import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, createRef } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TimelineIcon from "@mui/icons-material/Timeline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import advertisements from "../../../Images/advertisements.png";
import { useNavigate } from "react-router-dom";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  useStyles,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { updateLoginDetails } from "../../../redux/CreateLoginDetailSlice";
import { VscLayoutSidebarLeftOff } from "react-icons/vsc";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Close, PeopleAlt as PeopleAltIcon } from "@mui/icons-material";
import Cropper from "react-cropper";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CustomButton from "../../Components/CustomButton";
import SettingsIcon from "@mui/icons-material/Settings";
import ForumIcon from "@mui/icons-material/Forum";
import MessageIcon from "@mui/icons-material/Message";
import ProfileForm from "../EmployerProfile/ProfileFrom";
import EmployerPasswordChangeModal from "../EmployerProfile/EmployerPasswordChangeModal";
import EmployerTwoFactorModel from "../EmployerProfile/EmployerTwoFactorModel";
import { useScreenWidth } from "../../CommonCode/ScreenWidth/useScreenWidth";
import { useLocation } from "react-router-dom";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";

export default function EmployerSideMenu({ value, api }) {
  const redirect = useNavigate();
  const classes = useStyles();
  const [isApiCalled, setApiCall] = useState(0);
  const dispatch = useDispatch();
  const [employerData, setEmployerData] = useState({});
  const [openPasswordResetModal, setPasswordResetModal] = useState(false);
  const [openEmployerTFAChangeModal, setEmployerTFAChangeModal] =
    useState(false);
  const Labels = useSelector((state) => state.allLabels.labels);
  const employerTypeName = localStorage.getItem("employerType");

  // ---------------List Controls---------------------
  const [openGroup, setOpenGroup] = useState(false);
  const handleClickGroups = () => {
    setOpenGroup(!openGroup);
  };

  const [openMessages, setOpenMessages] = useState(false);
  const handleClickMessages = () => {
    setOpenMessages(!openMessages);
  };
  const [openJobs, setOpenJobs] = useState(false);
  const handleClickJobs = () => {
    setOpenJobs(!openJobs);
  };

  const [openForum, setOpenForum] = useState(false);
  const handleClickForums = () => {
    setOpenForum(!openForum);
  };

  const [openSettings, setOpenSettings] = useState(false);
  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const [Ischecked, setIsChecked] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);

  const getEmployerData = async () => {
    setBackdrop(true);
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;
    if (status) {
      setIsChecked(data.twoFactorPreferred == 1 ? true : false);
    }
  };

  // ---------------Get Company Data---------------------
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };
  const getCompanyData = async () => {
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;
    if (status) {
      setEmployerData(data);
    } else {
      //// console.log(message);
    }
  };
  useEffect(() => {
    getCompanyData();
  }, [value]);

  const callbackfn = () => {
    getCompanyData();
  };

  useEffect(() => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
  }, []);

  // -------------------- Employer Logout --------------------

  const employerLogout = async () => {
    try {
      const result = await axiosAPI.post("/logout", {
        language: localStorage.getItem("language"),
      });
      const { success, message } = result.data;
      if (success) {
        localStorage.clear();
        succesNotify(message);
        redirect("/landingpage");
      } else {
        localStorage.clear();
        redirect("/landingpage");
      }
    } catch (error) {
      localStorage.clear();
      redirect("/landingpage");
    }
  };
  const redirectoDashboard = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/employerDashboard");
    setMobileOpen(false);
  };
  const redirectoProfile = () => {
    localStorage.setItem("profform", "block");
    localStorage.setItem("protext", "none");
    redirect("/employerProfile");
    setMobileOpen(false);
  };
  const redirectoUserList = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/user");
    setMobileOpen(false);
  };
  const redirectoCreateJob = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/Createjob");
    setMobileOpen(false);
  };
  const redirectoCreatedJobList = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/employerJobList");
    setMobileOpen(false);
  };
  const redirectoJobseekerSearch = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/searchJobSeeker");
    setMobileOpen(false);
  };
  const redirectoCompanyList = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/CompanyList");
    setMobileOpen(false);
  };
  const redirectoSearchGruops = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/search-groupList");
    setMobileOpen(false);
  };
  const redirectoMyGruops = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/my-groupList");
    setMobileOpen(false);
  };
  const redirectoSeacrcForums = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/forumList");
    setMobileOpen(false);
  };
  const redirectoMyFroums = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/myForumList");
    setMobileOpen(false);
  };
  const redirectoChats = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/chats");
    setMobileOpen(false);
  };

  const redirectoMessages = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/messages");
    setMobileOpen(false);
  };
  const redirectoNotification = () => {
    localStorage.setItem("profform", "none");
    localStorage.setItem("protext", "block");
    redirect("/notification");
    setMobileOpen(false);
  };

  // -------------------- Drawer --------------------
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };
  const handleCloseDrawerToggle = () => {
    setMobileOpen(false);
  };
  const [openImage, setOpenImage] = useState(false);

  // ----------------Delete Profile pic ------------------

  const deletePrifilePic = async () => {
    if (!employerData?.logoPath) {
      return infoNotify("Upload profile picture");
    }
    const result = await axiosAPI.post("/deleteProfilePic", {
      language: localStorage.getItem("language"),
    });
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      setOpenImage(false);
      window.location.reload()
      // api(Math.random());
    }
  };

  // ----------------Profile pic upload ------------------

  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("");
  const [selectImage, setSelectImage] = useState(false);
  const [imagename, setImagename] = useState(null);
  const cropperRef = createRef();

  const onChange = (e) => {
    const datatypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    if (!datatypes.includes(e.target.files[0].type)) {
      warningNotify(Labels.invaildFile);
    } else {
      e.preventDefault();
      setOpenImage(false);
      setSelectImage(true);
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files[0];
      }
      setImagename(files);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files);
    }
  };

  const [imgbtndisabled,setimgbtndisabled] = useState(false)

  const handleUpload = async () => {
    setimgbtndisabled(true)
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();  
    uploadProfilePicture(croppedDataURL, imagename);
  };

  const uploadProfilePicture = async (manipulatedDataURL, imagename) => {
    const formData = new FormData();
    const blob = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData.append("images", blob);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    const maxFileSize = 3145728;

    if (!allowedTypes.includes(blob.type)) {
      return errorNotify(Labels.invaildFile);
    }
    if (blob.size > maxFileSize) {
      return errorNotify(Labels.imageValidation);
    }
    const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = imagename.name; // Replace with your desired file name
    const mimeType = imagename.type; // Replace with your desired MIME type

    const file = new File([blob2], fileName, { type: mimeType });

    const formData2 = new FormData();
    formData2.append("images", file);
    formData2.append("language", localStorage.getItem("language"));
    try {
      const response = await axiosupload.post(
        "/uploadProfilePic",
        formData2,
        tokens
      );
      const { status, message } = response.data;
      if (status === "successs") {
        succesNotify(message);
        setSelectImage(false);
        setOpenImage(false);
        setimgbtndisabled(false)
        getCompanyData();
        setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const CurrentWidth = useScreenWidth();

  const location = useLocation();

  const isActiveRoute = (route) => {
    return location?.pathname?.includes(route);
  };

  const activeRoute = window.location.pathname;
  const storedSourceRoute = localStorage.getItem("sourceRoute");
  const bgcolor = "lightgrey";

  return (
    <div
      style={{
        position: CurrentWidth > 1199 ? "fixed" : "relative",
        width: CurrentWidth > 1534 ? "15.7%" : `23.7%`,
      }}
    >
      <Stack sx={{ display: { xs: "block", lg: "none" }, my: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={handleDrawerToggle}
          sx={{ cursor: "pointer" }}
        >
          <VscLayoutSidebarLeftOff style={{ fontSize: 23 }} />
          <ChevronRightIcon />
          <Typography variant="caption" style={{ fontSize: 20 }}>
            {Labels.Menu}
          </Typography>
        </Stack>
      </Stack>
      {/* -------------------- Drawer -------------------- */}
      <Drawer anchor="left" open={mobileOpen} onClose={handleCloseDrawerToggle}>
        <Stack sx={{ width: 300 }}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {employerData?.userType === "Recruiter" ? (
                <Box sx={{ position: "relative" }}>
                  <Avatar
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                    src={`${employerData?.recruiterLogo}`}
                    onClick={() => setOpenImage(true)}
                  />
                  <IconButton
                    component="label"
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      bottom: "7%",
                      right: "0%",
                      padding: 0.5,
                      background: "white",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                      "&:hover": {
                        background: "white",
                      },
                    }}
                  >
                    <CameraAltIcon
                      sx={{
                        color: "black",
                      }}
                    />
                    <InputBase
                      type="file"
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, image/gif"
                      onChange={onChange}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <Avatar
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                    src={`${employerData?.companyLogo}`}
                    onClick={() => setOpenImage(true)}
                  />
                  <IconButton
                    component="label"
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      bottom: "7%",
                      right: "0%",
                      padding: 0.5,
                      background: "white",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                      "&:hover": {
                        background: "white",
                      },
                    }}
                  >
                    <CameraAltIcon
                      sx={{
                        color: "black",
                      }}
                    />
                    <InputBase
                      type="file"
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, image/gif"
                      onChange={onChange}
                    />
                  </IconButton>
                </Box>
              )}

              <Typography variant="subtitle1" sx={{ color: "#2B4947" }}>
                {employerData?.contactName}
              </Typography>
              <Typography variant="body2" sx={{ color: "#84827D" }}>
                {employerData?.designationName}
              </Typography>
              <Typography variant="caption" sx={{ color: "#84827D" }}>
                {employerData?.companyName}
              </Typography>

              <div
                style={{
                  display: isActiveRoute("employerProfile") ? "block" : "none",
                  width: "100%",
                }}
              >
                <ProfileForm callbackfn={callbackfn}/>
              </div>
            </CardContent>
          </Card>
          <Divider />
          <List
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
              margin: "10px 0",
            }}
            component="nav"
          >
            {/*-------------------- Dashboard --------------------*/}
            <ListItemButton
              onClick={redirectoDashboard}
              sx={
                isActiveRoute("/employerDashboard")
                  ? { backgroundColor: bgcolor }
                  : {}
              }
            >
              <ListItemIcon>
                <DashboardIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Dashboard} />
            </ListItemButton>
            {/*-------------------- My Profile --------------------*/}
            <ListItemButton
              onClick={redirectoProfile}
              sx={
                isActiveRoute("/employerProfile")
                  ? { backgroundColor: bgcolor }
                  : {}
              }
            >
              <ListItemIcon>
                <AccountBoxIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.myProfile} />
            </ListItemButton>
            {/*-------------------- Users --------------------*/}

            {employerTypeName === "Company" ? (
              <ListItemButton onClick={redirectoUserList}>
                <ListItemIcon>
                  <PeopleAltIcon sx={{ color: "#050505BF" }} />
                </ListItemIcon>
                <ListItemText primary={Labels?.users} />
              </ListItemButton>
            ) : null}
            {/*-------------------- Company List --------------------*/}
            {employerTypeName === "Consultancy" ? (
              <ListItemButton onClick={redirectoCompanyList}>
                <ListItemIcon>
                  <PeopleAltIcon sx={{ color: "#050505BF" }} />
                </ListItemIcon>
                <ListItemText primary={Labels?.Companies} />
              </ListItemButton>
            ) : null}
            {/*-------------------- Jobs --------------------*/}
            <ListItemButton onClick={handleClickJobs}>
              <ListItemIcon>
                <WorkRoundedIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Jobs} />
              {openJobs ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openJobs} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoCreateJob}>
                  <ListItemText secondary={Labels?.CreateJob} />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 8 }}
                  onClick={redirectoCreatedJobList}
                >
                  <ListItemText secondary={Labels?.ListJob} />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 8 }}
                  onClick={redirectoJobseekerSearch}
                >
                  <ListItemText secondary={Labels?.SearchCandidates} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Groups --------------------*/}
            <ListItemButton onClick={handleClickGroups}>
              <ListItemIcon>
                <AssessmentIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Groups} />
              {openGroup ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openGroup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoSearchGruops}>
                  <ListItemText secondary={Labels?.DiscoverGroups} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoMyGruops}>
                  <ListItemText secondary={Labels?.MyGroups} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Forums --------------------*/}
            <ListItemButton onClick={handleClickForums}>
              <ListItemIcon>
                <TimelineIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Forums} />
              {openForum ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openForum} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoSeacrcForums}>
                  <ListItemText secondary={Labels?.discoverForum} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoMyFroums}>
                  <ListItemText secondary={Labels?.MyForums} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Messages --------------------*/}
            <ListItemButton onClick={handleClickMessages}>
              <ListItemIcon>
                <ChatBubbleOutlineIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Messages} />
              {openMessages ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMessages} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoChats}>
                  <ListItemText secondary={Labels?.Onlinechat} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8 }} onClick={redirectoMessages}>
                  <ListItemText secondary={Labels?.Messages} />
                </ListItemButton>
              </List>
            </Collapse>
            {/*-------------------- Notifications --------------------*/}
            <ListItemButton onClick={redirectoNotification}>
              <ListItemIcon>
                <NotificationsIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Notifications} />
            </ListItemButton>
            {/*-------------------- Logout --------------------*/}
            <ListItemButton onClick={employerLogout}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Logout} />
            </ListItemButton>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: 75,
                  borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(97.72deg, rgba(28, 97, 78, 0) 2.18%, rgba(28, 97, 78, 0.58) 86.2%)",
                  backgroundImage: `url(${advertisements})`,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    color: "#FFFFFF",
                  }}
                >
                  {Labels.advertisementArea}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Stack>
      </Drawer>

      <Stack
        sx={{
          height: "90vh",
          overflowY: "auto",
          display: { xs: "none", lg: "block" },
          position: { md: "sticky" },
          top: "85px",
        }}
        className={classes.customScrollbar}
      >
        {/* -----------------Profile View Card-------------------------- */}
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {employerData?.userType === "Recruiter" ? (
              <Box sx={{ position: "relative" }}>
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  src={`${employerData?.recruiterLogo}`}
                  onClick={() => setOpenImage(true)}
                />
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "7%",
                    right: "0%",
                    padding: 0.5,
                    background: "white",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                    "&:hover": {
                      background: "white",
                    },
                  }}
                >
                  <CameraAltIcon
                    sx={{
                      color: "black",
                    }}
                  />
                  <InputBase
                    type="file"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, image/gif"
                    onChange={onChange}
                  />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ position: "relative" }}>
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  src={`${employerData?.companyLogo}`}
                  onClick={() => setOpenImage(true)}
                />
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "7%",
                    right: "0%",
                    padding: 0.5,
                    background: "white",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                    "&:hover": {
                      background: "white",
                    },
                  }}
                >
                  <CameraAltIcon
                    sx={{
                      color: "black",
                    }}
                  />
                  <InputBase
                    type="file"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, image/gif"
                    onChange={onChange}
                  />
                </IconButton>
              </Box>
            )}
            {employerData?.userType === "Recruiter" ? (
              <Typography variant="subtitle1" sx={{ color: "#2B4947" }}>
                {employerData?.recruiterName}
              </Typography>
            ) : (
              <Typography variant="subtitle1" sx={{ color: "#2B4947" }}>
                {employerData?.contactName}
              </Typography>
            )}
            <Typography variant="body2" sx={{ color: "#84827D" }}>
              {employerData?.designationName}
            </Typography>
            <Typography variant="caption" sx={{ color: "#84827D" }}>
              {employerData?.companyName}
            </Typography>

            <div
              style={{
                display: isActiveRoute("employerProfile") ? "block" : "none",
                width: "100%",
              }}
            >
              <ProfileForm callbackfn={callbackfn} />
            </div>
          </CardContent>
        </Card>

        {/* -----------------Side Bar -------------------------- */}
        <List
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
            margin: "10px 0",
            height: "71vh",
            overflow: "auto",
          }}
          component="nav"
          className={classes.customColoredScrollbar}
        >
          {/*-------------------- Dashboard --------------------*/}
          <ListItemButton
            onClick={redirectoDashboard}
            sx={
              isActiveRoute("/employerDashboard")
                ? { backgroundColor: bgcolor }
                : {}
            }
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Dashboard} />
          </ListItemButton>
          {/*-------------------- My Profile --------------------*/}
          <ListItemButton
            onClick={redirectoProfile}
            sx={
              isActiveRoute("/employerProfile")
                ? { backgroundColor: bgcolor }
                : {}
            }
          >
            <ListItemIcon>
              <AccountBoxIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.myProfile} />
          </ListItemButton>
          {/*-------------------- Users --------------------*/}
          {employerTypeName === "Company" ? (
            <ListItemButton
              onClick={redirectoUserList}
              sx={isActiveRoute("/user") ? { backgroundColor: bgcolor } : {}}
            >
              <ListItemIcon>
                <PeopleAltIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.users} />
            </ListItemButton>
          ) : null}
          {/*-------------------- consultancy --------------------*/}
          {employerTypeName === "Consultancy" ? (
            <ListItemButton
              onClick={redirectoCompanyList}
              sx={
                isActiveRoute("/CompanyList")
                  ? { backgroundColor: bgcolor }
                  : {}
              }
            >
              <ListItemIcon>
                <PeopleAltIcon sx={{ color: "#050505BF" }} />
              </ListItemIcon>
              <ListItemText primary={Labels?.Companies} />
            </ListItemButton>
          ) : null}
          {/*-------------------- Jobs --------------------*/}
          <ListItemButton onClick={handleClickJobs}>
            <ListItemIcon>
              <WorkRoundedIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Jobs} />
            {openJobs ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openJobs} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/Createjob")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoCreateJob}
              >
                <ListItemText secondary={Labels?.CreateJob} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/employerJobList")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoCreatedJobList}
              >
                <ListItemText secondary={Labels?.ListJob} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/searchJobSeeker")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoJobseekerSearch}
              >
                <ListItemText secondary={Labels?.SearchCandidates} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Groups --------------------*/}
          <ListItemButton onClick={handleClickGroups}>
            <ListItemIcon>
              <AssessmentIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Groups} />
            {openGroup ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openGroup} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/search-groupList") ||
                  isActiveRoute("/group-detail")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoSearchGruops}
              >
                <ListItemText secondary={Labels?.DiscoverGroups} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/my-groupList")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoMyGruops}
              >
                <ListItemText secondary={Labels?.MyGroups} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Forums --------------------*/}
          <ListItemButton onClick={handleClickForums}>
            <ListItemIcon>
              <ForumIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Forums} />
            {openForum ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openForum} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/forumList") || isActiveRoute("/forum-detail")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoSeacrcForums}
              >
                <ListItemText secondary={Labels?.discoverForum} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/myForumList") ||
                  isActiveRoute("/myForum-detail")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoMyFroums}
              >
                <ListItemText secondary={Labels?.MyForums} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Messages --------------------*/}
          <ListItemButton onClick={handleClickMessages}>
            <ListItemIcon>
              <MessageIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Messages} />
            {openMessages ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMessages} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={
                  isActiveRoute("/chats")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoChats}
              >
                <ListItemText secondary={Labels?.Onlinechat} />
              </ListItemButton>
              <ListItemButton
                sx={
                  isActiveRoute("/messages")
                    ? { backgroundColor: bgcolor, pl: 8 }
                    : { pl: 8 }
                }
                onClick={redirectoMessages}
              >
                <ListItemText secondary={Labels?.Messages} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Notifications --------------------*/}
          <ListItemButton
            onClick={() => redirect("/notification")}
            sx={
              isActiveRoute("/notification") ? { backgroundColor: bgcolor } : {}
            }
          >
            <ListItemIcon>
              <NotificationsIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Notifications} />
          </ListItemButton>
          {/*-------------------- Settings --------------------*/}
          <ListItemButton onClick={handleClickSettings}>
            <ListItemIcon>
              <SettingsIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.settings} />
            {openSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 8 }}
                onClick={() => setPasswordResetModal(true)}
              >
                <ListItemText secondary={Labels?.ChangePassword} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 8 }}
                onClick={() => {
                  setEmployerTFAChangeModal(true);
                  getEmployerData();
                }}
              >
                <ListItemText secondary={Labels.TFA} />
              </ListItemButton>
            </List>
          </Collapse>
          {/*-------------------- Logout --------------------*/}
          <ListItemButton onClick={employerLogout}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#050505BF" }} />
            </ListItemIcon>
            <ListItemText primary={Labels?.Logout} />
          </ListItemButton>
          <ListItem>
            {/* --------------------Advertisement------------------- */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: 75,
                borderRadius: 4,
                justifyContent: "center",
                alignItems: "center",
                background:
                  "linear-gradient(97.72deg, rgba(28, 97, 78, 0) 2.18%, rgba(28, 97, 78, 0.58) 86.2%)",
                backgroundImage: `url(${advertisements})`,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  color: "#FFFFFF",
                }}
              >
                {Labels.advertisementArea}
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Stack>
      <EmployerPasswordChangeModal
        open={openPasswordResetModal}
        handleClose={setPasswordResetModal}
      />
      <EmployerTwoFactorModel
        open={openEmployerTFAChangeModal}
        handleClose={setEmployerTFAChangeModal}
      />

      {/* -------------------- profile pic view -------------------- */}
      <Dialog open={openImage} onClose={() => setOpenImage(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="flex-end">
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenImage(false)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative" }}>
            {employerData?.userType === "Recruiter" ? (
              <Avatar
                src={`${employerData?.recruiterLogo}`}
                sx={{
                  width: { xs: 200, sm: 300 },
                  height: { xs: 200, sm: 300 },
                  borderRadius: "100%",
                  cursor: "pointer",
                  padding: "2px",
                }}
                onClick={() => setOpenImage(true)}
              />
            ) : (
              <Avatar
                src={`${employerData?.companyLogo}`}
                sx={{
                  width: { xs: 200, sm: 300 },
                  height: { xs: 200, sm: 300 },
                  borderRadius: "100%",
                  cursor: "pointer",
                  padding: "2px",
                }}
                onClick={() => setOpenImage(true)}
              />
            )}
            <IconButton
              component="label"
              sx={{
                position: "absolute",
                zIndex: 2,
                bottom: "7%",
                right: "7%",
                padding: 1,
                background: "white",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                "&:hover": {
                  background: "white",
                },
              }}
            >
              <CameraAltIcon
                sx={{
                  color: "black",
                }}
              />
              <InputBase
                type="file"
                style={{ display: "none" }}
                accept="image/jpeg, image/png, image/gif"
                onChange={onChange}
              />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <CustomButton
            onClick={deletePrifilePic}
            label={Labels.delete}
            style={{ padding: "5px 18px" }}
          />
        </DialogActions>
      </Dialog>
      {/* ---------------Dialog box for image crop------------------ */}
      <Dialog open={selectImage} onClose={() => setSelectImage(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="flex-end">
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => setSelectImage(false)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
              height: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
            }}
          >
            <Cropper
              ref={cropperRef}
              style={{ height: "100%", width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
            />
             <SimpleBackdrop open={imgbtndisabled} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "25px" }}>
          <CustomButton
            onClick={handleUpload}
            label={Labels.Upload}
            style={{ padding: "5px 18px" }}
            disabled={imgbtndisabled}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
