import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import InputWithLabel from "../../Components/InputWithLabel";
import CustomButton from "../../Components/CustomButton";
import { axiosupload } from "../../Axios/Axios";
import { errorNotify, succesNotify,warningNotify} from "../../CommonCode/Commonfunc";
import { useNavigate } from "react-router-dom";
import Topics from "../../CommonCode/Topics";
import { useSelector } from "react-redux";
import {profanityfn, profanitySend}  from "../../Profanity/Profanity";

export default function CreateGroup() {
  const redirect = useNavigate();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [image, setImage] = useState("");
  const [groupName, setGroupName] = useState("");
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const allowedTypes = ["image/jpeg", "image/png", "image/JPG"];
  const maxFileSize = 3145728;
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  const Labels = useSelector((state) => state.allLabels.labels);


  // const files = acceptedFiles.map((file) => (
  //   <div key={file.path}>
  //     <li>{file.path}</li>
  //     <img src={URL.createObjectURL(file)} alt={file.name} width="50px" />
  //   </div>
  // ));
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]; // Get the first accepted file
      if (allowedTypes.includes(file.type)) {
        // Check if file type is allowed
        setUploadedFile(file); // Set the uploaded file object
        setImage(URL.createObjectURL(file)); // Set the image URL
      } else {
        // Notify user of invalid file type
        errorNotify(Labels.invaildFile);
      }
    },
    [allowedTypes]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [btndisable,setbtndisable] = useState(false)

  const submitFormprofanity = (e) => {
    const data = {
      "name": groupName,
      "title": topic,
      "description": description,
    };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitForm(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!uploadedFile) {
      errorNotify(Labels.uploadImage);
      return;
    } else if (uploadedFile.size > maxFileSize) {
      errorNotify(Labels.imageValidation);
      return;
    } else if (!groupName) {
      errorNotify(Labels.groupNameValidation);
      return;
    } else if (!topic) {
      errorNotify(Labels.enterTopic);
      return;
    } else if (!description) {
      errorNotify(Labels.enterDescription);
      return;
    } else if (description.length > 1000) {
      errorNotify(Labels.descriptionValidation);
      return;
    } else {
      setbtndisable(true)
      const data = new FormData();
      data.append("logo", uploadedFile);
      data.append("name", groupName);
      data.append("title", topic);
      data.append("description", description);
      data.append('language',localStorage.getItem("language"))
      try {
        const result = await axiosupload.post("/createGroup", data, tokens);
        const { status, message } = result.data;
        if (status) {
          succesNotify(message);
          setTimeout(() => {
            redirect("/myGroupList");
          }, 1000);
            setbtndisable(false)
        } else {
          errorNotify(message);
            setbtndisable(false)
        }
      } catch (error) {
        //// console.log(error.message);
        setbtndisable(false)

      }
    }
  };
  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Paper elevation={0} sx={{ my: 1 }}>
      <Typography variant="h6" sx={{ color: "#2B4947", p: 2 }}>
        {Labels?.CreateGroup}
      </Typography>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1">{Labels?.Logo} </Typography>

        <div>
          <div {...getRootProps()} className="dropzone" style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>{Labels.dragndrop}</p>
          </div>
          {image && (
            <div>
              <h4>{Labels.UploadedImage}:</h4>
              <img src={image} alt="Uploaded" width="200px" />
            </div>
          )}
        </div>

        <InputWithLabel
          InputLabel={Labels?.groupName}
          label={Labels?.name}
          style={{ width: "100%" }}
          value={groupName}
          onChange={(event) => setGroupName(profanityfn(ProfanityList,event.target.value))}
        />
        <div style={{ margin: "10px 5px" }}>
          <label style={{ color: "#413E3E", width: 230 }}>
            {Labels?.topic}
          </label>
          <Topics
            value={topic}
            label={Labels?.Topics}
            setValue={setTopic}
            style={{
              width: "100%",
              fontSize: "14px",
            }}
          />
        </div>
        <InputWithLabel
          InputLabel={Labels?.groupDescription}
          label={Labels?.description}
          multiline
          rows={4}
          style={{ width: "100%" }}
          value={description}
          onChange={(event) => setDescription(profanityfn(ProfanityList,event.target.value))}
        />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomButton
            label={Labels?.CreateGroup}
            disabled={btndisable}
            style={{ padding: "5px 25px" }}
            onClick={(e) => submitFormprofanity(e)}
          />
        </div>
      </Box>
    </Paper>
  );
}
