import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../../Components/CustomButton";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DeleteForumModal({ open, handleClose, forumId }) {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [btndisable, setbtndisable] = React.useState(false);
  const deleteForum = async () => {
    setbtndisable(true);
    if (forumId) {
      try {
        const result = await axiosAPI.post("deleteForum", { forumid: forumId,language:localStorage.getItem("language") });
        const { status, message } = result.data;
        if (status) {
          setbtndisable(false);
          succesNotify(message);
          handleClose();
          redirect("/forum");
        } else {
          setbtndisable(false);
          errorNotify(message);
        }
      } catch (error) {
        setbtndisable(false);
        //// console.log(error.message);
      }
    } else {
      //// console.log("forum id is not available to delete forum");
      setbtndisable(false);

    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "300px",
          padding: "20px",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        {Labels?.areYouSure}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", fontFamily: "poppins" }}
        >
          {Labels?.wantToDelete}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginTop: "20px" }}>
        <CustomButton
          label={Labels?.delete}
          disabled={btndisable}
          style={{
            width: 100,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={deleteForum}
        />
        <CustomButton
          label={Labels?.Cancel}
          style={{
            width: 100,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
}
