import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Avatar,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  Divider,
  Box,
  Badge,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import Skeleton from "@mui/material/Skeleton";
import { PUBLIC_IMAGE_FOLDER } from "../../../Axios/Constant";
import {
  errorNotify,
  succesNotify,
  useStyles,
} from "../../../CommonCode/Commonfunc";
import { axiosAPI } from "../../../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BackBtn from "../../../../Images/left-arrow.png";
import JobDetailedViewPostLogin from "../../JobSearchPostLogin/JobDetailedViewPostLogin";

export default function SavedJobs() {
  const redirect = useNavigate();
  const [waiting, setwaiting] = useState(true);
  const [jobid, setjobid] = useState();
  const [JobList, setJobList] = useState([]);
  const [refreshState, setRefreshState] = useState(false);

  const savefn = async (data) => {
    const postdata1 = {
      language: "en",
      jobid: data.eja_id,
      type: data.save_status ? "unsave" : "save",
      language: localStorage.getItem("language"),
    };
    try {
      const result = await axiosAPI.post("/saveJob", postdata1);
      const { status, message } = result.data; // Destructure status and message
      if (status === true) {
        succesNotify(message); // Notify success
        getSavedJobs(); // Refresh job list
        setRefreshState(!refreshState);
        setjobid(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const getSavedJobs = async () => {
    try {
      const result = await axiosAPI.post("/searchjobs");
      const { data, success } = result.data;
      if (success === true) {
        setJobList(data);
        setwaiting(false);
      } else {
        setwaiting(false);
      }
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getSavedJobs();
  }, []);

  const Labels = useSelector((state) => state.allLabels.labels);

  const [selectedCardId, setSelectedCardId] = useState(null);
  const cardRefs = useRef([]);

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };

  useEffect(() => {
    if (selectedCardId !== null) {
      const selectedCardIndex = JobList.findIndex(
        (card) => card.eja_id === selectedCardId
      );
      if (selectedCardIndex !== -1 && cardRefs.current[selectedCardIndex]) {
        cardRefs.current[selectedCardIndex].scrollIntoView({
          behavior: "smooth",
          // block: "start",
        });
      }
    }
  }, [selectedCardId, JobList]);

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={jobid ? 6 : 12}
          lg={jobid ? 5 : 12}
          xl={jobid ? 4 : 12}
          sx={{
            maxHeight: "85vh",
            overflowY: "auto",
            margin: jobid ? "20px auto" : "0px",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              backgroundColor: "rgba(109, 160, 156, 0.12)",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#1C614E" }}
              >
                {Labels?.savedjobs}
              </Typography>
              <Typography variant="body1" sx={{ color: "#1C614E" }}>
                {Labels?.Total} :{" "}
                {JobList?.filter((item) => item?.save_status === 1).length}
              </Typography>
            </Stack>
          </Paper>
          {!waiting ? (
            JobList?.filter((item) => item?.save_status === 1).length > 0 ? (
              JobList.filter((item) => item.save_status === 1).map(
                (item, index) => (
                  <div
                    ref={(el) => (cardRefs.current[index] = el)}
                    className={`card-container ${
                      selectedCardId === item.eja_id ? "selected" : ""
                    }`}
                  >
                    <div className="card">
                      <Card
                        sx={{
                          backgroundColor:
                            selectedCardId === item.eja_id
                              ? // ? "#9ed8ff"
                                "#E0FDE3"
                              : "",
                          // borderRadius: "17px",
                          boxShadow: "none",
                          cursor: "pointer",
                          display: { xs: "block", md: "flex" },
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 5px",
                          minHeight: "100px",
                          my: 1,
                          position: "relative",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={3} sm={3} md={3} lg={2} onClick={() => {
                                  handleCardClick(item.eja_id);
                                  setjobid(item.eja_id);
                                }}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              <Grid
                                sx={{
                                  width: "90%",
                                  margin: "auto",
                                  position: "absolute",
                                  top: "10px",
                                  left: "25px",
                                  
                                }}
                                container
                                direction="row"
                                justifyContent="end"
                              >
                                <Grid item direction="row">
                                  {item.save_status === 1 ? (
                                    <BookmarkRoundedIcon
                                      onClick={() => {
                                        savefn(item);
                                      }}
                                      sx={{
                                        color: "#2B4947",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>

                              <Avatar
                              
                                src={
                                  item.companyLogo === null ||
                                  item.companyLogo?.endsWith("/")
                                    ? `${item?.jobName.charAt(0, 1)}`
                                    : `${item?.companyLogo}`
                                }
                                alt={item?.jobName.charAt(0, 1)}
                                style={{
                                  maxWidth: 80,
                                  minWidth: 60,
                                  maxHeight: 80,
                                  minHeight: 60,
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            sm={9}
                            md={9}
                            lg={8}
                            onClick={() => {
                              handleCardClick(item.eja_id);
                              setjobid(item.eja_id);
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ color: "#2B4947", fontWeight: 600 }}
                            >
                              {item?.jobName}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#2B4947" }}
                            >
                              {item?.employer}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "#000000A1" }}
                            >
                              {item?.city}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "#000000A1" }}
                            >
                              {item?.jobseekers}{" "}
                              {item?.jobseekers == 1
                                ? Labels.Applicant
                                : Labels.Applicants}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={2}>
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={1}
                              // sx={{ borderTop:  "1px solid #eaeaea" }}
                            >
                              <Stack
                                spacing={1}
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{ display: { xs: "none", sm: "flex" } }}
                              >
                                {item?.jobSkills
                                  ? item?.jobSkills
                                      .split(",")
                                      .map((skill) => (
                                        <Chip
                                          label={skill.trim()}
                                          size="small"
                                          sx={{
                                            color: "#BA8C04",
                                            background: "#BA8C040F",
                                            borderRadius: 1,
                                            width: 130,
                                            margin: "20px",
                                            border:
                                              "1px solid rgba(0, 0, 0, 0.10)",
                                          }}
                                        />
                                      ))
                                      .slice(0, 2)
                                  : null}
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                  </div>
                )
              )
            ) : (
              <h3 style={{ textAlign: "center" }}>{Labels?.NoSavedJobs}</h3>
            )
          ) : (
            <>
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
              <Skeleton
                variant="rounded"
                width={`100%`}
                height={120}
                style={{ margin: "10px auto" }}
              />
            </>
          )}
        </Grid>
        {jobid && (
          <Grid item xs={12} sm={12} md={6} lg={7} xl={8} mt={3}>
            <JobDetailedViewPostLogin
              jobid={jobid}
              refreshState={refreshState}
              savefn={savefn}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
