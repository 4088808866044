import React, { useState, useRef, useEffect } from "react";
import "./TwoFactorAuthentication.css";
import QRCode from "qrcode.react";
import { errorNotify, succesNotify } from "../Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";

function TwoFactorAuthentication() {
  const [pincode, setPincode] = useState(["", "", "", "", "", ""]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [wrong, setwrong] = useState("");
  const inputRefs = useRef([]);
  const [xmldata, setxmldata] = useState("");
  
  const Labels = useSelector((state) => state.allLabels.labels);

  useEffect(() => {
    inputRefs.current[0].focus(); // Focus on the first input field when component mounts

    axiosAPI
      .get(`/generateSecret`)
      .then((res) => {
        if (res.data.data.url) {
          // // console.log(res.data.data.url);
          setxmldata(res.data.data.url);
        }
      })
      .catch((err) => {
        //// console.log(err);
      });
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      const currentIndex = inputRefs.current.findIndex(
        (inputRef) => inputRef === document.activeElement
      );

      if (currentIndex !== -1) {
        if (event.key === "ArrowRight" && currentIndex < pincode.length - 1) {
          event.preventDefault();
          inputRefs.current[currentIndex + 1].focus();
        } else if (event.key === "ArrowLeft" && currentIndex > 0) {
          event.preventDefault();
          inputRefs.current[currentIndex - 1].focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [pincode.length]);

  const handlePincodeChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newPincode = [...pincode];
      newPincode[index] = value;
      setPincode(newPincode);
      setSubmitDisabled(newPincode.some((code) => code === ""));

      if (value !== "" && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    const result = pincode.join("");
    axiosAPI
      .post(`/verify2fa`, { key: result }, tokens)
      .then((res) => {
        if (res.data.status) {
          setwrong("rightValue");
          succesNotify(Labels.VerificationSuccessfull);
          localStorage.setItem("VerifiedMFA", true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setwrong("wrongValue");
          errorNotify(Labels.InvalidCode);
        }
      })
      .catch((err) => {
        //// console.log(err);
      });

    setTimeout(() => {
      setwrong("");
    }, 3000);
  };

  return (
    <div className="container-2FA">
      <form id="form" onSubmit={handleSubmit}>
        <h1>{Labels.twofaauth}</h1>
        <div className={`form__group_2FA form__pincode ${wrong}`}>
          <h3>
           {Labels.ScanthisQRCode}
          </h3>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="200"
            height="200"
            viewBox="0 0 200 200"
            dangerouslySetInnerHTML={{
              __html: xmldata,
            }}
          />
          <label>{Labels.enterdigitcode}</label>
          {[...Array(6)].map((code, index) => (
            <input
              key={index}
              type="tel"
              value={pincode[index]}
              maxLength="1"
              tabIndex={index + 1}
              placeholder="·"
              autoComplete="off"
              ref={(input) => {
                inputRefs.current[index] = input;
              }}
              onChange={(e) => handlePincodeChange(index, e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
          ))}
        </div>
        <div className="form__buttons">
          <button
            type="submit"
            className="button button--primary"
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            {Labels.Submit}
          </button>
        </div>
      </form>
    </div>
  );
}

export default TwoFactorAuthentication;
