import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import InputWithLabel from "../../Components/InputWithLabel";
import CustomButton from "../../Components/CustomButton";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import {
  errorNotify,
  succesNotify,
  warningNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import { useNavigate, useParams } from "react-router-dom";
import Topics from "../../CommonCode/Topics";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profanityfn, profanitySend} from "../../Profanity/Profanity";
import { createNewLoginDetails } from "../../../redux/CreateLoginDetailSlice";
import GrpStatus from "../../CommonCode/GrpStatus";

export default function EditEmployerGroup() {
  const redirect = useNavigate();
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [groupName, setGroupName] = useState("");
  const [topic, setTopic] = useState("");
  const [statusValue,setStatusvalue] = useState('')
  const [description, setDescription] = useState("");

  const employerLoginData = useSelector(
    (state) => state.createLoginDetails.loginDetails
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createNewLoginDetails());
  }, []);

  const allowedTypes = ["image/jpeg", "image/png", "image/JPG"];
  const maxFileSize = 3145728;
  const Labels = useSelector((state) => state.allLabels.labels);

  const { id } = useParams();
  const getGroupDetails = async () => {
    if (id) {
      const result = await axiosAPI.get(`/groupDetails?groupid=${id}`);
      const { status, data } = result.data;

      if (status) {
        if (result.data.data.ownerid == employerLoginData.uld_id) {
          setGroupName(data?.groupName);
          setImageUrl(data?.grpLogoPath);
          setTopic(data?.topicid);
          setDescription(data?.description);
          setStatusvalue(data?.grpStatus)
        } else {
          redirect("/my-groupList");
        }
      } else {
        redirect("/my-groupList");
      }
    } else {
      redirect("/my-groupList");
    }
  };
  useEffect(() => {
    getGroupDetails();
  }, []);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  const getImage = async (e) => {
    setImage(e.target.files);
    if (!e.target.files) {
      errorNotify(Labels.uploadImage);
      return;
    } else if (!allowedTypes.includes(e.target.files[0].type)) {
      errorNotify(Labels.invaildFile);
      return;
    } else if (e.target.files[0].size > maxFileSize) {
      errorNotify(Labels.imageValidation);
      return;
    } else {
      const data = new FormData();
      data.append("logo", e.target.files[0]);
      data.append("type", "group");
      data.append("groupid", id);
      data.append('language:localStorage.getItem("language")')
      const result = await axiosupload.post("/uploadLogo", data, tokens);
      const { message, status } = result.data;
      if (status) {
        setImage("");
        succesNotify(message);
        setTimeout(() => {
          redirect("/my-groupList");
        }, 1000);
      } else {
        setImage("");
        errorNotify(message);
      }
    }
  };

  const uploadImagefn = async () => {
    const data = new FormData();
    data.append("logo", image);
    data.append("type", "group");
    data.append("groupid", id);
    data.append('language',localStorage.getItem("language"))
    const result = await axiosupload.post("/uploadLogo", data, tokens);
    const { message, status } = result.data;
    if (status) {
      setImage("");
      succesNotify(message);
      setTimeout(() => {
        redirect("/my-groupList");
      }, 1000);
    } else {
      setImage("");
    }
  };

  const editgrp = (e) => {
    const data = {
      "name":groupName,
      "title":topic,
      "description":description
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitForm(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!groupName) {
      errorNotify(Labels.groupNameValidation);
      return;
    } else if (!topic) {
      errorNotify(Labels.enterTopic);
      return;
    } else if (!description) {
      errorNotify(Labels.enterDescription);
      return;
    } else if (description.length > 1000) {
      errorNotify(Labels.descriptionValidation);
      return;
    } else {
      const postData = {
        groupid: id,
        name: groupName,
        title: topic,
        description: description,
        status:statusValue,
        language:localStorage.getItem("language")
      };
      try {
        uploadImagefn();
        const result = await axiosAPI.post("/editGroup", postData);
        const { status, message } = result.data;
        if (status) {
          succesNotify(message);
          setTimeout(() => {
            redirect("/my-groupList");
          }, 1000);
        } else {
          errorNotify(message);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]; // Get the first accepted file
      if (allowedTypes.includes(file.type)) {
        setImage(file); // Set the uploaded file object
        setImageUrl(URL.createObjectURL(file)); // Set the image URL
      } else {
        // Notify user of invalid file type
        errorNotify(Labels.invaildFile);
      }
    },
    [allowedTypes]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  return (
    <Paper elevation={0} sx={{ my: 1 }}>
      <Typography variant="h6" sx={{ color: "#2B4947", p: 2 }}>
        {Labels?.EditGroup}
      </Typography>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1">{Labels.Logo}</Typography>
        <div>
          <div {...getRootProps()} className="dropzone" style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>{Labels.dragndrop}</p>
          </div>
          {imageUrl && (
            <div>
              <h4>{Labels.UploadedImage}:</h4>
              <img src={imageUrl} alt="Uploaded" width="200px" />
            </div>
          )}
        </div>

        <InputWithLabel
          InputLabel={Labels?.groupName}
          label={Labels?.name}
          value={groupName}
          style={{ width: "100%" }}
          onChange={(event) =>
            setGroupName(profanityfn(ProfanityList, event.target.value))
          }
        />
        <div style={{ margin: "10px 5px" }}>
          <label style={{ color: "#413E3E", width: 230 }}>
            {Labels?.topic}
          </label>
          <Topics
            value={topic}
            label={Labels?.Topics}
            setValue={setTopic}
            style={{
              width: "100%",
              fontSize: "14px",
            }}
          />
        </div>
        <InputWithLabel
          InputLabel={Labels?.groupDescription}
          label={Labels?.description}
          value={description}
          multiline
          rows={4}
          style={{ width: "100%" }}
          onChange={(event) =>
            setDescription(profanityfn(ProfanityList, event.target.value))
          }
        />
         <div style={{ margin: "10px 5px" }}>
          <label style={{ color: "#413E3E", width: 230 }}>
            {Labels?.Status}
          </label>
          <GrpStatus
           disabled={false}
            value={statusValue}
            label={Labels?.Status}
            setValue={setStatusvalue}
            style={{
              width: "100%",
              fontSize: "14px",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomButton
            label={Labels?.EditGroup}
            style={{ padding: "5px 25px" }}
            onClick={(e) => editgrp(e)}
          />
        </div>
      </Box>
    </Paper>
  );
}
