import React, { Fragment, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {profanityfn}  from "../Profanity/Profanity";
import { useSelector } from "react-redux";
export default function Percentage({ value, setValue, placeholder, style,ref,setButtonDisable = () => {},disabled=false}) {
    const ProfanityList = useSelector((state)=> state.Profanity.wordlist)
    const Labels = useSelector((state) => state.allLabels.labels);

    const handleChange = (event) => {
        setButtonDisable(false)
        setValue(profanityfn(ProfanityList,event.target.value));
    };
    
    return (
        <Fragment>
            <FormControl sx={{ width: "99%" }}>
                <Select
                    labelId="demo-simple-select-label"
                    ref={ref}
                    id="demo-simple-select"
                    value={value?`${value}0%`:''}
                    onChange={handleChange}
                    fullWidth
                    disabled={disabled}
                    variant="outlined"
                    sx={{
                        position:"relative",
                        left:"3px",
                        padding: "10px 12px",
                        fontSize: "14px", // Apply fontSize style here
                        fontFamily: "Poppins",
                        ...style
                      }}
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ color: "#bcbcbc" }}>
                              {placeholder || Labels.FullPartTime}
                            </span>
                          );
                        }
                        return selected ;
                      }}
                >
                {!value && (
                    <MenuItem value="" disabled>
                        <span style={{ color: "#bcbcbc" }}>{placeholder || Labels.FullPartTime}</span>
                    </MenuItem>
                )}
                    <MenuItem value="1">10 % </MenuItem>
                    <MenuItem value="2">20 %</MenuItem>
                    <MenuItem value="3">30 %</MenuItem>
                    <MenuItem value="4">40 %</MenuItem>
                    <MenuItem value="5">50 %</MenuItem>
                    <MenuItem value="6">60 %</MenuItem>
                    <MenuItem value="7">70 %</MenuItem>
                    <MenuItem value="8">80 %</MenuItem>
                    <MenuItem value="9">90 %</MenuItem>
                    <MenuItem value="10">100 %</MenuItem>
                </Select>
            </FormControl>
        </Fragment>
    )
}
