import React, { useState, useEffect, Fragment, useRef, createRef } from "react";
import {
  Box,
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  InputLabel,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { axiosupload } from "../../Axios/Axios";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import Select from "@mui/material/Select";
import CustomButton from "../../Components/CustomButton";
import TextfieldCustom from "../../Components/TextfieldCustom";
import JobCategory from "../../CommonCode/JobCategory";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import Skills from "../../CommonCode/Skills";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  useStyles,
  warningNotify,
} from "../../CommonCode/Commonfunc";

import Gender from "../../CommonCode/Gender";
import { axiosAPI } from "../../Axios/Axios";
import { redirect, useNavigate } from "react-router-dom";

import AddedCompanies from "../../CommonCode/AddedCompanies";

import { format, addDays } from "date-fns";

import SelectJobType from "../../CommonCode/SelectJobType";

import Education from "../../CommonCode/Education";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import InputWithLabel from "../../Components/InputWithLabel";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OfficePresence from "../../CommonCode/OfficePresence";
import Expectations from "../../CommonCode/Expectations";

import YesNo from "../../CommonCode/YesNo";
import AllLanguage from "../../CommonCode/AllLanguage";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import "./table.css";
import Weeks from "../../CommonCode/Weeks";
import { type } from "@testing-library/user-event/dist/type";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import axios from "axios";
import { Label } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import JobInfo from "../../CommonCode/JobInformation";
import WorkHour from "../../CommonCode/WorkingHour";
import Currency from "../../CommonCode/Currency";
import CompanyCountry from "../../CommonCode/CompanyCountries";
import JobPostingDuration from "../../CommonCode/JobDuration";
import SalaryPeriod from "../../CommonCode/SalaryPeriod";
import Percentage from "../../CommonCode/Percentage";
import CompanyBranchAddress from "../../CommonCode/CompanyBranchs";
import CountryByCompany from "../../CommonCode/CompanyByCountry";
import CounsultancyAddress from "../../CommonCode/CounsultancyAddress";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SalaryCalculatorModal from "./SalaryCalculator";

const EmployerCreatejob = ({
  value,
  api,
  setValue,
  placeHolder,
  selectedValue,
  style,
  newValue,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [country, setcountry] = useState(null);
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [state, setState] = useState(null);
  const [gender, setgender] = useState("");
  const [qualification, setqualification] = useState([]);
  const [jobacate, setjobcate] = useState(0);
  const [addressData, setAddressData] = useState(null);

  const [Workinghour, setWorkinghour] = useState(8);
  const [companyName, setcompayname] = useState(null);

  const [jobtype, setjobtype] = useState([]);
  const [skills, setskills] = useState([]);
  const [jobRequiredLanguages, setjobRequiredLanguages] = useState([]);
  const [Rlevel, setRLevel] = useState("");
  const [Wlevel, setWLevel] = useState("");
  const [languageName, setLanguageName] = useState("1"); // English
  const [language, setLanguage] = useState(""); // id
  const [spoken, setLanguageReadLevel] = useState(""); // id
  const [written, setLanguageWriteLevel] = useState(""); // id

  const [FilterLanguage, setFilterLanguage] = useState("");
  const [FilterLanguage1, setFilterLanguage1] = useState("");

  const [jobRequiredLanguages1, setjobRequiredLanguages1] = useState([]);

  const [languageName1, setLanguageName1] = useState("1"); // English
  const [language1, setLanguage1] = useState(""); // id

  const [isRemote, setisRemote] = useState(false);
  const [isHybrid, setisHybrid] = useState(false);
  const [remote, setRemote] = useState("country");
  const [hybrid, setHybrid] = useState("National");
  const [viewSelect, setviewSelect] = useState();

  const [jobdescription,setjobdescription] = useState('')

  const [radius, setRadius] = useState("");
  const [date, setClosingDate] = useState(null);

  const [errMsg, setErrMsg] = useState({
    spokenErr: false,
    writtenErr: false,
    languageErr: false,
  });

  const [errMsg1, setErrMsg1] = useState({
    spokenErr1: false,
    writtenErr1: false,
    languageErr1: false,
  });

  const [expectations, setExpectations] = useState("");
  const [jobRequireTravel, setJobRequireTravel] = useState("");
  const [travelPercentage, setTravelPercentage] = useState("");
  const [willingToRelocate, setRelocate] = useState("");
  const [receiveCoverLetter, setExpectCoverLetter] = useState("");
  const [showProfile, setShowProfile] = useState("");
  const [contactBymail, setShowEmail] = useState("");
  const [JobPostDuration, setJobPostDuration] = useState(4);
  const [allCompanies, setAllCompanies] = useState([]);
  const [currency, setCurrency] = useState(1);
  const [companyLogo, setcompanyLogo] = useState("");
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  const [logoPath, setlogoPath] = useState("");
  const [countrycompany, setcountrycompany] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const maxFileSize = 3145728;

  const getEmployerData = async () => {
    setBackdrop(true);
    try {
      const result = await axiosAPI.post("/getCompanyProfile");
      const { status, data, message } = result.data;

      if (data) {
        setTimeout(() => {
          setBackdrop(false);
        }, 1000);
        setExpectCoverLetter(data.receiveCoverLetter);
        setShowProfile(data.showProfile);
        setShowEmail(data.contactBymail);
        setJobPostDuration(data.JobPostDuration ? data.JobPostDuration : 4);
        setlogoPath(data.logoPath);
      } else {
        setBackdrop(false);
        // console.error("Data is undefined in the API response.");
      }
    } catch (error) {
      setBackdrop(false);
      console.error("Error fetching employer data:", error);
    }
  };

  useEffect(() => {
    getEmployerData(); //profile data .. 3 data
  }, []);

  const [backdropOpen, setBackdrop] = useState(false);
  const employerTypeName = localStorage.getItem("employerType");
  const [jobinfo, setjobinfo] = useState([]);
  const [jobinformation, setjobinformation] = useState([]);
  // const [AdressId, setAdressId] = useState(null);
  const [Adress, setAdress] = useState(null);
  const [counsultancyAdress, setCounsultancyAdress] = useState(null);
  const [salaryPeriod, setsalaryPeriod] = useState(5);

  // For new client change - 14-06-24 - vis
  const [officePresence, setOfficePresence] = useState(8);
  const [officePercentage, setofficePercentage] = useState(100); // Old Value
  const [remotePercentage, setremotePercentage] = useState(0); // New Value
  const [countryValue, setcountryValue] = useState(1); // New Value
  const [continentValue, setcontinentValue] = useState(1); // New Value
  const [radiusValue, setradiusValue] = useState(0); // New Value
  const [stateID, setstateID] = useState(""); // New Value
  const [cityvalue, setcityvalue] = useState(""); // New Value
  // For new client change - 14-06-24 - vis


  const [salaryMax, setSalaryMax] = useState("");

  const [yearlysalary, setyearlysalary] = useState("");
  const [yearlysalary2, setyearlysalary2] = useState("");

  const [salarycalcuvalue, setsalarycalcuvalue] = useState({});

  
  const [currencySymbol, setCurrencySymbol] = useState("");

  

  useEffect(() => {
    setcountry(null);
    setcountrycompany(null);
  }, [companyName]);

  useEffect(() => {
    setAdress(null);
  }, [country]);

  useEffect(() => {
    setCounsultancyAdress(null);
  }, [countrycompany]);

  //  For New Client changes - 14-06-2024 - Vis

  useEffect(() => {
    // change item.name to item.namekey later after api change - vis
    if (officePercentage != null && remotePercentage != null) {
      if (
        officePercentage > 0 &&
        officePercentage < 100 &&
        remotePercentage > 0 &&
        remotePercentage < 100
      ) {
        setisHybrid(false);
        setisRemote(false);
        setOfficePresence(6);
        setcountryValue(1);
        setcontinentValue(1);
        // Hybrid
      } else if (officePercentage == 0 && remotePercentage == 100) {
        setisHybrid(false);
        setisRemote(true);
        // Remote
        setOfficePresence(7);
      } else if (officePercentage == 100 && remotePercentage == 0) {
        setisHybrid(false);
        setisRemote(false);
        // Office
        setcountryValue(1);
        setcontinentValue(1);
        setradiusValue(0);
        setOfficePresence(8);
      }
    }
    if (officePercentage == "") {
      setremotePercentage("100");
      setisHybrid(false);
      setisRemote(true);
      setOfficePresence(7);
    }
    if (remotePercentage == "") {
      setofficePercentage("100");
      setisHybrid(false);
      setisRemote(false);
      setOfficePresence(8);
    }
  }, [officePercentage, remotePercentage, officePresence]);

  useEffect(() => {
    if (officePercentage == 100 && remotePercentage == 0) {
      setcountryValue(1);
      setcontinentValue(1);
    } else {
      if (continentValue == 0) {
        setcountryValue(0);
      }
    }
  }, [continentValue, countryValue]);

  const setradiusfn = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setradiusValue(value);
    }
  };

  const setofficePercentagefn = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      if ((inputValue >= 0 && inputValue <= 100) || inputValue == "") {
        setofficePercentage(inputValue);
        setremotePercentage((100 - inputValue).toString());
      }
    } else {
      setofficePercentage("");
      // warningNotify(Labels.Officepercentagevalueshouldbeanumber);
    }
  };

  const setremotePercentagefn = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      if ((inputValue >= 0 && inputValue <= 100) || inputValue == "") {
        setremotePercentage(inputValue);
        setofficePercentage((100 - inputValue).toString());
      }
    } else {
      setremotePercentage("");
      // warningNotify(Labels.Officepercentagevalueshouldbeanumber);
    }
  };

  //  For New Client changes - 14-06-2024 - Vis

  const Labels = useSelector((state) => state.allLabels.labels);
  const [selectedName, setSelectedName] = useState("");
  const [typePercentage, settypepercentage] = useState(null);
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  // -----------------Submit Form------------------------------
  const [formData, setFormData] = useState({});
  const {
    jobtitle,
    jobdesc,
    // officePercentage,
    travel,
    noofvaccancy,
    experience,
    salary,
    certifications,
    workingHours,
    additionalQualification,
    benefits,
    whatYouWillDo,
    companyLink,
    companylocation,
    // typePercentage,
  } = formData;

  function validatePositiveNumber(input) {
    const regex = /^[0-9]+(\.[0-9]+)?$/;
    return regex.test(input);
  }
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  useEffect(() => {
    setFormData({ ...formData, workingHours: "1" });
  }, [Workinghour]);

  const Createjobads = (e) => {
    if (e.target.name == "workingHours") {
      const numericValue = e.target.value;

      if (Workinghour == 5 && numericValue <= 744) {
        setFormData({
          ...formData,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      } else if (Workinghour == 6 && numericValue <= 168) {
        setFormData({
          ...formData,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      } else if (Workinghour == 7 && numericValue <= 8760) {
        setFormData({
          ...formData,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      } else if (Workinghour == 8 && numericValue <= 24) {
        setFormData({
          ...formData,
          [e.target.name]: profanityfn(ProfanityList, numericValue),
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: profanityfn(ProfanityList, e.target.value),
      });
    }
    setError(false);
    setHybridErr(false);
  };

  const [btndisable, setbtndisable] = useState(false);

  const profanityChecker = () => {
    let postDataToSend;
    if (employerTypeName === "Consultancy") {
      const postdata1 = {
        jobName: jobtitle,
        jobCategory: jobacate.id,
        jobinformation: jobinfo,
        language1: "en",
        salary: salary ? salary?.replace(",", "") : "",

        experianceYears: userExperienceLevel,
        countryID: countrycompany?.id,
        address: counsultancyAdress?.name,

        contract: Array.isArray(jobtype) ? 0 : 1,

        jobTypes: jobtype?.toString()?.replace(/(^,|,$|,,+)/g, ""),
        typePercentage: typePercentage,

        jobSkills: skills?.toString()?.length
          ? skills?.toString()?.replace(/(^,|,$|,,+)/g, "")
          : null,
        applicationLastDate: date,
        language: "en",
        certifications: certifications,
        remote: null,
        hybrid: null,
        //  Needed values______________________________________________________________________________________________________
        officePresence: officePresence,
        officePercentage: officePercentage,
        remotePercentage: remotePercentage,
        withinCountry: countryValue,
        withinContinents: continentValue,
        jobRadious: radiusValue,
        stateID: stateID,
        city: cityvalue,
        //  Needed values______________________________________________________________________________________________________
        whatYouWillDo: whatYouWillDo,
        jobRequireTravel: jobRequireTravel,
        travelPercentage: travelPercentage ? travelPercentage : "",
        willingToRelocate: willingToRelocate,
        receiveCoverLetter: receiveCoverLetter,
        showYourProfile: showProfile,
        allowContactByMail: contactBymail,
        salary: salary ? salary?.replace(",", "") : "",
        companyLogo: companyLogo,
        expectedLanguage: JSON.stringify(jobRequiredLanguages),
        additionalLanguage: JSON.stringify(jobRequiredLanguages1),
        companyid: companyName,
        type: "Consultancy",
        workingDuration: Workinghour,
        additionalQualification: additionalQualification,
        currency: currency,
        workingHours: workingHours || "8",
        salaryPeriod: salaryPeriod,
        JobPostDuration: JobPostDuration,
        Jobinformations: JSON.stringify(Questiondata),
        description:jobdescription
      };

      postDataToSend = {
        ...postdata1,
        officePercentage: officePercentage,
      };
    } else {
      const postdata = {
        jobName: jobtitle,
        jobCategory: jobacate.id,
        jobinformation: jobinfo,

        jobTypes: jobtype?.toString()?.replace(/(^,|,$|,,+)/g, ""),
        typePercentage: typePercentage,

        contract: Array.isArray(jobtype) ? 0 : 1,

        experianceYears: userExperienceLevel,
        countryID: country?.id,
        address: Adress?.name,
        jobSkills: skills?.toString()?.length
          ? skills?.toString()?.replace(/(^,|,$|,,+)/g, "")
          : null,
        applicationLastDate: date,
        language: "en",
        language1: "en",
        certifications: certifications,
        remote: null,
        hybrid: null,
        //  Needed values______________________
        officePresence: officePresence,
        officePercentage: officePercentage,
        remotePercentage: remotePercentage,
        withinCountry: countryValue,
        withinContinents: continentValue,
        jobRadious: radiusValue,
        stateID: stateID,
        city: cityvalue,
        //  Needed values______________________________________________________________________________________________________
        whatYouWillDo: whatYouWillDo,
        jobRequireTravel: jobRequireTravel,
        travelPercentage: travelPercentage ? travelPercentage : "",
        willingToRelocate: willingToRelocate,
        receiveCoverLetter: receiveCoverLetter,
        showYourProfile: showProfile,
        allowContactByMail: contactBymail,
        salary: salary ? salary?.replace(",", "") : "",
        companyLogo: companyLogo,
        expectedLanguage: JSON.stringify(jobRequiredLanguages),
        additionalLanguage: JSON.stringify(jobRequiredLanguages1),
        companyid: companyName,

        travel: travel,
        workingDuration: Workinghour,
        workingHours: workingHours || "8",
        additionalQualification: additionalQualification,
        currency: currency,
        salaryPeriod: salaryPeriod,
        JobPostDuration: JobPostDuration,
        officePercentage: officePercentage !== null ? officePercentage : "",
        Jobinformations: JSON.stringify(Questiondata),
        description:jobdescription
      };

      postDataToSend = {
        ...postdata,
        officePercentage: officePercentage,
        language: localStorage.getItem("language"),
      };
    }

    let profanityDetected;
    for (const key in postDataToSend) {
      if (profanitySend(ProfanityList, postDataToSend[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitFormData();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitFormData = async (e) => {
    setButtonDisable(true);
    const type = localStorage.getItem("employerType");
    const companytype = localStorage.getItem("companyTypeName");
    if (companytype == "Consultancy") {
      if (!countrycompany.id) {
        errorNotify(Labels.countryRequired);
        setButtonDisable(false);
        return;
      }
      // if (!counsultancyAdress.name) {
      //   errorNotify(Labels.countryRequired);
      //   setButtonDisable(false);
      //   return;
      // }
    }
    if (employerTypeName == "company") {
      if (!country?.id) {
        errorNotify(Labels.countryRequired);
        setButtonDisable(false);
        return;
      }
      //  else if (Adress.length === 0) {
      //   errorNotify(Labels.countryRequired);
      //   setButtonDisable(false);
      //   return;
      // }
    }
    if (employerTypeName !== "Consultancy") {
      if (!country) {
        errorNotify(Labels.countryRequired);
        setButtonDisable(false);
        return;
      }
    }
    // if (employerTypeName == "Consultancy") {
    //   if (!counsultancyAdress?.name) {
    //     errorNotify(Labels.counsultancyAdressRequired);
    //     setButtonDisable(false);
    //     return;
    //   }
    // }
    if (!jobtitle) {
      errorNotify(Labels.jobTitlerequired);
      setButtonDisable(false);
      return;
    } else if (jobtitle.length > 200) {
      errorNotify(Labels.jobTitleValidation);
      setButtonDisable(false);
      return;
    } else if (!jobacate) {
      errorNotify(Labels.jobCategoryRequired);
      setButtonDisable(false);
      return;
    } else if (!officePresence) {
      setButtonDisable(false);
      errorNotify(Labels.Officepresencerequired);
      return;
    } else if (officePercentage == null) {
      setButtonDisable(false);
      errorNotify(Labels.Officepercentagerequired);
      return;
    } else if (remotePercentage == null) {
      setButtonDisable(false);
      errorNotify(Labels.Remotepercentagerequired);
      return;
    } else if (jobRequiredLanguages.length === 0) {
      errorNotify(Labels.jobRequiredLanguages);
      setButtonDisable(false);
      return;
    } else if (!Workinghour) {
      errorNotify(Labels.Workinghoursrequired);
      setButtonDisable(false);
      return;
    } else if (yearlysalary > yearlysalary2){
      errorNotify(Labels.yearlysalaryValidation || "Minimum Salary cannot be more than maximum salary.");
      setButtonDisable(false);
      return;
    } else {

      setbtndisable(true);
      let postDataToSend;
      if (employerTypeName === "Consultancy") {
        const postdata1 = {
          jobName: jobtitle,
          jobCategory: jobacate.id,
          jobinformation: jobinfo,
          language1: "en",
          salary: salary ? salary?.replace(",", "") : "",

          jobTypes: jobtype.toString()?.replace(/(^,|,$|,,+)/g, ""),
          typePercentage: typePercentage,

          contract: Array.isArray(jobtype) ? 0 : 1,
          experianceYears: userExperienceLevel,
          countryID: countrycompany?.id,
          address: counsultancyAdress?.name,

          jobSkills: skills?.toString()?.length
            ? skills?.toString()?.replace(/(^,|,$|,,+)/g, "")
            : null,
          applicationLastDate: date,
          language: "en",
          certifications: certifications,

          remote: null,
          hybrid: null,

          //  Needed values______________________________________________________________________________________________________

          officePresence: officePresence,
          officePercentage: officePercentage,
          remotePercentage: remotePercentage,
          withinCountry: countryValue,
          withinContinents: continentValue,
          jobRadious: radiusValue,
          stateID: stateID,
          city: cityvalue,

          //  Needed values______________________________________________________________________________________________________

          whatYouWillDo: whatYouWillDo,
          jobRequireTravel: jobRequireTravel,
          travelPercentage: travelPercentage ? travelPercentage : "", //new
          willingToRelocate: willingToRelocate,
          receiveCoverLetter: receiveCoverLetter,
          showYourProfile: showProfile,
          allowContactByMail: contactBymail,
          salary: salary?salary?.toString()?.replace(",", ""):0,
          salaryMax: salaryMax?salaryMax?.toString()?.replace(",", ""):0,
          salaryCalucateitem: salarycalcuvalue,
          companyLogo: companyLogo,
          expectedLanguage: JSON.stringify(jobRequiredLanguages),
          additionalLanguage: JSON.stringify(jobRequiredLanguages1),
          companyid: companyName,
          type: "Consultancy",
          workingDuration: Workinghour,
          additionalQualification: additionalQualification,
          currency: currency,
          workingHours: workingHours || "8",
          salaryPeriod: salaryPeriod,
          JobPostDuration: JobPostDuration,
          Jobinformations: JSON.stringify(Questiondata),
          description:jobdescription,
          anualSalaryMin: yearlysalary ? yearlysalary : "",
          anualsalaryMax: yearlysalary2 ? yearlysalary2 : "",
        };

        postDataToSend = {
          ...postdata1,
          officePercentage: officePercentage,
        };

      } else {
        const postdata = {
          jobName: jobtitle,
          jobCategory: jobacate.id,
          jobinformation: jobinfo,

          jobTypes: jobtype.toString()?.replace(/(^,|,$|,,+)/g, ""),
          typePercentage: typePercentage,

          contract: Array.isArray(jobtype) ? 0 : 1,
          experianceYears: userExperienceLevel,
          countryID: country?.id,
          address: Adress?.name,
          jobSkills: skills?.toString()?.length
            ? skills?.toString()?.replace(/(^,|,$|,,+)/g, "")
            : null,
          applicationLastDate: date,
          language: "en",
          language1: "en",
          certifications: certifications,
          remote: null,
          hybrid: null,
          //  Needed values______________________
          officePresence: officePresence,
          officePercentage: officePercentage,
          remotePercentage: remotePercentage,
          withinCountry: countryValue,
          withinContinents: continentValue,
          jobRadious: radiusValue,
          stateID: stateID,
          city: cityvalue,
          //  Needed values______________________________________________________________________________________________________
          whatYouWillDo: whatYouWillDo,
          jobRequireTravel: jobRequireTravel,
          travelPercentage: travelPercentage ? travelPercentage : "",
          willingToRelocate: willingToRelocate,
          receiveCoverLetter: receiveCoverLetter,
          showYourProfile: showProfile,
          allowContactByMail: contactBymail,
          salary: salary?salary?.toString()?.replace(",", ""):0,
          salaryMax: salaryMax?salaryMax?.toString()?.replace(",", ""):0,
          salaryCalucateitem: salarycalcuvalue,
  
          companyLogo: companyLogo,
          expectedLanguage: JSON.stringify(jobRequiredLanguages),
          additionalLanguage: JSON.stringify(jobRequiredLanguages1),
          companyid: companyName,

          travel: travel,
          workingDuration: Workinghour,
          workingHours: workingHours || "8",
          additionalQualification: additionalQualification,
          currency: currency,
          salaryPeriod: salaryPeriod,
          JobPostDuration: JobPostDuration,
          officePercentage: officePercentage !== null ? officePercentage : "",
          Jobinformations: JSON.stringify(Questiondata),
          description:jobdescription,
          anualSalaryMin: yearlysalary ? yearlysalary : "",
          anualsalaryMax: yearlysalary2 ? yearlysalary2 : "",
        };

        postDataToSend = {
          ...postdata,
          officePercentage: officePercentage,
          language: localStorage.getItem("language"),
        };
      }

      const result = await axiosAPI.post("/getCompanyProfile");
      const { status, data, message } = result.data;

      if (
        !data.companyName ||
        !data.businessCategory ||
        !data.companySize ||
        !data.headQuarterCountry ||
        !data.headQuarterState ||
        !data.headQuarterCity
      ) {
        infoNotify(Labels.FillProfile);
        setButtonDisable(false);
        setTimeout(() => {
          navigate("/employerProfile");
        }, 2000);
      } else {
       
        await axiosAPI
          .post("/addjob", postDataToSend)
          .then((response) => {
            const { success, message, data } = response.data;
            if (success === true) {
              succesNotify(message);
              setButtonDisable(false);
              navigate("/employerJobList");
              setbtndisable(false);
            } else {
              setbtndisable(false);
            }
          })
          .catch((error) => {
            setButtonDisable(false);
            setbtndisable(false);
          });
      }
    }
  };

  const [Questiondata, setQuestiondata] = useState([]);

  useEffect(() => {
    const getJobInfo = async () => {
      try {
        const result = await axiosAPI.get("/getJobInformation");
        const { success, data } = result.data;
        const updatedData = data.map((item) => ({
          ...item,
          value: "",
        }));
        setQuestiondata(updatedData);
      } catch (error) {
        console.error("Error fetching job information:", error);
      }
    };

    getJobInfo();
  }, []);

  const handleChange = (id, newValue) => {
    setQuestiondata((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };

  const [dynamicFields, setDynamicFields] = useState([]);

  const handleDynamicChange = (index, field, value) => {
    if (value) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }

    const updatedFields = [...dynamicFields];
    updatedFields[index][field] = value;
    setDynamicFields(updatedFields);

    const updatedQuestionData = Questiondata.map((item) => {
      if (item.key === "others") {
        return { ...item, value: JSON.stringify(updatedFields) };
      }
      return item;
    });

    setQuestiondata(updatedQuestionData);
  };

  const addNewField = () => {
    setDynamicFields([
      ...dynamicFields,
      { question: "", answer: "" },
    ]);
    setButtonDisable(true);
  };

  const removeField = (index) => {
    const updatedFields = dynamicFields.filter((_, i) => i !== index);
    setDynamicFields(updatedFields);

    const updatedQuestionData = Questiondata.map((item) => {
      if (item.key === "others") {
        return { ...item, value: JSON.stringify(updatedFields) };
      }
      return item;
    });

    setQuestiondata(updatedQuestionData);
  };

  const [CreateJobView, setCreateJobView] = useState(true);

  const setView = (e) => {
    setCreateJobView(e);
  };

  // -----------------Remote Settings------------------------------

  const Travelchange = (event) => {
    setJobRequireTravel(event.target.value);
  };

  // -----------------Add Language------------------------------
  const addLanguage = () => {
    setTimeout(() => {
      setErrMsg({
        spokenErr: false,
        writtenErr: false,
        languageErr: false,
      });
    }, 2000);
    if (language) {
      // return setErrMsg({ languageErr: true });
      if (!spoken) {
        return setErrMsg({ spokenErr: true });
      }
      if (!written) {
        return setErrMsg({ writtenErr: true });
      }

      const data = {
        written: Wlevel, // string w level name
        spoken: Rlevel, // string r level name
        language: languageName, // string

        languageid: language.l_id, // object (all data available)
        writtenid: written.id, // id of written
        spokenid: spoken.id, // id  of spoken
      };

      setjobRequiredLanguages((prevLanguages) => [...prevLanguages, data]);
      setLanguage("");
      setLanguageName("");
      setLanguageReadLevel("");
      setLanguageWriteLevel("");
      setWLevel("");
      setRLevel("");
    } else {
      return setErrMsg({ languageErr: true });
    }
  };
  const removeAddedLanguage = (index) => {
    const updatedLanguages = [...jobRequiredLanguages];
    updatedLanguages.splice(index, 1);
    setjobRequiredLanguages(updatedLanguages);
  };

  // ---------------------------- Nice to have language----------------------
  const addLanguage1 = () => {
    setTimeout(() => {
      setErrMsg1({
        languageErr1: false,
      });
    }, 2000);
    if (language1) {
      const data = {
        language1: languageName1, // string

        languageid1: language1.l_id, // object (all data available)
      };

      setjobRequiredLanguages1((prevLanguages1) => [...prevLanguages1, data]);
      setLanguage1("");
      setLanguageName1("");
    } else {
      return setErrMsg1({ languageErr1: true });
    }
  };

  const removeAddedLanguage1 = (index) => {
    const updatedLanguages1 = [...jobRequiredLanguages1];
    updatedLanguages1.splice(index, 1);
    setjobRequiredLanguages1(updatedLanguages1);
  };

  const [error, setError] = useState(false);

  const addinfo = () => {
    if (jobinfo.length == 0) {
      errorNotify(Labels.PleaseselectaJobInformation);
    } else {
      setError(false);
      if (jobdesc !== undefined) {
        // Check if jobdesc is defined
        const data = {
          id: jobinfo.id,
          key: jobinfo.key,
          name: jobinfo.name,
          value: jobdesc,
        };
        setjobinformation((prevInformation) => [...prevInformation, data]);
        setjobinfo("");
        setFormData({ ...formData, jobdesc: "" });
        succesNotify(Labels.JobInformationAdded);
      } else {
        setError(true);
      }
    }
  };

  const removeAddedJobInfo = (index) => {
    const updatedLanguages = [...Questiondata];
    updatedLanguages.splice(index, 1);
    setQuestiondata(updatedLanguages);
  };
  const [hybridErr, setHybridErr] = useState(false);

  const [image, setImage] = useState("");
  const [selectImage, setSelectImage] = useState(false);
  const [imagename, setImagename] = useState(null);
  const cropperRef = createRef();
  const [openImage, setOpenImage] = useState(false);

  const handleUpload = async () => {
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    uploadProfilePicture(croppedDataURL, imagename);
  };

  const uploadProfilePicture = async (manipulatedDataURL, imagename) => {
    const formData = new FormData();
    const blob = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData.append("images", blob);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/webp",
    ];
    const maxFileSize = 3145728;

    if (!allowedTypes.includes(blob.type)) {
      // throw new Error(Labels.invaildFile);
      return errorNotify(Labels.invaildFile);
    }

    if (blob.size > maxFileSize) {
      // throw new Error(Labels.imageValidation);

      return errorNotify(Labels.imageValidation);
    }
    const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = imagename.name; // Replace with your desired file name
    const mimeType = imagename.type; // Replace with your desired MIME type

    const file = new File([blob2], fileName, { type: mimeType });

    const formData2 = new FormData();
    formData2.append("images", file);
    formData2.append("language", localStorage.getItem("language"));

    try {
      const response = await axiosupload.post("/addjob", formData2, tokens);
      const { status, message } = response.data;
      if (status === "successs") {
        succesNotify(message);
        setSelectImage(false);
        setOpenImage(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <Paper elevation={0} sx={{ zIndex: 1 }}>
      <Typography variant="h6" sx={{ color: "#2B4947", p: 2 }}>
        {Labels?.Createanewjob}
      </Typography>
      <Divider />
      <Box
        sx={{ p: 2, height: "73vh", overflow: "auto" }}
        className={classes.customColoredScrollbar}
      >
        <Grid container spacing={2}>
          <SimpleBackdrop open={backdropOpen} />

          {/* --------------------------------------------------------------------- Basic details----------------------------   */}
          <Grid item xs={12} xl={6}>
            <Grid container spacing={1}>
              {/* Logo Commented on 27-11-24 */}
              {/* <Grid item xs={12} sm={3} md={3} lg={2} xl={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    height: "100%", // Ensure the parent box takes up the full height
                    minHeight: "130px", // Set minimum height as per your requirement
                  }}
                >
                  <Avatar
                    sx={{
                      width: "auto",
                      height: 130,
                      width: "100%",
                      borderRadius: 1,
                      cursor: "pointer",
                      borderColor: "#000000",
                      border: "2px solid transparent",
                      borderRadius: `10px`,
                    }}
                    src={logoPath}
                    onClick={() => setOpenImage(true)}
                  />
                </Box>

                <Dialog
                  open={selectImage}
                  onClose={() => setSelectImage(false)}
                >
                  <DialogTitle>
                    <Stack direction="row" justifyContent="flex-end">
                      <Close
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSelectImage(false)}
                      />
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      sx={{
                        width: {
                          xs: "60%",
                          sm: "50%",
                          md: "60%",
                          lg: "80%",
                        },
                        height: {
                          xs: "60%",
                          sm: "50%",
                          md: "60%",
                          lg: "80%",
                        },
                      }}
                    >
                      <Cropper
                        ref={cropperRef}
                        style={{ height: "100%", width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ padding: "25px" }}>
                    <CustomButton
                      onClick={handleUpload}
                      label={Labels.Upload}
                      style={{ padding: "5px 18px" }}
                    />
                  </DialogActions>
                </Dialog>
              </Grid> */}

              {/* <Grid item xs={12} sm={9} md={9} lg={10} xl={9}> */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper elevation={0} sx={{ my: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      background: "#DEF0EA",
                      borderRadius: "5px",
                      border: "1px solid #ecf4f1",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {Labels?.Basicdetails}
                    </Typography>
                  </Stack>
                </Paper>

                {/* ---------------------Company Name-------------------------- */}
                {/* Implemented #fadde1 Color */}
                {employerTypeName == "Consultancy" ? (
                  <Grid item xs={12} lg={12}>
                    <Box sx={{ m: "10px 0px" }}>
                      <AddedCompanies
                        value={companyName}
                        setValue={setcompayname}
                        setcompanyid={setcountrycompany}
                        setView={setView}
                      />
                    </Box>
                  </Grid>
                ) : null}

                {/* ----------------------------------country------------------------- */}
                {/* Did not Implement #fadde1 Color */}

                {employerTypeName !== "Consultancy" ? (
                  <Grid item xs={12} lg={12} my={1.5}>
                    <Box sx={{ m: "10px 5px" }}>
                      <label>
                        {Labels?.Country}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <CompanyCountry
                        value={country}
                        setValue={setcountry}
                        setAdressId={setAdress}
                        setAddressData={setAddressData}
                      />
                    </Box>
                  </Grid>
                ) : null}

                {/* ------------------------------Address------------------------------   */}
                {/* Did not Implement #fadde1 Color */}
                {employerTypeName !== "Consultancy" ? (
                  <Grid item xs={12} lg={12}>
                    <span style={{ fontSize: "14px" }}>{Labels?.Address} </span>
                    {/* <span style={{ color: "red" }}>*</span> */}
                    <CompanyBranchAddress
                      value={Adress}
                      setValue={setAdress}
                      country={country}
                      setstateID={setstateID}
                      setcityvalue={setcityvalue}
                    />
                    {/* Country : Object { id: 101, name: "India" } */}
                  </Grid>
                ) : null}
              </Grid>

              {/* ----------------------------------------------------------------- Consultancy Country--------------------------------- */}
              {/* Implemented #fadde1 Color */}
              {employerTypeName == "Consultancy"
                ? CreateJobView && (
                    <Grid item xs={12} lg={12} sx={{position:"relative", bottom:'13px'}}>
                      <label>
                        {Labels?.Country}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <CountryByCompany
                        value={countrycompany}
                        setValue={setcountrycompany}
                        companyName={companyName}
                        setAdressId={setCounsultancyAdress}
                        setAddressData={setAddressData}
                      />
                    </Grid>
                  )
                : null}

              {/* ----------------------------------------------------------------- Consultancy Address--------------------------------- */}
              {employerTypeName == "Consultancy"
                ? CreateJobView && (
                    <Grid item xs={12} lg={12} sx={{position:'relative', bottom:'3px'}}>
                      <span style={{ fontSize: "14px" }}>
                        {Labels?.Address}{" "}
                      </span>
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <CounsultancyAddress
                        value={counsultancyAdress}
                        setValue={setCounsultancyAdress}
                        countrycompany={countrycompany}
                        companyName={companyName}
                        // setAddressData={setAddressData}
                      />
                    </Grid>
                  )
                : null}

              {/* ----------------------------------------------------------------- Job title--------------------------------- */}
              {/* Implemented #fadde1 Color */}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                sx={{ marginTop: "10px" }}
              >
                <label style={{ position: "relative", bottom: "1px" }}>
                  {Labels?.JobTitle} <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  onChange={(e) => Createjobads(e)}
                  value={jobtitle}
                  name="jobtitle"
                  placeholder={Labels?.JobTitle}
                  sx={{ position: "relative", left: "5px", width: "98%" }}
                  inputProps={{
                    sx: {
                      height: "40px",
                      padding: `0px`,
                      paddingLeft: "10px",
                      backgroundColor: !jobtitle ? "#fadde1" : "white", // Conditional background color
                      "&::placeholder": {
                        fontSize: "14px", // Adjust placeholder font size
                      },
                    },
                  }}
                />
              </Grid>

              {/* ----------------------------------------------------------------- Job Category --------------------------  */}

              {CreateJobView && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box sx={{ m: "10px 5px" }}>
                    <label>
                      {Labels?.JobCategory}
                      <span style={{ color: "red" }}>* </span>
                    </label>
                    <JobCategory
                      value={jobacate}
                      setValue={setjobcate}
                      setButtonDisable={setButtonDisable}
                    />
                  </Box>
                </Grid>
              )}

              {/* ----------------------------------------------------------------- Office presence % --------------------------------- */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label>
                    <span style={{ position: "relative", left: "10px" }}>
                      {Labels?.InOffice}
                    </span>
                  </label>
                  <InputWithLabel
                    label={Labels.InOffice}
                    name="officePercentage"
                    value={officePercentage}
                    type="number"
                    setValue={setofficePercentage}
                    style={{ width: "100%", position: "relative", left: "8px" }}
                    onChange={setofficePercentagefn}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label>
                    <span> {Labels?.InRemote} </span>
                  </label>
                  <InputWithLabel
                    label={Labels.InRemote}
                    name="remotePercentage"
                    value={remotePercentage}
                    type="number"
                    setValue={setremotePercentage}
                    style={{
                      width: "103%",
                      position: "relative",
                      right: "8px",
                    }}
                    onChange={setremotePercentagefn}
                  />
                </Grid>
              </Grid>

              {/* ----------------------------------------------------------------- Hybrid for Office presence---------------------------- */}
              {true && (
                // {(isHybrid || isRemote) && (

                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: "5px auto",
                    position: "relative",
                    right: "4px",
                    opacity: isRemote ? 1 : 0.4,
                  }}
                >
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <label>
                        <>
                          <span> {Labels.WithinsameContinent} </span>
                        </>
                      </label>
                      <Select
                        sx={{ height: "40px" }}
                        value={continentValue}
                        disabled={!isRemote}
                        onChange={(e) => {
                          setcontinentValue(e.target.value);
                        }}
                      >
                        <MenuItem value={1}>{Labels.yes}</MenuItem>
                        <MenuItem value={0}>{Labels.No}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <label>
                        <>
                          <span> {Labels.Withinsamecountry} </span>
                        </>
                      </label>
                      <Select
                        sx={{ height: "40px" }}
                        value={countryValue}
                        disabled={!isRemote}
                        onChange={(e) => {
                          setcountryValue(e.target.value);
                        }}
                      >
                        <MenuItem value={1}>{Labels.yes}</MenuItem>
                        <MenuItem value={0}>{Labels.No}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label style={{ position: "relative", bottom: "1px" }}>
                      <>
                        <span> {Labels.Withinaradius} (km)</span>
                      </>
                    </label>
                    <TextField
                      type="number"
                      fullWidth
                      sx={{
                        height: "50px",
                        position: "relative",
                        bottom: "3px",
                      }}
                      inputProps={{
                        sx: {
                          height: "40px",
                          padding: `0px 5px`,
                          paddingLeft: "10px",
                        },
                      }}
                      disabled={!isRemote}
                      onChange={setradiusfn}
                      value={radiusValue}
                      // id="outlined-required"
                    />
                  </Grid>
                </Grid>
              )}

              {/* ----------------------------------------------------------------- Contract Type--------------------------------- */}

              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    m: "-10px 4px 10px",
                    position: "relative",
                    right: "1px",
                  }}
                >
                  <label>{Labels.ContractType}</label>
                  <SelectJobType
                    value={jobtype}
                    setValue={setjobtype}
                    label={Labels.ContractType}
                  />
                </Box>
              </Grid>

              {/* ----------------------------------------------------------------- Contract Type %------------------------------------- */}

              <Grid item xs={12} lg={12}>
                <label style={{ position: "relative", left: "2px" }}>
                  {Labels.FullPartTime}
                </label>
                <Percentage
                  value={
                    jobtype == 2 ? 10 : typePercentage ? typePercentage : 10
                  }
                  disabled={jobtype == 2}
                  name="typePercentage"
                  placeholder={Labels.FullPartTime}
                  setValue={settypepercentage}
                  sx={{
                    padding: "10px 12px",
                    fontSize: "14px", // Apply fontSize style here
                    fontFamily: "Poppins",
                    ...style,
                  }}
                />
              </Grid>

              {/* ----------------------------------------------------------------- Working hours per -------------------------- */}
 
              {/* <Grid item xs={12} md={6} mt={1}>
                <span
                  style={{
                    position: "relative",
                    left: "3px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {Labels.ExpectedHours}
                </span>
                <WorkHour
                  InputLabel={Labels.ExpectedHours}
                  label={Labels?.Workinghours}
                  name="WorkingDuration"
                  value={Workinghour}
                  setValue={setWorkinghour}
                />
              </Grid> */}

              {/* ----------------------------------------------------------------- Hours -------------------------- */}
              {/* <Grid item xs={12} md={6}>
                <span
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "5px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  {Labels?.Workinghours}
                </span>
                <InputWithLabel
                  InputLabel={" "}
                  label={Labels?.Workinghours}
                  name="workingHours"
                  value={workingHours ? workingHours : ""}
                  type="number"
                  inputProps={{
                    sx: { height: "19px", color: "black" },
                    min: 0,
                  }}
                  style={{ width: "100%", position: "relative", bottom: "1px" }}
                  onChange={(e) => {
                    Createjobads(e);
                    setButtonDisable(false);
                  }}
                  onKeyDown={(e) => {
                    // Allow only numbers, backspace, delete, arrow keys, etc.
                    if (
                      !/[0-9]/.test(e.key) && // Only allow numeric keys
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "ArrowUp" && // Allow ArrowUp for increment
                      e.key !== "ArrowDown" && // Allow ArrowDown for decrement
                      e.key !== "Tab" &&
                      e.key !== "+"
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid> */}

              {/* ------------------------------- Salary -------------------------------? */}

              {/* <Grid container sx={{position:"relative", left:"5px"}}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputWithLabel
                    fullWidth
                    InputLabel={
                      <span style={{ position: "relative", bottom: "2px" }}>
                        {Labels.Salary} (Min)
                      </span>
                    }
                    label={Labels.Amount}
                    name="salary" // Unique name for each input
                    value={
                      salary
                        ? salary
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    type="text"
                    style={{
                      width: "99%",
                      position: "relative",
                    }}
                    inputProps={{ sx: { height: "18px" }, min: 0 }}
                    onChange={(e) => {
                      Createjobads(e);
                      setButtonDisable(false);
                    }}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "ArrowUp" &&
                        e.key !== "ArrowDown" &&
                        e.key !== "Tab" &&
                        e.key !== "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6} md={6}>
                  <InputWithLabel
                    fullWidth
                    InputLabel={
                      <span style={{ position: "relative", bottom: "2px" }}>
                        {Labels.Salary} (Max)
                      </span>
                    }
                    label={Labels.Amount}
                    name="salary2" // Unique name for each input
                    value={
                      salary
                        ? salary
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    type="text"
                    style={{
                      width: "99%",
                      position: "relative",
                    }}
                    inputProps={{ sx: { height: "18px" }, min: 0 }}
                    onChange={(e) => {
                      Createjobads(e);
                      setButtonDisable(false);
                    }}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "ArrowUp" &&
                        e.key !== "ArrowDown" &&
                        e.key !== "Tab" &&
                        e.key !== "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
              </Grid> */}

              {/* <Grid
                item
                xs={12}
                md={12}
                justifyContent="space-between"
                direction="row"
                display="flex"
              >
                <Currency
                  name="setCurrency"
                  value={currency}
                  setValue={setCurrency}
                  setButtonDisable={setButtonDisable}
                  style={{ width: "96%", position:"relative", left:"2px" }}
                />
               
                <SalaryPeriod
                 style={{ width: "99%" }}
                  InputLabel={Labels.Period}
                  label={Labels?.Workinghours}
                  name="salaryPeriod"
                  value={salaryPeriod ? salaryPeriod : ""}
                  setValue={setsalaryPeriod}
                  setButtonDisable={setButtonDisable}
                />
              </Grid> */}

              

              <Grid container spacing={1} sx={{ margin: "0px" }}>
                <Grid item xs={12} xl={12} style={{position:"relative", top:"0px"}}>
                  <Paper elevation={0} sx={{ my: 3.5 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        p: 1.5,
                        background: "#DEF0EA",
                        borderRadius: "5px",
                        border: "1 px solid #ecf4f1",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      >
                        {Labels.JobRequirementsExpectationsInfo}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <SalaryCalculatorModal
                    setyearlysalary={setyearlysalary}
                    setyearlysalary2={setyearlysalary2}
                    yearlysalary={yearlysalary}
                    yearlysalary2={yearlysalary2}
                    Createjobads={Createjobads}
                    salaryPeriod={salaryPeriod}
                    setsalaryPeriod={setsalaryPeriod}
                    currency={currency}
                    setCurrency={setCurrency}
                    Workinghour={Workinghour}
                    salaryValue={
                      salary
                        ? salary
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    salaryMax={salaryMax}
                    setSalaryMax={setSalaryMax}
                    setButtonDisable={setButtonDisable}
                    setsalarycalcuvalue={setsalarycalcuvalue}
                    salarycalcuvalue={salarycalcuvalue}
                    currencySymbol={currencySymbol}
                    setCurrencySymbol={setCurrencySymbol}
                  />
                </Grid>
     
              </Grid>
            </Grid>
          </Grid>

          {/* --------------------------------------------------------------------- Yes / No Questions -------------------- */}
          <Grid item xs={12} xl={6}>
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  p: 1.5,
                  background: "#DEF0EA",
                  borderRadius: "5px",
                  border: "1 px solid #ecf4f1",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {Labels?.Miscellaneous}
                </Typography>
              </Stack>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} my={1}>
                <Box sx={{ position: "relative", bottom: "2px" }}>
                  <label>{Labels?.Wouldyouliketoshowyourprofile}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={showProfile}
                    setValue={setShowProfile}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ position: "relative", bottom: "14px" }}>
                  <label>{Labels?.Canpeoplecontactyoubymail}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={contactBymail}
                    setValue={setShowEmail}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ position: "relative", bottom: "12px" }}>
                  <label>{Labels?.DoyouexpecttoreceiveaCoverletter}</label>
                  <YesNo
                    placeholder={`${Labels?.yes}/${Labels?.No}`}
                    value={receiveCoverLetter}
                    setValue={setExpectCoverLetter}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ position: "relative", bottom: "7px" }}>
                  <label>{Labels.JobPostDuration}</label>
                  <JobPostingDuration
                    placeholder={Labels.JobPostDuration}
                    value={JobPostDuration}
                    setValue={setJobPostDuration}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container  sx={{position:"relative", bottom:"20px"}}>
              <Grid item xs={12} xl={12} style={{position:"relative", top:"23px"}}>
                <Paper elevation={0} sx={{ my: 3.5 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      background: "#DEF0EA",
                      borderRadius: "5px",
                      border: "1 px solid #ecf4f1",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      {Labels.JobRequirementsExpectationsInfo}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>

              {/* ---------------------Added Languages -------------------------- */}
              <Grid item xs={12} xl={12} mb={2}>
                {jobRequiredLanguages.length !== 0 && (
                  <Grid container spacing={1}>
                    {jobRequiredLanguages.map((data, index) => (
                      <Grid item xs={8} md={4} lg={4} key={index} direction="row">
                        <Box
                          key={index}
                          sx={{
                            p: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            mb: 2,
                            cursor: "pointer",
                            xs: "100%",
                            width: {
                              xs: "100%", // Set width to 100% on xs screens
                              sm: "80%", // Set width to 80% on sm and larger screens
                            },
                          }}
                          // onClick={() => updateLanguage(val)}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600, width: "0%" }}
                            >
                              {`${data?.language}`}
                            </Typography>

                            <Stack direction="row" alignItems="center">
                              <HighlightOffTwoToneIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={() => removeAddedLanguage(index)}
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <Stack direction="row">
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                              >
                                {Labels?.Written}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                  mx: 2,
                                  width: "200px",
                                }}
                              >
                                {/* {data?.Wlevel} */}
                                {`${data?.written}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                              >
                                {Labels?.Spoken}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                  mx: 2,
                                  width: "200px",
                                }}
                              >
                                {/* {data?.Rlevel} */}
                                {`${data?.spoken}`}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                    {/* </Stack> */}
                  </Grid>
                )}

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={{ xs: "block", md: "space-between" }}
                  alignItems={{ xs: "center", md: "end" }}
                >
                  <Box sx={{ m: "10px 5px", width: "100%" }}>
                    <label>{Labels?.ExpectedLanguages}</label>
                    <span style={{ color: "red" }}>*</span>
                    <AllLanguage
                      languageName={languageName} // english
                      setLanguageName={setLanguageName}
                      value={language} // id ?
                      setValue={setLanguage}
                      label={Labels?.language}
                      setFilterLanguage={setFilterLanguage}
                      jobreqlang={jobRequiredLanguages}
                      type={"expectedlanguage"}
                    />
                  </Box>

                  <Box sx={{ m: "10px 5px", width: "100%" }}>
                    <label>{Labels?.Spoken}</label>
                    <span style={{ color: "red" }}>*</span>
                    <LanguageLevel
                      value={spoken}
                      setValue={setLanguageReadLevel}
                      setLevel={setRLevel}
                      label={Labels?.Spoken}
                      type={"expectedlanguage"}
                      jobreqlang={jobRequiredLanguages}
                    />
                    <br />
                  </Box>

                  <Box sx={{ m: "10px 5px", width: "100%" }}>
                    <label>{Labels?.Written}</label>
                    <span style={{ color: "red" }}>*</span>
                    <LanguageLevel
                      value={written}
                      setValue={setLanguageWriteLevel}
                      setLevel={setWLevel}
                      label={Labels?.Written}
                      type={"expectedlanguage"}
                      jobreqlang={jobRequiredLanguages}
                    />
                  </Box>

                  <Box sx={{ m: "10px 5px" }}>
                    <Button
                      variant="contained"
                      sx={{
                        background: "rgba(109, 160, 156, 0.15)",
                        color: "rgb(43, 73, 71)",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "rgba(109, 160, 156, 0.15)",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "grey",
                        },
                        boxShadow: "none",
                      }}
                      onClick={() => addLanguage()}
                    >
                      {Labels?.Add}
                    </Button>
                  </Box>
                </Stack>

                {errMsg?.languageErr && (
                  <span style={{ color: "#ff0000", fontSize: "13px" }}>
                    {Labels?.languagefieldisrequird}
                  </span>
                )}

                {errMsg?.writtenErr && (
                  <span style={{ color: "#ff0000", fontSize: "13px" }}>
                    {Labels?.Writtenfieldisrequird}
                  </span>
                )}

                {errMsg?.spokenErr && (
                  <span style={{ color: "#ff0000", fontSize: "13px" }}>
                    {Labels?.Spokenfieldisrequird}
                  </span>
                )}
              </Grid>

              {/* --------------------- nice to have Languages -------------------------- */}
              <Grid item xs={12} xl={12}>
                {jobRequiredLanguages1.length !== 0 && (
                  <Grid container spacing={1}>
                    {jobRequiredLanguages1.map((data, index) => (
                      <Grid item xs={9} md={4} lg={4} key={index} direction="row">
                        <Box
                          key={index}
                          sx={{
                            p: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            mb: 2,
                            cursor: "pointer",
                            xs: "100%",
                            width: {
                              xs: "100%", // Set width to 100% on xs screens
                              sm: "80%", // Set width to 80% on sm and larger screens
                            },
                          }}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600, width: "0%" }}
                            >
                              {`${data?.language1}`}
                            </Typography>

                            <Stack direction="row" alignItems="center">
                              <HighlightOffTwoToneIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={() => removeAddedLanguage1(index)}
                              />
                            </Stack>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={{ xs: "block", md: "space-between" }}
                  alignItems={{ xs: "center", md: "end" }}
                >
                  <Box sx={{ m: "-2px 5px", width: "100%" }}>
                    <label>{Labels?.Nicetohave}</label>
                    <AllLanguage
                      languageName={languageName1} // english
                      setLanguageName={setLanguageName1}
                      value={language1} // id ?
                      setValue={setLanguage1}
                      label={Labels?.language}
                      setFilterLanguage={setFilterLanguage1}
                      jobRequiredLanguages1={jobRequiredLanguages1}
                      type={"nicetohave"}
                    />
                  </Box>

                  <Box sx={{ m: "5px 5px" }}>
                    <Button
                      variant="contained"
                      sx={{
                        background: "rgba(109, 160, 156, 0.15)",
                        color: "rgb(43, 73, 71)",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "rgba(109, 160, 156, 0.15)",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "grey",
                        },
                        boxShadow: "none",
                      }}
                      onClick={() => addLanguage1()}
                    >
                      {Labels?.Add}
                    </Button>
                  </Box>
                </Stack>
                {errMsg?.languageErr && (
                  <span style={{ color: "#ff0000", fontSize: "13px" }}>
                    {Labels?.languagefieldisrequird}
                  </span>
                )}
              </Grid>
              <Grid container spacing={1}>
                {/* ------------------------------Seniority level--------------------------------- */}
                <Grid item xs={12} lg={12}>
                  <Box sx={{ m: "16px 5px" }}>
                    <label>{Labels?.ExperienceLevel}</label>
                    <ExperienceLevel
                      value={userExperienceLevel}
                      setValue={setUserExperienceLevel}
                      style={{
                        background: "white",
                      }}
                    />
                  </Box>
                </Grid>
                {/* --------------------- Qualifications -------------------------- */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ position: "relative", bottom: "8px" }}
                >
                  <InputWithLabel
                    InputLabel={
                      <>
                        <span> {Labels.EducationCertifications} </span>
                      </>
                    }
                    label={Labels?.Nicetohave}
                    name="certifications"
                    value={certifications}
                    style={{ width: "100%", position: "relative" }}
                    onChange={(e) => Createjobads(e)}
                  />
                </Grid>

                {/* -----------------------------travel---------------------------------------- */}
                <Grid item xs={12} md={12}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={jobRequireTravel == 1 ? 6 : 12}
                      md={jobRequireTravel == 1 ? 6 : 12}
                      lg={jobRequireTravel == 1 ? 6 : 12}
                      sx={{position:"relative", left:"5px", bottom:"8px" }}
                    >
                      <label>{Labels?.DoestheJobrequiretravelling}</label>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          onChange={Travelchange}
                          value={jobRequireTravel}
                          fullWidth
                          displayEmpty
                          variant="outlined"
                          sx={{
                            padding: "10px 12px",
                            fontFamily: "Poppins",
                            ...style,
                          }}
                        >
                          <MenuItem disabled value="">
                            <span style={{ color: "#bcbcbc", fontSize: "14px" }}>
                              {`${Labels?.yes}/${Labels?.No}`}
                            </span>
                          </MenuItem>
                          <MenuItem value="1" sx={{ fontSize: "14px" }}>
                            {Labels?.yes}
                          </MenuItem>
                          <MenuItem value="0" sx={{ fontSize: "14px" }}>
                            {Labels?.No}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {jobRequireTravel == 1 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        sx={{position:"relative",bottom:'8px'}}
                      >
                        <label>{Labels?.Percentageoftravellingrequired}</label>
                        <FormControl sx={{ width: "97%" }}>
                          <TextField
                            label={Labels?.TravelPercentage}
                            name="travelPercentage"
                            type="number"
                            value={travelPercentage}
                            onChange={(e) => setTravelPercentage(e.target.value)}
                            InputProps={{
                              sx: { height: "42px" },
                              inputProps: { min: 0, max: 100 }, // Restrict input to percentage range
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* --------------------- skils -------------------------- */}
                <Grid item xs={12} md={12}>
                  <Box sx={{ m: "0px 5px" }}>
                    <label>{Labels.ExpectedSkills}</label>

                    <Skills value={value} setValue={setskills} />
                  </Box>
                </Grid>

                {/* -------------------------Nice to have---------------------------------- */}
                <Grid item xs={12}>
                  <InputWithLabel
                    InputLabel={Labels?.Nicetohave}
                    label={Labels?.Nicetohave}
                    name="additionalQualification"
                    value={additionalQualification}
                    // setValue={setQualification2}
                    style={{ width: "100%" }}
                    onChange={(e) => Createjobads(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} xl={12}>
            <Paper elevation={0} sx={{ my: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  p: 1.5,
                  background: "#DEF0EA",
                  borderRadius: "5px",
                  border: "1 px solid #ecf4f1",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {Labels.JobOverview}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          {/* ------------------------------Job Information New --------------------- */}
          {Questiondata?.filter((item) => item.key !== "others").map((item) => (
            <Grid item xs={12} md={6} key={item.id}>
              <Box display="flex" alignItems="center">
                <label style={{ marginRight: "10px", minWidth: "200px" }}>
                  {item.name}
                </label>
                <TextField
                  fullWidth
                  label={Labels.description}
                  variant="outlined"
                  value={item.value}
                  onChange={(e) => handleChange(item.id, e.target.value)}
                  multiline
                  rows={4}
                  InputProps={{
                    sx: {
                      "& .MuiOutlinedInput-input": {
                        padding: "12px", // Adjusts padding inside the input
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          ))}

          <Grid
            container
            spacing={2}
            sx={{ width: "100%", position: "relative", left: "20px" }}
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={addNewField}
                sx={{
                  margin: "15px 0px 0px",
                  background: "#1C614E",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#1C614E",
                  },
                  "&:active": {
                    backgroundColor: "#1C614E",
                  },
                  "&:disabled": {
                    color: "white",
                    backgroundColor: "grey",
                  },
                  boxShadow: "none",
                }}
              >
                {Labels.AddQuestion}
              </Button>

              {dynamicFields.map((field, index) => (
                <Grid container spacing={2} key={index} my={1}>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      label={Labels.Title}
                      variant="outlined"
                      value={field.question}
                      onChange={(e) => handleDynamicChange(index, "question", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                    <Box display="flex" alignItems="center">
                      <TextField
                        label={Labels.description}
                        variant="outlined"
                        value={field.answer}
                        onChange={(e) => handleDynamicChange(index, "answer", e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{
                          sx: {
                            "& .MuiOutlinedInput-input": {
                              padding: "12px", // Adjusts padding inside the input
                            },
                          },
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeField(index)}
                        sx={{ marginLeft: "10px" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              ))}


                <Grid item xs={12} xl={12} mt={4}>
                  <Paper elevation={0} sx={{ my: 1 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        p: 1.5,
                        background: "#DEF0EA",
                        borderRadius: "5px",
                        border: "1px solid #ecf4f1",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      > {Labels.jobDescription}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
              
                <Grid container spacing={2} my={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <ReactQuill
                        theme="snow"
                        value={jobdescription}
                        style={{
                          width: "100%",
                          margin: "0 auto"
                        }}
                        onChange={(e) => {setjobdescription(e)}}
                      />

                   
                    </Box>
                  </Grid>
                </Grid>


            </Grid>
          </Grid>
          {/*------------------ Submit Button -------------------------- */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15px auto",
            }}
          >
            <CustomButton
              label={Labels?.CreateJob}
              style={{ padding: "5px 25px" }}
              disabled={btndisable}
              onClick={profanityChecker}
              // onClick={()=>{console.log(Questiondata)}}
            />
          </div>
        </Grid>
      </Box>
    </Paper>
  );
};

export default EmployerCreatejob;
