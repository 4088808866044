import {
  Avatar,
  Box,
  Container,
  Grid,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../../Images/fifyPluslogo.png";
import CustomButton from "../../Components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import MapIcon from "@mui/icons-material/Map";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";

import {
  errorNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import { Label } from "@mui/icons-material";
export default function CompanyDetailView() {
  const { id } = useParams();
  const redirect = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({});
  const [backdropOpen, setBackdrop] = useState(false);
  const getCompanyDetails = async () => {
    setBackdrop(true);
    const result = await axiosAPI.get(`/companyDetails?companyid=${id}`);
    const { status, data } = result.data;
    if (status) {
      setCompanyDetails(data);
      setBackdrop(false);
    } else {
      redirect("/jobseekerDashboard");
      setBackdrop(false);
    }
  };
  useEffect(() => {
    getCompanyDetails();
  }, []);
  const searchJobs = () => {
    const searchData = {
      language: "en",
      search: "",
      companyID: companyDetails?.id,
    };
    redirect("/jobList", { state: searchData });
  };
  const postRequestToFollow = async () => {
    const result = await axiosAPI.post(`/followCompany`, {
      companyid: id,
      type: "follow",
      language: localStorage.getItem("language"),
    });
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      getCompanyDetails();
    } else {
      errorNotify(message);
    }
  };
  const postRequestToUnFollow = async () => {
    const result = await axiosAPI.post(`/followCompany`, {
      companyid: id,
      type: "unfollow",
      language: localStorage.getItem("language"),
    });
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      getCompanyDetails();
    } else {
      errorNotify(message);
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <Container maxWidth="xl">
      <SimpleBackdrop open={backdropOpen} />
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: { xs: "75px", sm: "10px" },
          boxShadow: "0px 0px 10px #BBB",
          background: "white",
          mb: 4,
          padding: 2,
        }}
      >
        <Grid item xs={6} sm={6} md={8} sx={{ paddingBottom: "5px" }}>
          <Stack direction="row" spacing={2}>
            {companyDetails?.companyLogo?.endsWith("/") ? null : (
              <Avatar
                src={`${companyDetails?.companyLogo}`}
                sx={{
                  fontSize: "3rem",
                  height: "80px",
                  width: "80px",
                }}
              />
            )}
            <Stack>
              <Typography
                variant="h6"
                sx={{ color: "#1c614e", fontWeight: 600, mb: 2 }}
              >
                {companyDetails?.companyName}
              </Typography>

              <Stack>
                <Typography
                  sx={{
                    color: "#595959",
                    width: "240px",
                    fontWeight: 600,
                    mb: 2,
                    mx: 1,
                  }}
                >
                  {companyDetails?.followers} {Labels?.Followers}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: "#595959",
                    // width: "240px",
                  }}
                >
                <LocationOnIcon sx={{ color: "#1c614e" }} /> &nbsp;&nbsp;
                  {companyDetails?.city}, {companyDetails?.state}, {companyDetails?.country}
                </Typography>

                <Typography sx={{ color: "#595959", width: "240px", my: 2 }}>
                  <PhoneIcon sx={{ color: "#1c614e" }} />
                  &nbsp; +{companyDetails?.countryCode}{" "}
                  {companyDetails?.phoneNo}
                </Typography>

                <Typography
                  sx={{
                    mb:2,
                    display: "flex",
                    flexDirection: "row",
                    color: "#595959",
                  }}
                >
                   {Labels?.Zipcode} : {companyDetails?.zip}
                </Typography>
              </Stack>

              <Stack direction="row">
                {companyDetails?.following == false ? (
                  <Stack>
                    <CustomButton
                      label={Labels.Follow}
                      style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        height: "40px",
                        borderRadius: "4px",
                        marginRight: "16px",
                      }}
                      onClick={() => {
                        postRequestToFollow(companyDetails?.companyid);
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <CustomButton
                      label={Labels.Following}
                      style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        height: "40px",
                        borderRadius: "4px",
                        marginRight: "16px",
                      }}
                      onClick={() => {
                        postRequestToUnFollow(companyDetails?.companyid);
                      }}
                    />
                  </Stack>
                )}
                <Stack>
                  <Stack spacing={1}>
                    <a
                      onClick={() => {
                        const websiteUrl = companyDetails?.website?.startsWith(
                          "https://"
                        )
                          ? companyDetails?.website
                          : `https://${companyDetails?.website}`;

                        if (companyDetails?.website) {
                          window.open(websiteUrl, "_blank");
                        }
                      }}
                    >
                      {companyDetails?.website && (
                        <Button
                          variant="outlined"
                          style={{ color: "#1C614E", borderColor: "#1C614E" }}
                        >
                          {Labels?.Website}
                        </Button>
                      )}
                    </a>
                  </Stack>
                </Stack>
              </Stack>
              {/* <Stack>
                            <Typography sx={{ color: "#595959" , fontWeight: 400 }}>
                                {companyDetails?.headOfCountry} , &nbsp; since {companyDetails?.founded}
                                </Typography>
                            </Stack> */}
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={12} sx={{ paddingBottom: "16px" }}>
          {/* <Typography
                        variant="h6"
                        sx={{ color: "#1c614e", fontWeight: 600, mb: 2 }}
                    >
                        {companyDetails?.companyName}
                    </Typography> */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {/* <Stack direction="row" spacing={4}>
                                <Typography sx={{ color: "#595959", width: "180px" }}>
                                    Headquarters
                                </Typography>
                                <Typography sx={{ color: "#595959", fontWeight: 600 }}>
                                    Mountain View California
                                </Typography>
                            </Stack> */}

              {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between" mb={2}>
                             <Stack>
                                  <Typography sx={{ color: "#595959" }}>Founded</Typography>
                                    <Typography sx={{ color: "#595959", fontWeight: 700 }}>

                                        {companyDetails?.founded}
                                    </Typography>

                                    </Stack>
                                    <Stack>
                                    <Typography sx={{ color: "#595959" }}>Type</Typography>
                                    <Typography sx={{ color: "#595959", fontWeight: 700, mb: 2 }}>
                                        {companyDetails?.companyType}
                                    </Typography>
                                    </Stack>
                                    <Stack>
                                    <Typography sx={{ color: "#595959" , fontWeight: 700}}>{Labels?.Website}</Typography>
                                    <Typography sx={{ color: "#595959", fontWeight: 400, mb: 1 }}>
                                        {companyDetails?.website}
                                    </Typography>
                                    </Stack>
                            </Stack> */}
            </Grid>
            {/* <Grid item xs={12} md={6}>
                            <Stack direction="row" spacing={4}>
                                <Box>
                                    <Typography sx={{ color: "#595959" }}>Industry</Typography>
                                    {companyDetails.businessCategory &&
                                    Array.isArray(companyDetails.businessCategory) &&
                                    companyDetails.businessCategory.map((data, index) => (
                                    <Typography sx={{ color: "#595959", fontWeight: 700, mb: 2 }}>
                                    {data.name}
                                    </Typography>
                                    ))}
                                    <Typography sx={{ color: "#595959" }}>Founded</Typography>
                                    <Typography sx={{ color: "#595959", fontWeight: 700 }}>

                                        {companyDetails?.founded}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ color: "#595959" }}>Type</Typography>
                                    <Typography sx={{ color: "#595959", fontWeight: 700, mb: 2 }}>
                                        {companyDetails?.companyType}
                                    </Typography>
                                    <Typography sx={{ color: "#595959" }}>Website</Typography>
                                    <Typography sx={{ color: "#595959", fontWeight: 700 }}>
                                        {companyDetails?.website}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={"auto"} sx={{ paddingBottom: "16px" }}></Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ boxShadow: "0px 0px 10px #BBB", padding: 4, background: "white" }}
      >
        <Stack direction="column">
          <Typography
            variant="h6"
            sx={{ color: "#595959", mb: 1, fontWeight: 700 }}
          >
            {Labels?.AboutUs}
          </Typography>
          <Typography sx={{ color: "#595959", mb: 2 }}>
            {companyDetails?.companyDescription}
          </Typography>
          <Stack>
            <Typography variant="h6" sx={{ color: "#595959", fontWeight: 700 }}>
              {Labels?.CompanySize}
            </Typography>
            <Typography sx={{ color: "#595959", fontWeight: 400, mb: 2 }}>
              {companyDetails?.companySize
                ? companyDetails.companySize
                    ?.split("-")
                    ?.map((part) =>
                      part
                        ?.toString()
                        ?.replace(/[^0-9]/g, "")
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    )
                    .join(" - ")
                : ""}
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            sx={{ color: "#595959", mb: 1, fontWeight: 700 }}
          >
            {Labels?.BusinessIndustry}
          </Typography>

          <Stack direction="row" flexWrap="wrap">
            {/* {companyDetails.businessCategory &&
              Array.isArray(companyDetails.businessCategory) &&
              companyDetails.businessCategory.map((data, index) => ( */}
            <Typography
              // key={index}
              sx={{
                display: "inline",
                color: "#595959",
                fontWeight: 400,
                mb: 2,
              }}
            >
              {/* {data.name}
                  {index !== companyDetails.businessCategory.length - 1 && ","} */}
              {companyDetails?.businessCategory}
              &nbsp;
            </Typography>
            {/* ))} */}
          </Stack>
          <Stack>
            <Typography
              variant="h6"
              sx={{ color: "#595959", mb: 1, fontWeight: 700 }}
            >
              {Labels?.LatestJobs}
            </Typography>
            <Stack>
              <CustomButton
                label="See All Jobs"
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  height: "40px",
                  width: 150,
                  borderRadius: "4px",
                  marginRight: "16px",
                }}
                onClick={searchJobs}
              />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Container>
  );
}
