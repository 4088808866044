import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Fade,
  Paper,
  Popper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
  Button,
  Menu,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import WorkIcon from "@mui/icons-material/Work";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import React, { useEffect, useState } from "react";
import fifyPluslogo from "../../../Images/fifyPluslogo.png";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import ClearIcon from "@mui/icons-material/Clear";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import JobSeekerDrawer from "./JobSeekerDrawer";
import "../jobseekerStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllLabels } from "../../../redux/AllLabels";
import { axiosAPI } from "../../Axios/Axios";
import { profanityfn } from "../../Profanity/Profanity";
import {
  errorNotify,
  infoNotify,
  succesNotify,
} from "../../CommonCode/Commonfunc";
import bino from "../../../Images/binoculars-icon.png";

import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import TextfieldCustom from "../../Components/TextfieldCustom";
import CustomButton from "../../Components/CustomButton";
import JobSearchFilter from "../JobSearchPostLogin/JobSearchFilter";
import { Grid } from "semantic-ui-react";
import { HeadingProvider } from "../../CommonCode/HeadingProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  padding: "10px",
  width: `50vw`,
  height: "auto",
};

export default function JobSeekerNavbar({ NavHead }) {
  const theme = useTheme();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));
  const filterView = useMediaQuery(theme.breakpoints.down("md"));

  const language =
    localStorage.getItem("language") != null
      ? localStorage.getItem("language")
      : "en";

  const location = useLocation();
  const postData = location.state;
  const { search } = postData ? postData : "";
  const [formdata, setformData] = useState({
    jobtitle: search ? search : "",
  });
  const { jobtitle } = formdata;

  // const Labels = useSelector((state) => state.allLabels.labels);
  const goToHome = () => {
    redirect("/landingpage");
  };
  const getLabels = () => {
    dispatch(getAllLabels(language));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  useEffect(() => {
    getLabels();
  }, []);

  // ----------Notification settings-------------------

  const [anchorElNotification, setAnchorElNotification] = useState(null);

  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  // --------------get notifications---------------------
  const [notifications, setNotifications] = useState([]);
  const [MessageList, setMessageList] = useState([]);

  const data = useSelector( (state) => state.getJobseekerProfileData.jobseekerDetails);
  const uid = localStorage.getItem("uid");
  const getNotifications = async () => {
    const result = await axiosAPI.get("/getNotificationsList");
    const { status, data } = result.data;
    if (status) {
      const activeNotifications = data.filter(
        (notification) => notification.status == 0
      );
      setNotifications(activeNotifications);
    } else {
      setNotifications([]);
    }
  };

  const getChatList = async () => {
    const result = await axiosAPI.get(`/getOnlineChat?userid=${uid}`);
    const { status, data } = result.data;
  };

  useEffect(() => {
    getNotifications();
    getChatList();
  }, []);

  const clearnotificationfn = async (id) => {
    const result = await axiosAPI.post("/readNotification", { id: id });
    const { status, data } = result.data;
    getNotifications();
  };

  const notificationRedirect = (data) => {
    // Notification read api ?
    if (data.type === "job") {
      localStorage.setItem("jobId", data?.typeID);
      redirect(`/detailedJobView/${data?.typeID}`);
    } else if (data.type === "group") {
      redirect(`/groupDetail/${data?.typeID}`);
    }
    setAnchorElNotification(false);
  };
  const redirectToNotification = () => {
    redirect("/notifications");
    setAnchorElNotification(false);
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const setJobSerach = (e) => {
    const value = e.target.value;
    setformData({
      ...formdata,
      [e.target.name]: profanityfn(ProfanityList, value),
    });
  };

  const [searchValue, setSearchValue] = useState("");

  const searchJobs = (e) => {
    e.preventDefault();
    if (e.target.value.length < 2) {
      setSearchValue("");
      const searchData = {
        jobsearch: "",
      };
      redirect("/jobList", { state: searchData });
    } else {
      const searchData = {
        jobsearch: e.target.value.trim(),
      };
      setSearchValue(e.target.value);
      redirect("/jobList", { state: searchData });
    }
  };

  const resetFields = () => {
    // Clear the form data
    setformData({ jobtitle: "", joblocation: "" });
    const searchData = {
      search: "",
      city: "",
    };
    redirect("/jobList", { state: searchData });
  };

  const [jobSearchMenu, setjobSearchMenu] = useState(false);
  const [NavHeading, setNavHeading] = useState("Home");

  useEffect(() => {
    const heading = HeadingProvider(location.pathname);

    if (heading === "Dashboard") {
      setNavHeading(Labels.Dashboard);
    } else if (heading === "Profile") {
      setNavHeading(Labels.Profile);
    } else if (heading === "Recommended Jobs") {
      setNavHeading(Labels.recommendedJobs);
    } else if (heading === "Applied Jobs") {
      setNavHeading(Labels.appliedJobs);
    } else if (heading === "All Jobs") {
      setNavHeading(Labels.AllJobs);
    } else if (heading === "Saved Jobs") {
      setNavHeading(Labels.savedjobs);
    } else {
      setNavHeading("");
    }

    if (location.pathname === "/jobList") {
      setjobSearchMenu(true);
    } else {
      setjobSearchMenu(false);
      setopenadvancedsearch(false);
    }
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      setopenadvancedsearch(false);
    }
    getAppliedJobs();
  }, [isMobile, location]);

  const [openadvancedsearch, setopenadvancedsearch] = useState(false);

  const advancedsearchfn = () => {
    setopenadvancedsearch(!openadvancedsearch);
  };

  const [AppliedJobs, setAppliedJobs] = useState("");
  const postdata = {
    jobseekerid: uid,
  };
  const [appliedjobcount, setajcount] = useState(0);

  const getAppliedJobs = async () => {
    try {
      if (uid) {
        const result = await axiosAPI.post("/getAppliedJobs", postdata);
        const { success, data, message } = result.data;
        if (success) {
          setajcount(data?.applied_Jobs?.length + data?.deleted_Jobs?.length);
          setAppliedJobs(data?.applied_Jobs?.reverse());
        } else {
          errorNotify(message);
        }
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const searchfn = (e) => {
    setJobSerach(e);
    searchJobs(e);
  };

  const [Open, setopen] = useState(false);
  const handleOpen = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopenadvancedsearch(false);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: "white", px: 5 }}>
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              cursor: "pointer",
            }}
            alt=" "
            src={fifyPluslogo}
            onClick={goToHome}
          />
          <span
            style={{
              color: "#1C614E",
              marginLeft: "30px",
              fontSize: "25px",
              width: "350px",
            }}
          >
            {NavHeading}
          </span>

          {isMobile ? (
            <Stack
              sx={{
                mx: { xs: "12px", lg: "70px" },
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
              direction="row"
            >
              <JobSeekerDrawer
                jobSearchMenu={jobSearchMenu}
                advancedsearchfn={advancedsearchfn}
                AppliedJobs={AppliedJobs}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                flexWrap: "nowrap",
                display: { xs: "block", sm: "flex" },
                alignItems: "center",
                justifyContent: "space-between",
                width: { sm: "85%", lg: "100%" },
              }}
              direction={{
                xs: "column",
                sm: "row",
              }}
            >
              {jobSearchMenu && (
                <Stack
                  direction={{ xs: "row", md: "row" }}
                  spacing={2}
                  sx={{
                    px: 10,
                    pb: 2,
                    paddingTop: {
                      xs: "55px",
                      sm: "20px",
                    },
                    "@media (max-width: 599px)": {
                      px: 1,
                    },
                  }}
                >
                  <TextfieldCustom
                    label={Labels.Jobtitlelocationcompany}
                    style={{
                      borderRadius: "10px",
                      width: "500px",
                    }}
                    name="jobtitle"
                    value={jobtitle}
                    onChange={searchfn}
                  />
                  {/* <CustomButton
                    label={Labels?.search}
                    style={{ borderRadius: "10px" }}
                    onClick={searchJobs}
                  /> */}
                </Stack>
              )}
              {location.pathname == "/jobList" &&
                jobSearchMenu &&
                !isMobile && (
                  <>
                    <div
                      onClick={advancedsearchfn}
                      className="jobSearchMenuBtn"
                    >
                      <img src={bino} />
                      <span
                        style={{
                          left:
                            Labels.AdvancedSearch == "Advanced Search"
                              ? "40px"
                              : "5px",
                        }}
                      >
                        {Labels.AdvancedSearch}
                      </span>
                    </div>
                    <Link to={`/appliedJobs`} className="AppliedJobsBtn">
                      <WorkIcon />
                      <span
                        style={{
                          left:
                            Labels.appliedJobs == "Applied Jobs"
                              ? "45px"
                              : "20px",
                        }}
                      >
                        {Labels.appliedJobs} : {appliedjobcount}
                      </span>
                    </Link>
                  </>
                )}
              <Box
                sx={{
                  flexGrow: 1,
                  alignItems: "center !important",
                  textAlign: "right",
                }}
              >
                <nav id="jobseeker_navbar">
                  {language === "pl" ? (
                    <NavLink to="" className="navLink">
                      <LanguageIcon
                        sx={{
                          color: "#1C614E",
                          margin: "0 5px",
                          fontSize: "2rem",
                        }}
                      />
                      Polish
                    </NavLink>
                  ) : language === "de" ? (
                    <NavLink to="" className="navLink">
                      <LanguageIcon
                        sx={{
                          color: "#1C614E",
                          margin: "0 5px",
                          fontSize: "2rem",
                        }}
                      />
                      German
                    </NavLink>
                  ) : (
                    <NavLink to="" className="navLink">
                      <LanguageIcon
                        sx={{
                          color: "#1C614E",
                          margin: "0 5px",
                          fontSize: "2rem",
                        }}
                      />
                      English
                    </NavLink>
                  )}

                  <NavLink to="/chat" className="navLink">
                    <Badge
                      badgeContent={data?.ChatmessageCount ? data?.ChatmessageCount : "0"}
                      color="error"
                    >
                      <ChatOutlinedIcon
                        sx={{
                          color: "#1C614E",
                          margin: "0 5px",
                          fontSize: "2rem",
                        }}
                      />
                    </Badge>
                  </NavLink>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    
                    sx={{ cursor: "pointer" }}
                  >
                    <Badge badgeContent={notifications.length} color="error">
                      <NotificationsNoneOutlinedIcon
                      onClick={handleClickNotification}
                        sx={{
                          color: "#1C614E",
                          margin: "0 5px",
                          fontSize: "2rem",
                        }}
                      />
                    </Badge>
                  </Stack>
                </nav>
              </Box>
            </Stack>
          )}

          <Menu
            anchorEl={anchorElNotification}
            open={Boolean(anchorElNotification)}
            onClose={handleCloseNotification}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {notifications.length === 0 ? (
              <Paper
                sx={{
                  padding: "5px 15px",
                  boxShadow: "none",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Adjusted here
                  mb: 1,
                  cursor: "pointer",
                  width:'200px',
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ fontSize: "16px" }}>
                    {Labels.NotificationsEmpty}
                  </Typography>
                </Stack>
              </Paper>
            ) : (
              notifications.slice(0, 5).map((data, index) => (
                <>
                  <Paper
                    key={index}
                    sx={{
                      padding: "15px",
                      boxShadow: "none",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Adjusted here
                      mb: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between" // Changed to space-between for better spacing
                      sx={{ width: "100%" }} // Ensures Stack takes full width of Paper
                    >
                      <Avatar sx={{ mr: 2 }} src={`${data.logo}`} />
                      <Typography
                        sx={{ fontSize: "16px", flexGrow: 1 }} // Added flexGrow to take available space
                        onClick={() => notificationRedirect(data)}
                      >
                        {data?.message}
                      </Typography>
                      <ClearIcon
                        onClick={() => {
                          clearnotificationfn(data.id);
                        }}
                      />
                    </Stack>
                  </Paper>
                  <Divider />
                </>
              ))
            )}
           
          </Menu>
        </Toolbar>
      </AppBar>
      <Modal
        open={openadvancedsearch}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        keepMounted
      >
        <Box
          sx={{
            ...style,
            width: filterView ? "80vw" : "550px",
            maxHeight: "90vh",
            overflow: "none",
            borderRadius: "20px",
            zIndex: "999988",
          }}
        >
          <JobSearchFilter
            openadvancedsearch={openadvancedsearch}
            searchValue={searchValue}
            isMobile={isMobile}
            setopenadvancedsearch={setopenadvancedsearch}
          />
        </Box>
      </Modal>
    </>
  );
}
