import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Button,
  Skeleton,
  TextField, // Import TextField for search input
} from "@mui/material";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
// import { useNavigate } from "react-router-dom";
import CreateUser from "./createUser";
import { axiosAPI } from "../../Axios/Axios";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import DeleteUser from "./deleteUser";
import EditUser from "./editUser";
import InputWithLabel from "../../Components/InputWithLabel";

function UserList() {
  const Labels = useSelector((state) => state.allLabels.labels);
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [listRecruiter, setListRecruiter] = useState([]);
  const [filteredRecruiter, setFilteredRecruiter] = useState([]); // State for filtered list

  const [deleteUser, setDeleteUser] = useState(false);
  const [userid, setUserId] = useState("");
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const [counter, setCounter] = useState(1);

  const deleteUserById = (id) => {
    setDeleteUser(true);
    setUserId(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [loading, setloading] = useState(true);

  const getAllRecruiter = async () => {
    try {
      const res = await axiosAPI.post("/listAllRecruiter");
      const { data, status } = res.data;
      if (status) {
        const newData = data;
        newData.forEach((obj, index) => {
          obj.objid = index + 1;
        });
        setListRecruiter(newData);
        setFilteredRecruiter(newData); // Set filtered list initially
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  useEffect(() => {
    getAllRecruiter();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    const filteredData = listRecruiter.filter(
      (user) =>
        user?.name?.toLowerCase()?.includes(value) ||
        user?.email?.toLowerCase()?.includes(value) ||
        user?.contact?.toLowerCase()?.includes(value) ||
        user?.designation?.toLowerCase()?.includes(value) ||
        user?.jobCount?.toString()?.includes(value)
    );
    setFilteredRecruiter(filteredData);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.users}
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <CreateUser
            open={createUser}
            handleClose={() => setCreateUser(false)}
            getAllRecruiter={getAllRecruiter}
          />
          <EditUser open={editUser} handleClose={() => setEditUser(false)} />
          <InputWithLabel
            InputLabel={""}
            label={Labels.SearchUsers}
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            style={{ width: "300px", position: "relative", top: "10px" }}
          />
          <CustomButton
            onClick={() => setCreateUser(true)}
            label={Labels?.createUser}
          />
        </Stack>

        <TableContainer sx={{ my: 2 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  borderTop: "10px solid #EAEFF1",
                  borderBottom: "10px solid #EAEFF1",
                  background: "rgba(3, 132, 97, 0.3)",
                }}
              >
                <TableCell sx={{ fontWeight: 600 }}>{Labels?.sno}</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>{Labels?.name}</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.designation}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.emailAddress}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.ContactNumber}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.JobsPosted}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>
                  {Labels?.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  {Array(7)
                    .fill()
                    .map(() => {
                      return (
                        <TableRow
                          sx={{
                            borderTop: "10px solid #EAEFF1",
                            borderBottom: "10px solid #EAEFF1",
                            background: "#fff",
                          }}
                        >
                          {Array(7)
                            .fill()
                            .map(() => (
                              <TableCell>
                                <Skeleton
                                  variant="rectangular"
                                  width={`100%`}
                                  height={23}
                                  style={{ margin: "2px auto" }}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                      );
                    })}
                </>
              ) : (
                filteredRecruiter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow
                      key={user.name}
                      sx={{
                        borderTop: "10px solid #EAEFF1",
                        borderBottom: "10px solid #EAEFF1",
                        background: "#fff",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope={isSmallScreen ? "column" : "row"}
                      >
                        {user?.objid}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope={isSmallScreen ? "column" : "row"}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell>{user.designation}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.contact}</TableCell>
                      <TableCell>{user.jobCount}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {user.jobCount === 0 ? (
                            <DeleteIcon
                              sx={{
                                width: 28,
                                height: 28,
                                color: "#d32f2f",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteUserById(user.userid)}
                            />
                          ) : (
                            <DeleteForeverIcon
                              onClick={() =>
                                warningNotify(Labels.Cannotdeletethisstaff)
                              }
                              sx={{
                                width: 28,
                                height: 28,
                                color: "#d32f2f",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredRecruiter.length} // Use filtered data length for pagination
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
        />
        <DeleteUser
          open={deleteUser}
          handleClose={() => setDeleteUser(false)}
          userID={userid}
          getAllRecruiter={getAllRecruiter}
        />
      </ThemeProvider>
    </>
  );
}

export default UserList;
