import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Card,
  Avatar,
  Divider,
  Dialog,
  Skeleton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PublicIcon from "@mui/icons-material/Public";
import "./group.css";
import { useNavigate, useParams } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";
import {
  errorNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import "./group.css";
import { Item } from "semantic-ui-react";

function GroupDetails({ setApiCalled }) {
  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );
  const { id } = useParams();
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const classes = useStyles();
  const [groupInfo, setGroupInfo] = useState({});

  const [loading, setloading] = useState(false);

  // -------------------- GroupDetails --------------------
  const getGroupDetails = async () => {
    if (id) {
      const result = await axiosAPI.get(`/groupDetails?groupid=${id}`);
      const { status, data } = result.data;
      if (status) {
        setGroupInfo(data);
        getSimilarGroups(data.topicid);
        setloading(true);
      } else {
        setloading(true);
      }
    }
  };

  // -------------------- SimilarGroup --------------------
  const [similarGroup, setSimilarGroup] = useState([]);
  const getSimilarGroups = async (topicId) => {
    const result = await axiosAPI.post("/getSimilarGroups", {
      topicid: topicId,
    });
    const { status, data } = result.data;
    if (status) {
      setSimilarGroup(data);
    }
  };
  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };

  //------------------- Request to groups -------------------
  const postRequestToGroup = async (id) => {
    const result = await axiosAPI.post(`/requestToGroup?groupid=${id}`, { language:localStorage.getItem("language")});
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      getGroupDetails();
    } else {
      errorNotify(message);
    }
  };

  //------------------- Accept Invitation -------------------
  const acceptInvitation = async () => {
    try {
      const result = await axiosAPI.post("/acceptInvite", {
        groupid: id,
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        setApiCalled();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  //------------------- Reject Invitation -------------------
  const rejectInvitation = async () => {
    try {
      const result = await axiosAPI.post("/rejectInvite", {
        groupid: id,
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        redirect(`/searchGroupList`);
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  //------------------Cancel requests to groups -------------------
  const postRemoveRequestToGroup = async (id) => {
    const result = await axiosAPI.post(`/cancelRequest?groupid=${id}`,{ language:localStorage.getItem("language")});
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      getGroupDetails();
    } else {
      errorNotify(message);
    }
  };
  useEffect(() => {
    getSimilarGroups();
    getGroupDetails();
  }, [id]);

  const viewDetailedGroup = (id) => {
    redirect(`/groupDetail/${id}`);
  };
  function formatDate(inputDate) {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  }

  function formatDate(inputDate) {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  }
  const [openImage, setOpenImage] = useState(false);

  return (
    <>
      {!loading ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Item>
                <Skeleton variant="rectangular" width={`100%`} height={150} />
                <br />
                <Skeleton variant="rectangular" width={`100%`} height={100} />
                <br />
                <Skeleton variant="rectangular" width={`100%`} height={250} />
                {/* <br /> */}
                {/* <Skeleton variant="rectangular" width={`100%`} height={150} /> */}
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <Skeleton variant="rounded" width={`100%`} height={`100vh`} />
              </Item>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8}>
              <Paper elevation={0} sx={{ my: 1 }}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ p: 2 }}
                >
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{ color: "#2B4947", fontWeight: 600 }}
                    >
                      {groupInfo?.groupName}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#1C614E" }}>
                      {groupInfo?.categoryName}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#1C614E" }}>
                      {Labels?.createdOn} : {groupInfo?.createdOn}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#1C614E" }}>
                      {Labels?.groupOwner} : {groupInfo?.userDisplayName}
                    </Typography>
                    <Typography variant="subtitle1">
                      {groupInfo?.membersCount}
                      {groupInfo?.membersCount == 1
                        ? Labels?.Member
                        : Labels?.Members}
                    </Typography>
                  </Box>

                  <Avatar
                    src={
                      groupInfo?.grpLogoPath
                        ? `${groupInfo?.grpLogoPath}`
                        : null
                    }
                    alt={groupInfo?.groupName}
                    sx={{ width: 150, height: 150 }}
                    onClick={() => setOpenImage(true)}
                  />
                </Stack>
              </Paper>
              <Paper elevation={0} sx={{ my: 1, p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Status
                  </Typography>
                  {groupInfo?.statusKey !==
                  "Active" ? null : groupInfo?.memberStatusKey === "Invited" ? (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Button
                        variant="contained"
                        sx={{
                          background: "#fff",
                          color: "#000",
                          boxShadow: "none",
                          padding: "5px 10px",
                          border: "1px solid #00000040",
                          mx: 1,
                          "&:hover": { background: "#fff", color: "#000" },
                          "&:active": {
                            backgroundColor: "#1C614E",
                          },
                          "&:disabled": { color:"white",
                            backgroundColor: "#1C614E",
                          },
                        }}
                        onClick={() => acceptInvitation(groupInfo?.groupid)}
                      >
                        {Labels?.accept}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#fff",
                          color: "#d32f2f",
                          boxShadow: "none",
                          padding: "5px 10px",
                          border: "1px solid #00000040",
                          mx: 1,
                          "&:hover": { background: "#fff", color: "#d32f2f" },
                          "&:active": {
                            backgroundColor: "#1C614E",
                          },
                          "&:disabled": { color:"white",
                            backgroundColor: "#1C614E",
                          },
                        }}
                        onClick={() => rejectInvitation(groupInfo?.groupid)}
                      >
                        {Labels?.reject}
                      </Button>
                    </Stack>
                  ) : groupInfo?.memberStatusKey === "Requested" ? (
                    <Button
                      variant="contained"
                      sx={{
                        background: "#fff",
                        color: "#000",
                        boxShadow: "none",
                        padding: "5px 10px",
                        border: "1px solid #00000040",
                        mx: 1,
                        "&:hover": { background: "#fff", color: "#000" },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": { color:"white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      onClick={() => {
                        postRemoveRequestToGroup(groupInfo?.groupid);
                      }}
                    >
                      {Labels?.requested}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        background: "#fff",
                        color: "#000",
                        boxShadow: "none",
                        padding: "5px 10px",
                        border: "1px solid #00000040",
                        mx: 1,
                        "&:hover": { background: "#fff", color: "#000" },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": { color:"white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      onClick={() => {
                        postRequestToGroup(groupInfo?.groupid);
                      }}
                    >
                      {Labels?.Join}
                    </Button>
                  )}
                </Stack>
              </Paper>
              <Paper elevation={0} sx={{ p: 2, minHeight: "35vh" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {Labels?.About}
                </Typography>
                <Typography variant="body2">
                  {groupInfo?.description}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ my: 1 }}>
              <Card
                sx={{
                  borderRadius: 1,
                  padding: 0,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 10,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#1C614E", fontWeight: 500 }}
                  >
                    {Labels?.similarGroups}
                  </Typography>
                </div>
                <Divider />
                <Box
                  sx={{ height: { xs: "50vh", md: "70vh" }, overflow: "auto" }}
                  className={classes.customColoredScrollbar}
                >
                  {similarGroup ? (
                    similarGroup.map((val, i) => (
                      <Card
                        key={i}
                        sx={{
                          borderRadius: 1,
                          boxShadow: "none",
                          borderBottom: "1px solid #1C614E33",
                          cursor: "pointer",
                          p: 0.5,
                          "&:hover": {
                            backgroundColor: "#ffffffe5",
                          },
                         
                        }}
                        onClick={() => viewDetailedGroup(val.id)}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Avatar
                              src={
                                val.grpLogoPath === null || ""
                                  ? `${val.groupName.charAt(0)}`
                                  : `${val.grpLogoPath}`
                              }
                              alt=" "
                              style={{
                                maxWidth: 30,
                                maxHeight: 30,
                              }}
                            />
                            <Stack>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "#2B4947", fontWeight: 600 }}
                              >
                                {val.groupName}
                              </Typography>
                              <Stack direction="row" alignItems="center">
                                <PublicIcon sx={{ fontSize: 17 }} />
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#1C614E" }}
                                >
                                  &nbsp;{val.categoryName}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                          {/* <Stack>
                        <Typography variant="caption">
                          {getTimeDifference(val.createdOn)}
                        </Typography>
                      </Stack> */}
                        </Stack>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="body1" sx={{ p: 1 }}>
                      {Labels?.noGroupFound}
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Dialog open={openImage} onClose={() => setOpenImage(false)} maxWidth="lg">
            <Stack direction="row" spacing={1}>
              <Avatar
                src={
                  groupInfo?.grpLogoPath ? `${groupInfo?.grpLogoPath}` : null
                }
                alt="post"
                sx={{
                  height: 300,
                  width: 300,
                  margin: 1,
                }}
              />
              <CloseOutlinedIcon onClick={() => setOpenImage(false)} />
            </Stack>
          </Dialog>
        </>
      )}
    </>
  );
}

export default GroupDetails;
