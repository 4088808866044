import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../../../Components/CustomButton";
import { errorNotify, succesNotify } from "../../../CommonCode/Commonfunc";
import { axiosAPI } from "../../../Axios/Axios";
import { useSelector } from "react-redux";

export default function EmploymentDeleteAlert({
  open,
  handleClose,
  employmentId,
  educationId,
  LanguageId,
}) {
  const Labels = useSelector((state) => state.allLabels.labels);
  const deleteEmployment = async () => {
    if (employmentId) {
      try {
        const postData = { employmentid: employmentId,language:localStorage.getItem("language") };
        const result = await axiosAPI.post("/deleteEmployment", postData);
        const { success, message } = result.data;
        if (success) {
          succesNotify(message);
          handleClose();
        } else {
          errorNotify(message);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    } else if (educationId) {
      try {
        const postData = { educationid: educationId };
        const result = await axiosAPI.post("/deleteEducation", postData);
        const { success, message } = result.data;
        if (success) {
          succesNotify(message);
          handleClose();
        } else {
          errorNotify(message);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    } else if (LanguageId) {
      try {
        const result = await axiosAPI.get(
          `/deleteLanguageLevel?id=${LanguageId}`, {language:localStorage.getItem("language")}
        );
        const { status, message } = result.data;
        if (status) {
          succesNotify(message);
          handleClose();
        } else {
          errorNotify(message);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "300px",
          padding: "20px",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        {Labels?.areYouSure}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", fontFamily: "poppins" }}
        >
          {Labels?.wantToDelete}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginTop: "20px" }}>
        <CustomButton
          label={Labels?.delete}
          style={{
            width: 100,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={deleteEmployment}
        />
        <CustomButton
          label={Labels?.Cancel}
          style={{
            width: 100,
            borderRadius: "3px",
            fontSize: "14px",
            mb: 2,
          }}
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
}
