import {
  Avatar,
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  linearProgressClasses,
  LinearProgress,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WorkIcon from "@mui/icons-material/Work";
import InfoIcon from "@mui/icons-material/Info";
import PublicIcon from "@mui/icons-material/Public";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { axiosAPI } from "../../Axios/Axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  infoNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `white`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1C614E",
  },
}));

export default function JobSeekerProfileView() {

  const classes = useStyles();
  const { id } = useParams();
  const redirect = useNavigate();
  
  // --------------Get JobSeeker Profile------------------
  const [backdropOpen, setBackdrop] = useState(false);
  const [jobSeekerData, setJobSeekerData] = useState({});

  const getJobSeekerData = async () => {
    setBackdrop(true);
    try {
      const postData = {
        jobseekerid: id,
      };
      const result = await axiosAPI.post("/profile", postData);
      const { success, data } = result.data;
      if (result.data.success == undefined) {
        redirect("/searchJobSeeker");
      }
      if (success) {
        setJobSeekerData(data);
        setBackdrop(false);
      } else {
        setBackdrop(false);
      }
    } catch (error) {
      //// console.log(error.message);
      setBackdrop(false);
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  // --------------Get Matching jobSeekers------------------
  const [matchingJobSeekers, setMatchingJobSeekers] = useState([]);
  const getMatchingJobSeekers = async () => {
    const jobId = localStorage.getItem("jobId");
    const result = await axiosAPI.post(`/matching?jobid=${jobId}`);
    const { success, data, message } = result.data;
    if (success === true) {
      setMatchingJobSeekers(data);
    } else {
      //// console.log(message);
    }
  };
  useEffect(() => {
    getMatchingJobSeekers();
  }, []);
  useEffect(() => {
    getJobSeekerData();
  }, [id]);
  // ----------------Download Resume---------------------
  const downloadResume = async () => {
    const resumeUrl = PUBLIC_IMAGE_FOLDER + "/" + jobSeekerData?.resumePath;
    if (jobSeekerData?.resumePath) {
      try {
        const response = await fetch(resumeUrl);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = jobSeekerData?.resumePath
          ? jobSeekerData?.resumePath
          : "document.pdf";
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    } else {
      infoNotify(Labels.noFileUploaded);
    }
  };

  const [countrylist, setcountrylist] = useState([]);

  const getCountryNameById = (countryId) => {
    const country = countrylist.find((item) => item.id == countryId);
    return country ? country.name : ""; // Fallback if not found
  };

  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCountries");
      const { success, data } = await result.data;
      setcountrylist(data);
    };
    getCountries();
  }, [jobSeekerData?.education]);

  return (
    <Grid container spacing={2}>
      <SimpleBackdrop open={backdropOpen} />

      {/* -------------------- profile details -------------------- */}
      <Grid item xs={12} md={7} lg={8}>
        <Paper elevation={0} sx={{ p: 1, my: 0 }}>
          {/* -------------------- profile -------------------- */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 2 }}
          >
            {jobSeekerData?.profilePicPath?.endsWith("/") ? null : (
              <Avatar
                src={`${jobSeekerData?.profilePicPath}`}
                sx={{ my: 1, width: 60, height: 60 }}
              />
            )}

            <Typography variant="h6" sx={{ color: "#2B4947", fontWeight: 500 }}>
              {jobSeekerData?.displayName}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "#84827D", fontWeight: 500 }}
            >
              {jobSeekerData?.designationName}
            </Typography>
            <Typography variant="body2" sx={{ color: "#84827D" }}>
              {jobSeekerData?.currentEmployer}
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            {/* ---------------- Career Information ---------------- */}
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  border: "1px solid #c3e1d9",
                  p: 2,
                  borderRadius: 2,
                  minHeight: "230px",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <WorkIcon
                    sx={{
                      mr: 1,
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "2rem",
                    }}
                  />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {Labels?.careerProfile}
                  </Typography>
                </Stack>
                <table>
                  <tbody>
                    {jobSeekerData?.designationName && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.designation}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.designationName}
                          </Typography>
                        </td>
                      </tr>
                    )}

                    {jobSeekerData?.experienceLevelName && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.ExperienceLevel}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.experienceLevelName}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td style={{ width: 150 }}>
                        <Typography variant="body2">
                          {Labels?.WorkStatus}
                        </Typography>
                      </td>
                      <td style={{ width: 25 }}>
                        <Typography variant="body2">:</Typography>
                      </td>
                      <td style={{ width: 150 }}>
                        <Typography variant="body2">
                          {jobSeekerData?.workStatus}
                        </Typography>
                      </td>
                    </tr> */}
                    {jobSeekerData?.totalExperience && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.TotalExperience}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          {jobSeekerData?.totalExperience ? (
                            <Typography variant="body2">
                              {jobSeekerData?.totalExperience} {Labels?.Years}
                            </Typography>
                          ) : null}
                        </td>
                      </tr>
                    )}

                    {jobSeekerData?.currentEmployer && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.CurrentCompany}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.currentEmployer}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Box>
            </Grid>
            {/* ---------------- Personal Information ---------------- */}
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  border: "1px solid #c3e1d9",
                  p: 2,
                  borderRadius: 2,
                  minHeight: "230px",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <AccountCircleIcon
                    sx={{ mr: 1, color: "#00000099", fontSize: 30 }}
                  />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {Labels?.personalInformation}
                  </Typography>
                </Stack>
                <table>
                  <tbody>
                    {jobSeekerData?.displayName && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.name}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.displayName}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {jobSeekerData?.dateOfBirth && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.dateofBirth}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.dateOfBirth}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {jobSeekerData?.genderName && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.Gender}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.genderName}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td style={{ width: 150 }}>
                        <Typography variant="body2">
                          {Labels?.preferredLanguage}
                        </Typography>
                      </td>
                      <td style={{ width: 25 }}>
                        <Typography variant="body2">:</Typography>
                      </td>
                      <td style={{ width: 175 }}>
                        <Typography variant="body2">
                          {jobSeekerData?.languageName}
                        </Typography>
                      </td>
                    </tr> */}
                    {jobSeekerData?.city && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.location}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                           {jobSeekerData?.city}, {jobSeekerData?.stateName}, {jobSeekerData?.countryName}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {jobSeekerData?.zip && (
                      <tr>
                        <td style={{ width: 150 }}>
                          <Typography variant="body2">
                            {Labels?.zip}
                          </Typography>
                        </td>
                        <td style={{ width: 25 }}>
                          <Typography variant="body2">:</Typography>
                        </td>
                        <td style={{ width: 175 }}>
                          <Typography variant="body2">
                            {jobSeekerData?.zip}
                          </Typography>
                        </td>
                      </tr>
                    )}

                  </tbody>
                </table>
              </Box>
            </Grid>
            {/* _________________Skills__________________________________ */}
            {jobSeekerData?.skills?.length ? (
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    border: "1px solid #c3e1d9",
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <TipsAndUpdatesIcon
                      sx={{
                        mr: 1,
                        color: "rgba(0, 0, 0, 0.6)",
                        fontSize: "2rem",
                      }}
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {Labels.skills}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">
                    {jobSeekerData?.skills?.map((val) => {
                      return (
                        <Stack
                          direction={{ xs: "column", sm: "row" }} // Responsive: stack vertically on small screens, horizontally on larger screens
                          alignItems="center"
                          spacing={2}
                          sx={{
                            margin: "4px 0px",
                            p: 1,
                            border: "1px solid #e0e0e0",
                            borderRadius: 2,
                          }}
                        >
                          {/* Skill Name */}
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              color: "#2B4947",
                              flexBasis: { xs: "100%", sm: "25%" }, // Full width on small screens, 25% width on larger screens
                              display: { xs: "none", sm: "block" },
                            }}
                          >
                            {val.name}
                          </Typography>
                          <Typography
                            sx={{
                              minWidth: "50px",
                              textAlign: "center",
                              fontWeight: 500,
                              color: `black`, // Color change based on value
                              display: { xs: "block", sm: "none" },
                            }}
                          >
                            {val.name} {val?.detail ? `- ${val?.detail}%` : ""}
                          </Typography>

                          {/* Progress Bar */}
                          <Box sx={{ flexGrow: 1, width: "100%" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={val.detail}
                              sx={{
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor:
                                    val.detail >= 50 ? "#1C614E" : "#FFD700", // Green if 50% or above, yellow if below
                                },
                              }}
                            />
                          </Box>

                          {/* Percentage Value */}
                          <Typography
                            sx={{
                              minWidth: "50px",
                              textAlign: "center",
                              fontWeight: 500,
                              color: `black`, // Color change based on value
                              display: { xs: "none", sm: "block" },
                            }}
                          >
                            {val?.detail ? `${val?.detail}%` : ""}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Typography>
                </Box>
              </Grid>
            ) : null}

          </Grid>

          <Grid container spacing={2}>
            {/* -------------------- Employment -------------------- */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box
                sx={{
                  my: 1,
                  border: "1px solid #c3e1d9",
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {Labels?.EmploymentHistory}
                </Typography>
                {jobSeekerData.employment &&
                Array.isArray(jobSeekerData.employment) &&
                jobSeekerData.employment.length === 0 ? (
                  <Typography variant="body1">
                    {Labels?.NoEmploymentHistory}
                  </Typography>
                ) : (
                  jobSeekerData.employment &&
                  Array.isArray(jobSeekerData.employment) &&
                  jobSeekerData.employment.map((data) => (
                    <List>
                      <ListItem
                        key={data?.jseh_id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 0,
                        }}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            sx={{ fontSize: 15, color: "#1C614E" }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          primary={data.designationName}
                          secondary={
                            <Stack direction={{ xs: "column", sm: "column" }}>
                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                  {data.company}
                                </Typography>
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                  {data.jobtypename}
                                </Typography>
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                  {data.startMonth}
                                </Typography>
                                <Typography variant="body2">
                                  {data.startyear}
                                </Typography>
                                <Typography variant="body2">-</Typography>
                                {data?.currentstatus ? (
                                  <Typography variant="body2">
                                    {Labels.Currentlyworking}
                                  </Typography>
                                ) : (
                                  <>
                                    <Typography variant="body2">
                                      {data.endMonth}
                                    </Typography>
                                    <Typography variant="body2">
                                      {data.endyear}
                                    </Typography>
                                  </>
                                )}
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                  {data?.responsibilities}
                                </Typography>
                              </Stack>
                            </Stack>
                          }
                        />
                      </ListItem>
                    </List>
                  ))
                )}
              </Box>
            </Grid>
            {/* -------------------- Education -------------------- */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box
                sx={{
                  my: 1,
                  border: "1px solid #c3e1d9",
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {Labels?.EducationHistory}
                </Typography>
                {jobSeekerData.employment &&
                Array.isArray(jobSeekerData.education) &&
                jobSeekerData.education.length === 0 ? (
                  <Typography variant="body2">
                    {Labels?.NoEducationHistory}
                  </Typography>
                ) : (
                  jobSeekerData.education &&
                  Array.isArray(jobSeekerData.education) &&
                  jobSeekerData.education.map((data) => (
                    <List>
                      <ListItem
                        key={data?.jseh_id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 0,
                        }}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            sx={{ fontSize: 15, color: "#1C614E" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={data.CourseName}
                          secondary={
                            <Stack direction={{ xs: "column", sm: "column" }}>
                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                  {data.institution}
                                </Typography>
                              </Stack>

                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2" sx={{ mx: 2 }}>
                                  {data.jobtypename}
                                </Typography>
                              </Stack>

                              <Stack direction="row" spacing={1}>
                                <Typography variant="body2">
                                  {data.startYear}
                                </Typography>
                                <Typography variant="body2">
                                  {Labels?.To}
                                </Typography>
                                <Typography variant="body2">
                                  {data.endYear}
                                </Typography>
                              </Stack>
                              <Stack direction="row" spacing={1}>
                                {getCountryNameById(data?.countryId)}
                              </Stack>
                            </Stack>
                          }
                        />
                      </ListItem>
                    </List>
                  ))
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={5} lg={4}>
        <Card
          sx={{
            p: 0,
            boxShadow: "none",
          }}
        >
          <Typography variant="subtitle1" sx={{ color: "#1C614E", p: 2 }}>
            {Labels?.MatchingCandidates}
          </Typography>
          <Divider />
          <Box
            sx={{ height: { md: "70vh", overflow: "auto" } }}
            className={classes.customColoredScrollbar}
          >
            {matchingJobSeekers.length > 0 ? (
              matchingJobSeekers.map((val, i) => (
                <Card
                  key={i}
                  sx={{
                    borderRadius: 1,
                    boxShadow: "none",
                    borderBottom: "1px solid #1C614E33",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#ffffffe5",
                    },
                  }}
                  onClick={() => getJobSeekerData(val?.jobseekerid)}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    p={1}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        src={
                          val.grpLogoPath === null ||
                          "" ||
                          val.grpLogoPath?.endsWith("/")
                            ? `${val.groupName.charAt(0)}`
                            : `${val.logo}`
                        }
                        alt=" "
                        style={{
                          maxWidth: 30,
                          maxHeight: 30,
                        }}
                      />
                      <Stack>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#2B4947", fontWeight: 600 }}
                        >
                          {val.displayName}
                        </Typography>
                        <Stack direction="row" alignItems="center">
                          <PublicIcon sx={{ fontSize: 17 }} />
                          <Typography variant="body2" sx={{ color: "#1C614E" }}>
                            &nbsp;{val?.designation?.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <InfoIcon />
                    </Stack>
                  </Stack>
                </Card>
              ))
            ) : (
              <Typography variant="body1" sx={{ p: 1 }}>
                {Labels?.NoshortlistedCandidates}
              </Typography>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
