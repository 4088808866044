import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const JobType = ({ style, value, setValue }) => {
  const [jobTypes, setJobType] = useState([]);
  useEffect(() => {
    const getJobType = async () => {
      const result = await axiosAPI.get("/getTypes");
      const { success, data } = await result.data;
      if (success === true) {
        setJobType(data);
      } else {
        setJobType([]);
      }
    };
    getJobType();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={value}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            <span style={{ color: "#bcbcbc" }}>{Labels?.JobType}</span>
          </MenuItem>
          {jobTypes &&
            jobTypes.map((val, index) => {
              return (
                <MenuItem key={index} value={val.id}>
                  {val.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default JobType;
