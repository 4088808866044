import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";

const State = ({ value, setValue, country,style,userchangedcountry,setuserchangedcountry }) => {
 
  const [States, setStates] = useState([]);
  const usertype = localStorage.getItem("userType");

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getStates?countryID=${country?.id}`);
      const { success, data } = await result.data;
      if (success === true) {
        setStates(data);
        if(userchangedcountry){
          setValue(null)
          setuserchangedcountry(false)
        }
      } else {
        setStates([]);
      }
    };
    getStates();
  }, [country]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={States}
        sx={{ ...style }}
        disabled={usertype === "Recruiter" ? true : false}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectState}/>
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default State;
