import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, Grid, TextField } from "@mui/material";
import Currency from "../../CommonCode/Currency";
import SalaryPeriod from "../../CommonCode/SalaryPeriod";
import { useSelector } from "react-redux";
import InputWithLabel from "../../Components/InputWithLabel";
import CloseIcon from "@mui/icons-material/Close";
import { axiosAPI } from "../../Axios/Axios";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SalaryCalculatorModal = ({
  setyearlysalary = {},
  setyearlysalary2 = {},
  yearlysalary = {},
  yearlysalary2 = {},
  Createjobads = {},
  salaryPeriod = {},
  setsalaryPeriod = {},
  currency = {},
  setCurrency = {},

  salaryValue = "",
  salaryMax = "",
  setSalaryMax = {},
  setButtonDisable = {},

  setsalarycalcuvalue = () => {},
  salarycalcuvalue = {},

  setCurrencySymbol = {},
  currencySymbol = "",
}) => {
  const [open, setOpen] = useState(false);

  const [salary, setSalary] = useState();
  const [salary2, setSalary2] = useState();
  const [salaryType, setSalaryType] = useState("");

  const [hoursPerDay, setHoursPerDay] = useState(8); // default value
  const [daysPerWeek, setDaysPerWeek] = useState(5); // default value
  const [holidays, setHolidays] = useState(10); // default value
  const [vacationDays, setVacationDays] = useState(10); // default value

  const [annualSalary, setAnnualSalary] = useState(0);
  const [annualSalary2, setAnnualSalary2] = useState(0);
  const [totalWorkingDays, setTotalWorkingDays] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);

  useEffect(() => {
    subfn();
  }, [
    salary,
    salary2,
    hoursPerDay,
    daysPerWeek,
    holidays,
    vacationDays,
    salaryType,
  ]);

  const Labels = useSelector((state) => state.allLabels.labels);

  useEffect(() => {
    setSalary(salaryValue?.replace(",", ""));
    setSalary2(salaryMax);
  }, [salaryValue, salaryMax]);

  useEffect(() => {
    if (salaryPeriod === 5) {
      setSalaryType(`month`);
    } else if (salaryPeriod === 6) {
      setSalaryType("week");
    } else if (salaryPeriod === 7) {
      setSalaryType("year");
    } else if (salaryPeriod === 8) {
      setSalaryType("day");
    }
  }, [salaryPeriod]);

  const [CurrencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    const getCurrency = async () => {
      const result = await axiosAPI.get("/getCurrency");
      const { success, data } = await result.data;
      if (success === true) {
        setCurrencyList(data);
      } else {
        setCurrencyList([]);
      }
    };
    getCurrency();
  }, []);

  useEffect(() => {
    if (CurrencyList.length && currency) {
      const matchedCurrency = CurrencyList.find(
        (item) => item.cl_id === currency
      );
      if (matchedCurrency) {
        setCurrencySymbol(matchedCurrency.currencySymbol);
      } else {
        setCurrencySymbol(""); // Fallback for no match
      }
    }
  }, [currency, CurrencyList]);

  const subfn = () => {
    let salarynew = salary?.toString()?.replace(",", "");
    let salary2new = salary2?.toString()?.replace(",", "");

    let salaryParsed = parseFloat(salarynew);
    let salary2Parsed = parseFloat(salary2new);

    // Constants
    const totalDaysInYear = 365;
    const weeksInYear = 52;

    // Calculate working days and hours based on inputs
    const workingDaysInYear =
      daysPerWeek * weeksInYear - holidays - vacationDays;
    const workingHoursInYear = workingDaysInYear * hoursPerDay;

    let salaryCalculation = 0;
    let salaryCalculationMax = 0;

    // Calculate annual salary based on salaryType
    if (salaryType === "month") {
      salaryCalculation = salaryParsed * 12;
      salaryCalculationMax = salary2Parsed * 12;
    } else if (salaryType === "week") {
      salaryCalculation = salaryParsed * weeksInYear;
      salaryCalculationMax = salary2Parsed * weeksInYear;
    } else if (salaryType === "year") {
      salaryCalculation = salaryParsed;
      salaryCalculationMax = salary2Parsed;
    } else if (salaryType === "day") {
      salaryCalculation = salaryParsed * workingDaysInYear;
      salaryCalculationMax = salary2Parsed * workingDaysInYear;
    } else if (salaryType === "hour") {
      salaryCalculation = salaryParsed * workingHoursInYear;
      salaryCalculationMax = salary2Parsed * workingHoursInYear;
    }

    // Update state
    setAnnualSalary(salaryCalculation);
    setyearlysalary(salaryCalculation);
    setAnnualSalary2(salaryCalculationMax);
    setyearlysalary2(salaryCalculationMax);
    setTotalWorkingDays(workingDaysInYear);
    setTotalWorkingHours(workingHoursInYear);

    setsalarycalcuvalue(
      JSON.stringify({
        hoursPerDay: hoursPerDay,
        daysPerWeek: daysPerWeek,
        holidays: holidays,
        vacationDays: vacationDays,
        salaryMax:salary2
      })
    );
  };

  useEffect(() => {
    if (typeof salarycalcuvalue == "string") {
      const parsedvalue = JSON.parse(salarycalcuvalue);
      setHoursPerDay(parsedvalue?.hoursPerDay ? parsedvalue?.hoursPerDay : "");
      setDaysPerWeek(parsedvalue?.daysPerWeek ? parsedvalue?.daysPerWeek : "");
      setHolidays(parsedvalue?.holidays ? parsedvalue?.holidays : "");
      setVacationDays( parsedvalue?.vacationDays ? parsedvalue?.vacationDays : "");
      
      if(!salary2){
        setSalary2(parsedvalue?.salaryMax?parsedvalue?.salaryMax:'')
      }
    }
    

    setAnnualSalary(yearlysalary);
    setAnnualSalary2(yearlysalary2);
  }, [salarycalcuvalue]);

  return (
    <Grid container spacing={2} sx={{position:"relative", bottom:"20px"}}>
      <Grid item xs={12} sm={6}>
        <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
          {Labels.Salary} (Min)
        </span>

        <TextField
          fullWidth
          name="salary"
          placeholder={`${Labels.Salary} (Min)`}
          inputProps={{
            sx: {
              height: "40px",
              padding: `0px`,
              paddingLeft: "10px",
            },
          }}
          value={
            salary
              ? salary
                  .toString()
                  .replace(/[^0-9]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : ""
          }
          type="text"
          onChange={(e) => {
            Createjobads(e);
            setButtonDisable(false);
          }}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab" &&
              e.key !== "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
          {Labels.Salary} (Max)
        </span>
        <TextField
          fullWidth
          name="salaryMax"
          placeholder={`${Labels.Salary} (Max)`}
          inputProps={{
            sx: {
              height: "40px",
              padding: `0px`,
              paddingLeft: "10px",
            },
          }}
          value={
            salary2
              ? salary2
                  .toString()
                  .replace(/[^0-9]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : ""
          }
          type="text"
          onChange={(e) => {
            setSalaryMax(e.target.value);
            setButtonDisable(false);
          }}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab" &&
              e.key !== "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Currency
          name="setCurrency"
          value={currency}
          setValue={setCurrency}
          setButtonDisable={setButtonDisable}
          style={{ width: "96%", position: "relative", left: "2px" }}
          width={`104%`}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SalaryPeriod
          style={{ width: "99%" }}
          width={`101%`}
          InputLabel={Labels.Period}
          label={Labels?.Workinghours}
          name="salaryPeriod"
          value={salaryPeriod ? salaryPeriod : ""}
          setValue={setsalaryPeriod}
          setButtonDisable={setButtonDisable}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
          {Labels.HoursperDay || "Hours Per Day"}
        </span>
        <TextField
          type="number"
          inputProps={{
            min: 0,
            max:24,
            sx: {
              height: "40px",
              padding: `0px 10px`,
            },
          }}
          value={hoursPerDay}
          onChange={(e) => setHoursPerDay(Number(e.target.value))}
          fullWidth
            onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab" &&
              e.key !== "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
          {Labels.DaysperWeek || "Days Per Week"}
        </span>
        <TextField
          type="number"
          inputProps={{
            min: 0,
            max:7,
            sx: {
              height: "40px",
              padding: `0px 10px`,
            },
          }}
          value={daysPerWeek}
          onChange={(e) => setDaysPerWeek(Number(e.target.value))}
          fullWidth
            onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab" &&
              e.key !== "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
          {Labels.HolidaysPerYear || "Holidays Per Year"	}
        </span>
        <TextField
          type="number"
          inputProps={{
            min: 0,
            max:366,
            sx: {
              height: "40px",
              padding: `0px 10px`,
            },
          }}
          value={holidays}
          onChange={(e) => setHolidays(Number(e.target.value))}
          fullWidth
            onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab" &&
              e.key !== "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
          {Labels.VacationDaysperYear || "Vacation Days per Year"}
        </span>
        <TextField
          type="number"
          inputProps={{
            min: 0,
            max:366,
            sx: {
              height: "40px",
              padding: `0px 10px`,
              
            },
          }}
          value={vacationDays}
          onChange={(e) => setVacationDays(Number(e.target.value))}
          fullWidth
            onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab" &&
              e.key !== "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Grid>

      {/* <Grid item xs={12}>
        {annualSalary || annualSalary2 ? (
          <Typography variant="body1">
            <strong>Annual Salary:</strong>{" "}
            {annualSalary?.length
              ? `${annualSalary
                  .toString()
                  .replace(/[^0-9]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currencySymbol}`
              : ""}
            {annualSalary2?.length
              ? `- ${annualSalary2
                  .toString()
                  .replace(/[^0-9]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currencySymbol}`
              : ""}
          </Typography>
        ) : (
          ""
        )}

        {totalWorkingDays ? (
          <Typography variant="body1">
            <strong>Total Working Days:</strong> {totalWorkingDays}
          </Typography>
        ) : (
          ""
        )}

        {totalWorkingHours ? (
          <Typography variant="body1">
            <strong>Total Working Hours:</strong> {totalWorkingHours}
          </Typography>
        ) : (
          ""
        )}
      </Grid> */}
    </Grid>
  );
};

export default SalaryCalculatorModal;
