import {
  Box,
  Modal,
  Dialog,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Skeleton,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

import React, { Fragment, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Designation from "../../CommonCode/Designation";
import { Button } from "@mui/joy";
import { axiosAPI } from "../../Axios/Axios";
import {
  errorNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import JobType from "../../CommonCode/JobType";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { profanityfn } from "../../Profanity/Profanity";
import DesignationProfile from "../../CommonCode/DesignationProfile";

const EmploymentModal = ({ open, handleClose, data, btnlabel }) => {
  const classes = useStyles();
  const [customtitletoggle, setcustomtitletoggle] = useState();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [designation, setDesignation] = useState("");
  const [Jobtype, setJobtype] = useState("");
  const [company, setCompany] = useState("");
  const [location, setlocation] = useState("");
  const [description, setDescription] = useState("");
  const [Title, setTitle] = useState("");
  const [empstart, setempstart] = useState("");
  const [empend, setempend] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [datacalling, setdatacalling] = useState(false);

  useEffect(() => {
    if (data) {
      setdatacalling(true);
      if (data?.titile) {
        setcustomtitletoggle(true);
      } else {
        setcustomtitletoggle(false);
      }

      const startMonth1 = data?.startMonth;
      const startYear1 = data?.startyear;
      const monthIndex1 = new Date(
        Date.parse(startMonth1 + " 1, 2012")
      ).getMonth();
      const startDate1 = new Date(startYear1, monthIndex1, 1, 0, 0, 0, 0);

      const startMonth2 = data?.endMonth;
      const startYear2 = data?.endyear;
      const monthIndex2 = new Date(
        Date.parse(startMonth2 + " 1, 2012")
      ).getMonth();
      const startDate2 = new Date(startYear2, monthIndex2, 1, 0, 0, 0, 0);

      setempstart(startDate1);
      setempend(startDate2);

      setDesignation(data.designationid);
      setJobtype(data.jobtype);
      setCurrentlyWorking(data?.currentstatus == 1 ? 1 : 0);
      setCompany(data.company);

      setDescription(data.responsibilities);
      setlocation(data.location || "");
      setTitle(data?.titile);
    }
    else{
      setdatacalling(true)
    }
  }, [data]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setCurrentlyWorking(isChecked);
    if (isChecked) {
      const currentDate = new Date();
      const currentMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(currentDate);
      const currentYear = currentDate.getFullYear().toString();
      setempend(`${currentMonth} ${currentYear}`);
    } else {
      setempend("");
    }
  };

  const submitEmploymentHistory = async (e) => {
    e.preventDefault();
    setButtonDisable(true);

    if (
      !designation ||
      !Jobtype ||
      !company ||
      !empstart ||
      (!empend && !currentlyWorking)
    ) {
      setButtonDisable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (company.length > 200) {
      setButtonDisable(false);
      return errorNotify(Labels.companyNameValidation);
    }

    const now = new Date(); // Current date
    const currentYear = now.getFullYear().toString();
    const currentMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(now);

    const startDate = new Date(empstart);
    const endDate = currentlyWorking ? now : new Date(empend);

    const startYear = startDate.getFullYear().toString();
    const endYear = currentlyWorking
      ? currentYear
      : endDate.getFullYear().toString();

    const startMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(startDate);
    const endMonth = currentlyWorking
      ? currentMonth
      : new Intl.DateTimeFormat("en-US", { month: "long" }).format(endDate);

    if (startYear > endYear && !currentlyWorking) {
      setButtonDisable(false);
      return errorNotify(Labels.startyearshouldnot);
    } else if (startYear === endYear && !currentlyWorking) {
      const startMonthIndex = startDate.getMonth();
      const endMonthIndex = endDate.getMonth();

      if (startMonthIndex > endMonthIndex) {
        setButtonDisable(false);
        return errorNotify(Labels.startmonthshouldnot);
      }
    }

    const totalYear = currentlyWorking
      ? new Date().getFullYear() - startDate.getFullYear()
      : endYear - startYear;

    const postData = {
      designationid: designation,
      jobtype: Jobtype,
      nYears: totalYear,
      company: company,
      startyear: startYear,
      endyear: endYear,
      startMonth: startMonth,
      endMonth: endMonth,
      responsibilities: description,
      titile: Title,
      currentstatus: currentlyWorking ? 1 : 0,
      location: location,
    };

    try {
      const result = await axiosAPI.post("/addEmployment", postData);
      const { success, message } = result.data;

      if (success) {
        succesNotify(Labels.employmentSuccess);
        handleClose();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      errorNotify(Labels.genericError);
    } finally {
      setButtonDisable(false);
    }
  };

  useEffect(() => {
    if (Title) {
      setcustomtitletoggle(true);
    } else {
      setcustomtitletoggle(false);
    }
  }, [Title]);

  const UpdateEmploymentHistory = async (e) => {
    e.preventDefault();
    setButtonDisable(true);

    if (
      !designation ||
      !Jobtype ||
      !company ||
      !empstart ||
      (!empend && !currentlyWorking)
    ) {
      setButtonDisable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (company.length > 200) {
      setButtonDisable(false);
      return errorNotify(Labels.companyNameValidation);
    }

    const now = new Date(); // Current date
    const currentYear = now.getFullYear().toString();
    const currentMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(now);

    const startDate = new Date(empstart);
    const endDate = currentlyWorking ? now : new Date(empend);

    const startYear = startDate.getFullYear().toString();
    const endYear = currentlyWorking
      ? currentYear
      : endDate.getFullYear().toString();

    const startMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(startDate);
    const endMonth = currentlyWorking
      ? currentMonth
      : new Intl.DateTimeFormat("en-US", { month: "long" }).format(endDate);

    if (startYear > endYear && !currentlyWorking) {
      setButtonDisable(false);
      return errorNotify(Labels.startyearshouldnot);
    } else if (startYear === endYear && !currentlyWorking) {
      const startMonthIndex = startDate.getMonth();
      const endMonthIndex = endDate.getMonth();

      if (startMonthIndex > endMonthIndex) {
        setButtonDisable(false);
        return errorNotify(Labels.startmonthshouldnot);
      }
    }

    const totalYear = currentlyWorking
      ? new Date().getFullYear() - startDate.getFullYear()
      : endYear - startYear;

    const postData = {
      employmentid: data?.jseh_id,
      language: localStorage.getItem("language"),
      designationid: designation,
      jobtype: Jobtype,
      nYears: totalYear,
      company: company,
      startyear: startYear,
      endyear: endYear,
      startMonth: startMonth,
      endMonth: endMonth,
      responsibilities: description,
      titile: Title,
      currentstatus: currentlyWorking ? 1 : 0,
      location: location,
    };

    try {
      const result = await axiosAPI.post("/updateEmployment", postData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(Labels.employmentSuccess);
        handleClose();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      errorNotify(Labels.genericError);
    } finally {
      setButtonDisable(false);
    }
  };

  const ProfanityList = useSelector((state) => state.Profanity.wordlist);
  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      PaperProps={{
        sx: {
          width: "80vw",
        },
      }}
    >
      {datacalling ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <Typography variant="h6" sx={{ color: "#1C614E" }}>
              {Labels?.EmploymentHistory}
            </Typography>
            <HighlightOffIcon
              sx={{
                color: "#1C614E",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Stack>

          <Box
            className={classes.customColoredScrollbar}
            sx={{ p: 2, height: "auto", maxHeight: "80vh", overflow: "auto" }}
          >
            {/* Designation / Title */}

            <div style={{ margin: "10px 5px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.Title}
              </Typography>

              {customtitletoggle ? (
                <TextfieldCustom
                  style={{ width: "100%" }}
                  name="titile"
                  value={Title}
                  onChange={(e) =>
                    setTitle(profanityfn(ProfanityList, e.target.value))
                  }
                  label={Labels?.Title}
                />
              ) : (
                <DesignationProfile
                  value={designation}
                  setValue={setDesignation}
                  label={Labels?.Title}
                  style={{ width: "100%" }}
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={customtitletoggle}
                    onChange={(e) => {
                      setcustomtitletoggle(e.target.checked);
                      if (e.target.checked == false) {
                        setTitle("");
                      } else {
                      }
                    }}
                  />
                }
                label={Labels.AddCustomTitle}
              />
            </div>

            {/* Title */}

            {/* Job Type */}
            <div style={{ margin: "10px 5px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.JobType}
              </Typography>
              <JobType
                value={Jobtype}
                setValue={setJobtype}
                style={{
                  width: "100%",
                }}
              />
            </div>

            {/* Company */}
            <div style={{ margin: "10px 5px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.company}
              </Typography>
              <TextfieldCustom
                style={{
                  width: "100%",
                }}
                name="company"
                value={company}
                onChange={(e) =>
                  setCompany(profanityfn(ProfanityList, e.target.value))
                }
                label={Labels?.company}
              />
            </div>

            {/* Date */}
            <div style={{ margin: "10px 5px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.From}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="MM/yyyy"
                  views={["month", "year"]}
                  maxDate={new Date()}
                  value={empstart}
                  onChange={(event) => setempstart(event)}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>
            {!currentlyWorking && (
              <div style={{ margin: "10px 5px" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {Labels?.To}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="MM/yyyy"
                    views={["month", "year"]}
                    minDate={empstart}
                    maxDate={new Date()}
                    value={empend}
                    onChange={(event) => {
                      if (!currentlyWorking) {
                        setempend(event);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                    // disabled={currentlyWorking}
                  />
                </LocalizationProvider>
              </div>
            )}

            <div style={{ margin: "10px 5px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentlyWorking}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={Labels.Currentlyworking}
              />
            </div>

            {/* Location Type */}
            <div style={{ margin: "10px 5px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.location}
              </Typography>
              <TextfieldCustom
                style={{
                  width: "100%",
                }}
                name="location"
                value={location} // You might want to adjust this if it's not the right field
                onChange={(e) =>
                  setlocation(profanityfn(ProfanityList, e.target.value))
                }
                label={Labels?.location}
              />
            </div>

            {/* Description */}
            <div style={{ margin: "10px 5px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.description}
              </Typography>
              <TextField
                placeholder={Labels.enterDescription}
                multiline
                rows={4}
                variant="outlined"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    padding: "10px",
                  },
                  "& .MuiInputLabel-root": {
                    padding: "10px",
                  },
                }}
                onChange={(e) =>
                  setDescription(profanityfn(ProfanityList, e.target.value))
                }
                value={description}
              />
            </div>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 2 }}
            >
              {btnlabel == "save" ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#1C614E",
                    color: "#FFFFFF",
                    "&:hover": { backgroundColor: "#1C614E" },
                  }}
                  onClick={submitEmploymentHistory}
                  disabled={isButtonDisable}
                >
                  {Labels?.Save}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#1C614E",
                    color: "#FFFFFF",
                    "&:hover": { backgroundColor: "#1C614E" },
                  }}
                  onClick={UpdateEmploymentHistory}
                  disabled={isButtonDisable}
                >
                  {Labels?.Update}
                </Button>
              )}
            </Stack>
          </Box>
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
          <Skeleton variant="rectangular" width={`90%`} height={60} sx={{margin:"10px auto"}}/>
        </>
      )}
    </Dialog>
  );
};

export default EmploymentModal;
