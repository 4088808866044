import { Box, Dialog, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/joy";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import Qualification from "../../CommonCode/Qualification";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { profanityfn } from "../../Profanity/Profanity";
import Country from "../../CommonCode/Country";

const EducationModal = ({ open, handleClose }) => {
    
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [educationhistory, setEducationhistory] = useState("");
  const [educationStartYear, setEducationStartYear] = useState(null);
  const [educationEndYear, setEducationEndYear] = useState(null);
  const [institution, setInstitution] = useState("");
  const [title,settitle] = useState('')
  const [countryId, setCountryId] = useState(null);

  const today = new Date();

  const submitEducationHistory = async (e) => {
    e.preventDefault();
    setButtonDisable(true);
    if (
      !educationhistory ||
      !institution ||
      !educationStartYear ||
      !educationEndYear
    ) {
      setButtonDisable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (institution.length > 200) {
      setButtonDisable(false);
      return errorNotify(Labels.institutionNameValidation);
    } else {
      setButtonDisable(false);

      const startDate = new Date(educationStartYear);
      const endDate = new Date(educationEndYear);

      const startYear = startDate.getFullYear().toString();
      const endYear = endDate.getFullYear().toString();

      const startMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(startDate);

      const endMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(endDate);

      if (startYear > endYear) {
        errorNotify(Labels.startyearshouldnot);
      } else if (startYear === endYear) {
        const startMonthtest = startDate.getMonth();
        const endMonthtest = endDate.getMonth();

        if (startMonthtest > endMonthtest) {
          errorNotify(Labels.startmonthshouldnot);
        } else {
          const postData = {
            educationID: educationhistory,
            institution: institution,
            educationTitile:title,
            startYear: startYear,
            endYear: endYear,
            startMonth: startMonth,
            endMonth: endMonth,
            countryId: countryId?.id,
            language: localStorage.getItem("language"),
          };

          const result = await axiosAPI.post("/addEducation", postData);
          const { success, message } = result.data;
          if (success === true) {
            succesNotify(message);
            handleClose();
            setButtonDisable(false);
          } else {
            errorNotify(message);
            setButtonDisable(false);
          }
        }
      } else {
        const postData = {
          educationID: educationhistory,
          institution: institution,
          educationTitile:title,
          startYear: startYear,
          endYear: endYear,
          startMonth: startMonth,
          endMonth: endMonth,
          countryId: countryId?.id,
          language: localStorage.getItem("language"),
        };

        const result = await axiosAPI.post("/addEducation", postData);
        const { success, message } = result.data;
        if (success === true) {
          succesNotify(message);
          handleClose();
          setButtonDisable(false);
        } else {
          errorNotify(message);
          setButtonDisable(false);
        }
      }
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);
  const [userchangedcountry, setuserchangedcountry] = useState(null);

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.EducationHistory}
        </Typography>
        <HighlightOffIcon
          sx={{ color: "#1C614E", cursor: "pointer" }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 2 }}>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Qualifications}
          </Typography>

          <Qualification
            value={educationhistory}
            setValue={setEducationhistory}
            style={{ width: "100%" }}
          />
        </div>
             {/* Updated Date Pickers */}
             <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{ justifyContent: "space-between" }}
        >
          <Box sx={{ mx: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.From}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/yyyy"
                views={["month", "year"]}
                maxDate={today}
                value={educationStartYear}
                onChange={(newValue) => setEducationStartYear(newValue)}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ mx: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.To}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/yyyy"
                views={["month", "year"]}
                minDate={educationStartYear}
                maxDate={today}
                value={educationEndYear}
                onChange={(newValue) => setEducationEndYear(newValue)}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
        
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Institution}
          </Typography>
          <TextfieldCustom
            style={{ width: "100%" }}
            label={Labels?.Institution}
            name="institution"
            value={institution}
            onChange={(e) =>setInstitution(profanityfn(ProfanityList, e.target.value))}
          />
        </div>

        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Title}
          </Typography>
          <TextfieldCustom
            style={{ width: "100%" }}
            label={Labels?.Title}
            name="institution"
            value={title}
            onChange={(e) =>settitle(profanityfn(ProfanityList, e.target.value))}
          />
        </div>
     

        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Country}
          </Typography>
          <Country
            value={countryId}
            setValue={setCountryId}
            userchangedcountry={userchangedcountry}
            setuserchangedcountry={setuserchangedcountry}
            style={{ width: "100%", fontSize: "14px" }}
          />
        </div>

       
      

        <Stack direction="row" justifyContent="center" sx={{ p: 2 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              "&:hover": { backgroundColor: "#1C614E" },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            disabled={isButtonDisable}
            onClick={submitEducationHistory}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default EducationModal;
