import React, { useState, useEffect, Fragment } from "react";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  Dialog,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Logout as LogoutIcon,
  Public as PublicIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
  PersonRemove as PersonRemoveIcon,
  MapsUgcOutlined as MapsUgcOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import ExitGroupModal from "./exitGroupModal";
import DeleteGroupModal from "./DeleteGroupModal";
import { useNavigate, useParams } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import {
  errorNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import debounce from "lodash/debounce";

function GroupInfo() {
  const classes = useStyles();
  const redirect = useNavigate();

  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );
  const Labels = useSelector((state) => state.allLabels.labels);

  const { id } = useParams();
  const [inputValue, setInputValue] = useState("");

  // -------------------- Group Details --------------------
  const [groupInfo, setGroupInfo] = useState({});
  const getGroupDetails = async () => {
    if (id) {
      const result = await axiosAPI.get(`/groupDetails?groupid=${id}`);
      const { status, data } = result.data;
      if (status) {
        setGroupInfo(data);
      }
    }
  };

  // -------------------- Requested Member List --------------------
  const [requestedMemberList, setRequestedMemberList] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const getRequestedMemberList = async () => {
    if (id) {
      const result = await axiosAPI.post(`/requestedMembersList?groupid=${id}`);
      const { status, data, message } = result.data;
      if (status) {
        setRequestedMemberList(data);
      } else {
        setRequestedMemberList([]);
      }
    }
  };
  // -------------------- Request Accept --------------------
  const acceptRequest = async (userid) => {
    try {
      const result = await axiosAPI.post("/acceptGroupMember", {
        groupid: id,
        userid: userid,
        type: "group",
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        // getRequestedMemberList();
        setrefresh(!refresh);
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // -------------------- Request Reject --------------------
  const rejectRequest = async (userid) => {
    try {
      const result = await axiosAPI.post("/rejectGroupMember", {
        groupid: id,
        userid: userid,
        type: "group",
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        // getRequestedMemberList();
        setrefresh(!refresh);
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // -------------------- Joined Member List --------------------
  const [groupMemberList, setGroupMemberList] = useState([]);
  
  const getGroupMemberList = async () => {
    if (id) {
      const result = await axiosAPI.post(`/joinedMembersList?groupid=${id}`);
      const { status, data } = result.data;
      if (status) {
        const groupAdmins = data.filter(
          (member) => member.groupRole === "Group Admin"
        );
        const groupOwner = data.filter(
          (member) => member.groupRole === "Group Owner"
        );
        const groupMembers = data.filter(
          (member) => member.groupRole === "Group Member"
        );
        const orderedGroupMembers = [
          ...groupAdmins,
          ...groupMembers,
          ...groupOwner,
        ];
        setGroupMemberList(orderedGroupMembers);
      }
    }
  }

  const [userRole, setUserRole] = useState("Group Member");

  useEffect(() => {
    if (groupMemberList.length) {
      groupMemberList.map((item) => {
        if (data.userid === item.userid) {
          setUserRole(item.groupRole);
        }
      });
    }
  }, [groupMemberList]);

  const [showAllParticipants, setShowAllParticipants] = useState(false);

  const toggleShowParticipants = () => {
    setShowAllParticipants((prev) => !prev);
  };

  // -------------------- Make Admin --------------------
  const makeAdmin = async (userid, groupid) => {
    try {
      const result = await axiosAPI.post("/makeAdmin", {
        userid: userid,
        type: "make",
        groupid: groupid,
        language:localStorage.getItem("language")
      });

      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        getGroupMemberList();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  // -------------------- Remove Admin --------------------
  const removeAdmin = async (userid, groupid) => {
    try {
      const result = await axiosAPI.post("/makeAdmin", {
        userid: userid,
        type: "remove",
        groupid: groupid,
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        getGroupMemberList();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  // -------------------- Remove Member --------------------
  const removeMember = async (userid, groupid) => {
    try {
      const result = await axiosAPI.post("/removeMember", {
        userid: userid,
        groupid: groupid,
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        getGroupMemberList();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // -------------------- InviteDialog --------------------
  const [dialogKey, setDialogKey] = useState(0);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const handleInviteDialogOpen = () => {
    getInvitedMembersList();
    setInviteDialogOpen(true);
  };
  const handleInviteDialogClose = () => {
    setInputValue("");
    setMembersList([]);
    setDialogKey((prevKey) => prevKey + 1);
    setInviteDialogOpen(false);
  };
  // -------------------- Invite Member --------------------
  const inviteMember = async (userid) => {
    try {
      const result = await axiosAPI.post("/inviteGroupMembers", {
        groupid: id,
        userid: userid,
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        getInvitedMembersList();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // -------------------- Get Send Invitation Member list --------------------
  const [membersList, setMembersList] = useState([]);
  const getMemberList = async (searchKeyword) => {
    const result = await axiosAPI.get(
      `/membersList?search=${searchKeyword}&type=group&groupid=${id}`
    );
    const { status, data } = result.data;
    if (status) {
      setMembersList(data);
    } else {
      setMembersList([]);
    }
  };
  // -------------------- Invited Member list --------------------
  const [invitedMembersList, setInvitedMembersList] = useState([]);
  const getInvitedMembersList = async (searchKeyword) => {
    const result = await axiosAPI.post(`/invitedMembersList`, { groupid: id });
    const { status, data } = result.data;
    if (status) {
      setInvitedMembersList(data);
    } else {
      setInvitedMembersList([]);
    }
  };
  const debouncedGetMemberList = debounce(getMemberList, 800);

  // -------------------- Cancel Invitation --------------------
  const cancelInvitation = async (userid) => {
    try {
      const result = await axiosAPI.post("/cancelInvite", {
        groupid: id,
        userid: userid,
        language:localStorage.getItem("language")
      });
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        getInvitedMembersList();
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  // -------------------- GroupInfo --------------------
  const [groupInfoOpen, setGroupInfoOpen] = useState(false);
  const handleGroupInfoOpen = () => {
    setGroupInfoOpen(true);
  };
  const handleGroupInfoClose = () => {
    setGroupInfoOpen(false);
  };

  // -------------------- Info Hover --------------------
  const [infoHover, setInfoHover] = useState(false);
  const handleInfoMouseEnter = () => {
    setInfoHover(true);
  };
  const handleInfoMouseLeave = () => {
    setInfoHover(false);
  };

  // -------------------- Edit Hover --------------------
  const [editHover, setEditHover] = useState(false);
  const handleEditMouseEnter = () => {
    setEditHover(true);
  };
  const handleEditMouseLeave = () => {
    setEditHover(false);
  };

  // -------------------- Delete Hover --------------------
  const [deleteHover, setDeleteHover] = useState(false);
  const handleDeleteMouseEnter = () => {
    setDeleteHover(true);
  };
  const handleDeleteMouseLeave = () => {
    setDeleteHover(false);
  };
  const closeDeleteGroupModal = () => {
    setDeleteModal(false);
  };

  // -------------------- Exit Group --------------------
  const [exitGroupModal, setExitGroupModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);

  const closeExitModal = () => {
    setExitGroupModal(false);
  };

  useEffect(() => {
    getRequestedMemberList();
    getGroupDetails();
    getGroupMemberList();
    getInvitedMembersList();
  }, [refresh]);

  return (
    <>
      <Paper elevation={0} sx={{ p: 2, my: 1, overflow: "auto" }}>
        {/* -------------------- Group info -------------------- */}
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Stack direction="row" justifyContent="center">
              <Avatar
                src={
                  groupInfo?.grpLogoPath ? `${groupInfo?.grpLogoPath}` : null
                }
                sx={{
                  width: "100px",
                  height: "100px",
                }}
              />
            </Stack>
            <Box
              sx={{
                margin: "10px 0",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#2B4947", fontWeight: 600 }}
              >
                {groupInfo?.groupName}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <PublicIcon sx={{ fontSize: 17 }} />
                <Typography variant="body2" sx={{ color: "#1C614E" }}>
                  {groupInfo?.categoryName}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Stack
              direction="column"
              alignItems="end"
              spacing={1}
              sx={{ float: "right", width: 50 }}
            >
              <Box
                sx={{
                  border: infoHover
                    ? "1px solid #0288d1"
                    : "1px solid #00000040",
                  borderRadius: infoHover ? 10 : "100%",
                  display: "flex",
                  p: 0.25,
                  justifyContent: "end",
                  alignItems: "center",
                  cursor: "pointer",
                  background: infoHover ? "#0288d1" : "transparent",
                  color: infoHover ? "#fff" : "#000",
                }}
                onMouseEnter={handleInfoMouseEnter}
                onMouseLeave={handleInfoMouseLeave}
              >
                {infoHover ? (
                  <Typography variant="body2" sx={{ p: "0 5px" }}>
                    {Labels?.info}
                  </Typography>
                ) : null}
                <InfoIcon
                  onClick={handleGroupInfoOpen}
                  sx={{
                    width: 28,
                    height: 28,
                    color: infoHover ? "#fff" : "#0288d1",
                  }}
                />
              </Box>
              {groupMemberList.length > 0
                ? groupMemberList.map((item, index) =>
                    data?.userid === item.userid ? (
                      item.groupRole === "Group Admin" ? (
                        <Fragment key={index}>
                          <Box
                            sx={{
                              border: editHover
                                ? "1px solid #2e7d32"
                                : "1px solid #00000040",
                              borderRadius: editHover ? 10 : "100%",
                              display: "flex",
                              p: 0.5,
                              justifyContent: "end",
                              alignItems: "center",
                              cursor: "pointer",
                              background: editHover ? "#2e7d32" : "transparent",
                              color: editHover ? "#fff" : "#000",
                            }}
                            onMouseEnter={handleEditMouseEnter}
                            onMouseLeave={handleEditMouseLeave}
                          >
                            {editHover && (
                              <Typography variant="body2" sx={{ p: "0 5px" }}>
                                {Labels?.edit}
                              </Typography>
                            )}
                            <EditIcon
                              onClick={() => redirect(`/editGroup/${id}`)}
                              sx={{
                                width: 25,
                                height: 25,
                                color: editHover ? "#fff" : "#2e7d32",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              border: deleteHover
                                ? "1px solid #d32f2f"
                                : "1px solid #00000040",
                              borderRadius: deleteHover ? 10 : "100%",
                              display: "flex",
                              p: 0.5,
                              justifyContent: "end",
                              alignItems: "center",
                              cursor: "pointer",
                              background: deleteHover
                                ? "#d32f2f"
                                : "transparent",
                              color: deleteHover ? "#fff" : "#000",
                            }}
                            onMouseEnter={handleDeleteMouseEnter}
                            onMouseLeave={handleDeleteMouseLeave}
                          >
                            {deleteHover && (
                              <Typography variant="body2" sx={{ p: "0 5px" }}>
                                {Labels?.delete}
                              </Typography>
                            )}
                            <DeleteIcon
                              onClick={() => setDeleteModal(true)}
                              sx={{
                                width: 28,
                                height: 28,
                                color: deleteHover ? "#fff" : "#d32f2f",
                              }}
                            />
                          </Box>
                        </Fragment>
                      ) : null
                    ) : null
                  )
                : null}
              {groupInfo.ownerid === data?.userid ? (
                <Fragment>
                  <Box
                    sx={{
                      border: editHover
                        ? "1px solid #2e7d32"
                        : "1px solid #00000040",
                      borderRadius: editHover ? 10 : "100%",
                      display: "flex",
                      p: 0.5,
                      justifyContent: "end",
                      alignItems: "center",
                      cursor: "pointer",
                      background: editHover ? "#2e7d32" : "transparent",
                      color: editHover ? "#fff" : "#000",
                    }}
                    onMouseEnter={handleEditMouseEnter}
                    onMouseLeave={handleEditMouseLeave}
                  >
                    {editHover && (
                      <Typography variant="body2" sx={{ p: "0 5px" }}>
                        {Labels?.edit}
                      </Typography>
                    )}
                    <EditIcon
                      onClick={() => redirect(`/editGroup/${id}`)}
                      sx={{
                        width: 25,
                        height: 25,
                        color: editHover ? "#fff" : "#2e7d32",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: deleteHover
                        ? "1px solid #d32f2f"
                        : "1px solid #00000040",
                      borderRadius: deleteHover ? 10 : "100%",
                      display: "flex",
                      p: 0.5,
                      justifyContent: "end",
                      alignItems: "center",
                      cursor: "pointer",
                      background: deleteHover ? "#d32f2f" : "transparent",
                      color: deleteHover ? "#fff" : "#000",
                    }}
                    onMouseEnter={handleDeleteMouseEnter}
                    onMouseLeave={handleDeleteMouseLeave}
                  >
                    {deleteHover && (
                      <Typography variant="body2" sx={{ p: "0 5px" }}>
                        {Labels?.delete}
                      </Typography>
                    )}
                    <DeleteIcon
                      onClick={() => setDeleteModal(true)}
                      sx={{
                        width: 28,
                        height: 28,
                        color: deleteHover ? "#fff" : "#d32f2f",
                      }}
                    />
                  </Box>
                </Fragment>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
        {/* -------------------- Invitation button -------------------- */}
        <Stack sx={{ my: 1 }}>
          {groupMemberList.length > 0 &&
            groupMemberList.map((item, i) => {
              if (
                data?.userid === item.userid &&
                (item.groupRole === "Group Admin" ||
                  item.groupRole === "Group Owner")
              ) {
                return (
                  <React.Fragment key={i}>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#fff",
                        color: "#000",
                        boxShadow: "none",
                        padding: "5px 10px",
                        justifyContent: "center",
                        border: "1px solid #00000040",
                        textTransform: "capitalize",
                        my: 0.5,
                        "&:hover": {
                          background: "#fff",
                          color: "#000",
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": { color:"white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      startIcon={<MapsUgcOutlinedIcon />}
                      onClick={handleInviteDialogOpen}
                    >
                      {Labels?.invite}
                    </Button>
                  </React.Fragment>
                );
              }
              return null;
            })}
          {groupInfo.ownerid !== data?.userid ? (
            <Button
              variant="contained"
              sx={{
                background: "#fff",
                color: "#d32f2f",
                boxShadow: "none",
                padding: "5px 10px",
                justifyContent: "center",
                border: "1px solid #d32f2f40",
                textTransform: "capitalize",
                my: 0.5,
                "&:hover": {
                  background: "#fff",
                  color: "#d32f2f",
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: "#1C614E",
                },
                "&:disabled": { color:"white",
                  backgroundColor: "#1C614E",
                },
              }}
              startIcon={<LogoutIcon />}
              onClick={() => setExitGroupModal(true)}
            >
              {Labels?.exit}
            </Button>
          ) : null}
        </Stack>
        <Box
          sx={{
            overflow: "auto",
            height: "56vh",
            border: "1px solid #E2DADA",
            borderRadius: 1,
          }}
          className={classes.customColoredScrollbar}
        >
          {/* -------------------- Memeber list -------------------- */}
          <Accordion elevation={0} disableGutters={true} defaultExpanded={true}>
            <AccordionSummary
              disableGutters={true}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {Labels?.memberList} ({groupMemberList.length})
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{ p: 0 }}>
              {/* -------------------- Make Admin -------------------- */}
              {groupMemberList.length > 0 &&
                groupMemberList.map((item) => {
                  if (data.userid === item.userid) {
                    return (
                      <Stack
                        key={item.userid}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{ borderBottom: "1px solid #0000001A", p: 1 }}
                      >
                        {[
                          "Group Owner",
                          "Group Admin",
                          "Group Member",
                        ].includes(item.groupRole) && (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Avatar
                                src={`${item.logo}`}
                                sx={{ width: 30, height: 30 }}
                              />
                              <Box>
                                <Typography variant="subtitle1">
                                  {`${item.Name} (You)`}
                                </Typography>
                                {["Group Admin", "Group Owner"].includes(
                                  item.groupRole
                                ) && (
                                  <Stack direction="row" spacing={0.5}>
                                    <StarIcon
                                      sx={{ fontSize: 16, color: "#2B4947" }}
                                    />
                                    <Typography
                                      variant="body2"
                                      sx={{ alignItems: "center" }}
                                    >
                                      {item.groupRole.slice(6)}
                                    </Typography>
                                  </Stack>
                                )}
                              </Box>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    );
                  }
                  return null;
                })}

              {/* Render the rest of the members */}
              {groupMemberList.length > 0 ? (
                showAllParticipants ? (
                  groupMemberList.map((item) => {
                    if (data?.userid !== item.userid) {
                      return (
                        <Stack
                          key={item.userid}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={1}
                          sx={{ borderBottom: "1px solid #0000001A", p: 1 }}
                        >
                          {[
                            "Group Owner",
                            "Group Admin",
                            "Group Member",
                          ].includes(item.groupRole) && (
                            <>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <Avatar
                                  src={`${item.logo}`}
                                  sx={{ width: 30, height: 30 }}
                                />
                                <Box>
                                  <Typography variant="subtitle1">
                                    {item.Name}
                                  </Typography>
                                  {["Group Admin", "Group Owner"].includes(
                                    item.groupRole
                                  ) && (
                                    <Stack direction="row" spacing={0.5}>
                                      <StarIcon
                                        sx={{ fontSize: 16, color: "#2B4947" }}
                                      />
                                      <Typography
                                        variant="body2"
                                        sx={{ alignItems: "center" }}
                                      >
                                        {item.groupRole.slice(6)}
                                      </Typography>
                                    </Stack>
                                  )}
                                </Box>
                              </Stack>

                              {data?.userid !== item.userid ? (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {(userRole == "Group Owner" ||
                                    userRole == "Group Admin") &&
                                    item.groupRole == "Group Admin" && (
                                      <StarOutlineIcon
                                        sx={{ fontSize: 23 }}
                                        color="error"
                                        onClick={() =>
                                          removeAdmin(item.userid, item.groupid)
                                        }
                                      />
                                    )}

                                  {(userRole == "Group Owner" ||
                                    userRole == "Group Admin") &&
                                    item.groupRole == "Group Member" && (
                                      <StarOutlineIcon
                                        color="success"
                                        sx={{ fontSize: 23 }}
                                        onClick={() =>
                                          makeAdmin(item.userid, item.groupid)
                                        }
                                      />
                                    )}

                                  {(userRole == "Group Owner" ||
                                    userRole == "Group Admin") &&
                                    item.groupRole !== "Group Owner" && (
                                      <PersonRemoveIcon
                                        sx={{ fontSize: 23 }}
                                        color="error"
                                        onClick={() =>
                                          removeMember(
                                            item.userid,
                                            item.groupid
                                          )
                                        }
                                      />
                                    )}
                                </Stack>
                              ) : null}
                            </>
                          )}
                        </Stack>
                      );
                    }
                    return null;
                  })
                ) : (
                  groupMemberList
                    .map((item) => {
                      if (data?.userid !== item.userid) {
                        return (
                          <Stack
                            key={item.userid}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{ borderBottom: "1px solid #0000001A", p: 1 }}
                          >
                            {[
                              "Group Owner",
                              "Group Admin",
                              "Group Member",
                            ].includes(item.groupRole) && (
                              <>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Avatar
                                    src={`${item.logo}`}
                                    sx={{ width: 30, height: 30 }}
                                  />
                                  <Box>
                                    <Typography variant="subtitle1">
                                      {item.Name}
                                    </Typography>
                                    {["Group Admin", "Group Owner"].includes(
                                      item.groupRole
                                    ) && (
                                      <Stack direction="row" spacing={0.5}>
                                        <StarIcon
                                          sx={{
                                            fontSize: 16,
                                            color: "#2B4947",
                                          }}
                                        />
                                        <Typography
                                          variant="body2"
                                          sx={{ alignItems: "center" }}
                                        >
                                          {item.groupRole.slice(6)}
                                        </Typography>
                                      </Stack>
                                    )}
                                  </Box>
                                </Stack>

                                {data?.userid !== item.userid ? (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {(userRole == "Group Owner" ||
                                      userRole == "Group Admin") &&
                                      item.groupRole == "Group Admin" && (
                                        <StarOutlineIcon
                                          sx={{ fontSize: 23 }}
                                          color="error"
                                          onClick={() =>
                                            removeAdmin(
                                              item.userid,
                                              item.groupid
                                            )
                                          }
                                        />
                                      )}

                                    {(userRole == "Group Owner" ||
                                      userRole == "Group Admin") &&
                                      item.groupRole == "Group Member" && (
                                        <StarOutlineIcon
                                          color="success"
                                          sx={{ fontSize: 23 }}
                                          onClick={() =>
                                            makeAdmin(item.userid, item.groupid)
                                          }
                                        />
                                      )}

                                    {(userRole == "Group Owner" ||
                                      userRole == "Group Admin") &&
                                      item.groupRole !== "Group Owner" && (
                                        <PersonRemoveIcon
                                          sx={{ fontSize: 23 }}
                                          color="error"
                                          onClick={() =>
                                            removeMember(
                                              item.userid,
                                              item.groupid
                                            )
                                          }
                                        />
                                      )}
                                  </Stack>
                                ) : null}
                              </>
                            )}
                          </Stack>
                        );
                      }
                      return null;
                    })
                    .slice(0, 4)
                )
              ) : (
                <Typography variant="subtitle1" sx={{ px: 2, py: 1 }}>
                  {Labels?.noMembers}
                </Typography>
              )}

              {groupMemberList.length > 5 && (
                <Button
                  variant="text"
                  onClick={toggleShowParticipants}
                  fullWidth={true}
                  endIcon={
                    showAllParticipants ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                >
                  {showAllParticipants ? "Show Less" : "Show More"}
                </Button>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider />

          {/* -------------------- request Member list -------------------- */}
          <>
            {groupMemberList.length > 0 &&
              groupMemberList.map((item, i) => {
                if (
                  data?.userid === item.userid &&
                  (item.groupRole === "Group Admin" ||
                    item.groupRole === "Group Owner")
                ) {
                  return (
                    <Accordion elevation={0} disableGutters={true}>
                      <AccordionSummary
                        disableGutters={true}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          {Labels?.request} ({requestedMemberList.length})
                        </Typography>
                      </AccordionSummary>
                      <Divider />

                      <AccordionDetails sx={{ p: 0 }}>
                        {requestedMemberList.length > 0 ? (
                          requestedMemberList.map((item, i) => (
                            <Stack
                              key={i}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              spacing={1}
                              sx={{ borderBottom: "1px solid #0000001A", p: 1 }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <Avatar
                                  src={`${item.logo}`}
                                  sx={{ width: 30, height: 30 }}
                                />

                                <Typography variant="subtitle1">
                                  {item.Name}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "#fff",
                                    background: "#1C614E",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      color: "#fff",
                                      background: "#1C614E",
                                    },
                                    "&:active": {
                                      backgroundColor: "#1C614E",
                                    },
                                    "&:disabled": { color:"white",
                                      backgroundColor: "#1C614E",
                                    },
                                  }}
                                  onClick={() => acceptRequest(item.userid)}
                                >
                                  {Labels?.accept}
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "#000",
                                    background: "#E2DADA",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      color: "#000",
                                      background: "#E2DADA",
                                    },
                                    "&:active": {
                                      backgroundColor: "#1C614E",
                                    },
                                    "&:disabled": { color:"white",
                                      backgroundColor: "#1C614E",
                                    },
                                  }}
                                  onClick={() => rejectRequest(item.userid)}
                                >
                                  {Labels?.reject}
                                </Button>
                              </Stack>
                            </Stack>
                          ))
                        ) : (
                          <Typography variant="subtitle1" sx={{ px: 2, py: 1 }}>
                            {Labels?.noRequest}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
                return null;
              })}
            <Divider />
          </>
          {/* -------------------- invited Memeber list -------------------- */}
          <>
            {groupMemberList.length > 0 &&
              groupMemberList.map((item, i) => {
                if (
                  data?.userid === item.userid &&
                  (item.groupRole === "Group Admin" ||
                    item.groupRole === "Group Owner")
                ) {
                  return (
                    <Accordion elevation={0} disableGutters={true}>
                      <AccordionSummary
                        disableGutters={true}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          {Labels?.invited} {Labels?.memberList} (
                          {invitedMembersList.length})
                        </Typography>
                      </AccordionSummary>
                      <Divider />

                      <AccordionDetails sx={{ p: 0 }}>
                        {invitedMembersList.length > 0 ? (
                          invitedMembersList.map((item, i) => (
                            <Stack
                              key={item.userid}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              spacing={1}
                              sx={{ borderBottom: "1px solid #0000001A", p: 1 }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <Avatar
                                  src={`${item.logo}`}
                                  sx={{ width: 30, height: 30 }}
                                />

                                <Typography variant="subtitle1">
                                  {item.Name}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "#000",
                                    background: "#E2DADA",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      color: "#000",
                                      background: "#E2DADA",
                                    },
                                    "&:active": {
                                      backgroundColor: "#1C614E",
                                    },
                                    "&:disabled": { color:"white",
                                      backgroundColor: "#1C614E",
                                    },
                                  }}
                                  onClick={() => cancelInvitation(item.userid)}
                                >
                                  {Labels?.Cancel}
                                </Button>
                              </Stack>
                            </Stack>
                          ))
                        ) : (
                          <Typography variant="subtitle1" sx={{ px: 2, py: 1 }}>
                            {Labels?.noRequest}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
                return null;
              })}
            <Divider />
          </>
        </Box>
      </Paper>
      <>
        {/* -------------------- GroupInfoDialog -------------------- */}
        <Dialog open={groupInfoOpen}>
          <Box
            sx={{
              maxHeight: "60vh",
              minWidth: 500,
              p: 2,

              overflow: "auto",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{Labels?.aboutGroup}</Typography>
              <CancelIcon
                onClick={handleGroupInfoClose}
                sx={{ color: "#1C614E", cursor: "pointer" }}
              />
            </Stack>

            <Typography variant="body2" sx={{ py: 1 }}>
              {groupInfo?.description}
              {/* Description */}
            </Typography>
            {/* <Typography variant="subtitle1">No Request</Typography> */}
          </Box>
        </Dialog>

        {/* -------------------- InviteDialog -------------------- */}
        <Dialog key={dialogKey} open={inviteDialogOpen}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <Typography variant="h6">{Labels?.invite}</Typography>
            <CancelIcon
              onClick={handleInviteDialogClose}
              sx={{ color: "#1C614E", cursor: "pointer" }}
            />
          </Stack>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <OutlinedInput
              placeholder={Labels?.searchHere}
              value={inputValue}
              onChange={(e) => {
                const newValue = e.target.value;
                setInputValue(newValue);
                debouncedGetMemberList(newValue.trim());
              }}
            />
          </FormControl>
          <Box sx={{ m: 1, border: "1px solid #0000001A", borderRadius: 2 }}>
            {membersList.length > 0 ? (
              membersList.map((item, i) => (
                <Stack
                  key={i}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    minWidth: 400,
                    p: 1,
                    borderBottom: "1px solid #0000001A",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      src={`${item.logo}`}
                      sx={{ width: 30, height: 30 }}
                    />
                    <Box>
                      <Typography variant="subtitle1" sx={{ lineHeight: 1 }}>
                        {item.name}
                      </Typography>
                      <Typography variant="caption">{item.role}</Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        background: "#1C614E",
                        cursor: "pointer",
                        boxShadow: "none",
                        textTransform: "capitalize",
                        "&:hover": {
                          color: "#fff",
                          background: "#1C614E",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": { color:"white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      onClick={() => inviteMember(item.userid)}
                    >
                      {Labels?.invite}
                    </Button>
                  </Stack>
                </Stack>
              ))
            ) : inputValue.trim() !== "" ? ( // Display message only if input field has value
              <Box sx={{ width: 400 }}>
                <Typography variant="subtitle1">
                  {Labels?.noMembersFound}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Dialog>
        <DeleteGroupModal
          open={openDeleteModal}
          handleClose={closeDeleteGroupModal}
          groupId={id}
        />
        <ExitGroupModal
          open={exitGroupModal}
          handleClose={closeExitModal}
          exitId={id}
        />
      </>
    </>
  );
}

export default GroupInfo;
