import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Fade,
  Paper,
  Popper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
} from "@mui/material";
import { Message } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import TextfieldCustom from "../../Components/TextfieldCustom";
import fifyPluslogo from "../../../Images/fifyPluslogo.png";
import figmalogo from "../../../Images/figmalogo.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LanguageIcon from "@mui/icons-material/Language";
import ClearIcon from "@mui/icons-material/Clear";

import CustomButton from "../../Components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import EmployerDrawer from "./employerDrawer";
import { NavLink, useNavigate } from "react-router-dom";
import { getAllLabels } from "../../../redux/AllLabels";

export default function EmployerNavbar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const redirect = useNavigate();
  const [employerLanguage, setEmployerLanguage] = useState("");

  const language =
    localStorage.getItem("language") != null
      ? localStorage.getItem("language")
      : "en";
  const Language = useSelector((state) => state.getAllLanguage.languages);

  const getLabels = () => {
    dispatch(getAllLabels(language));
  };
  useEffect(() => {
    getLabels();
    getNotifications();
    Language.map((lan) => {
      if (lan.languagecode === language) {
        setEmployerLanguage(lan?.language);
      }
    });
  }, []);

  // ----------Notification settings-------------------

  const [anchorElNotification, setAnchorElNotification] = useState(null);

  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  // --------------get notifications---------------------
  const [notifications, setNotifications] = useState([]);
  const [companyProfilee, setcompanyProfilee] = useState({});

  // -------------------- Employer Data --------------------
  useEffect(() => {
    axiosAPI
      .post("/getCompanyProfile")
      .then((res) => {
        setcompanyProfilee(res.data.data);
      })
      .catch((err) => {
        //// console.log(err);
      });
  }, []);
  const getNotifications = async () => {
    const result = await axiosAPI.get("/getNotificationsList");
    const { status, data } = result.data;
    if (status) {
      const activeNotifications = data.filter(
        (notification) => notification.status == 0
      );
      setNotifications(activeNotifications);
    } else {
      setNotifications([]);
    }
  };

  const clearnotificationfn = async (id) => {
    const result = await axiosAPI.post("/readNotification", { id: id });
    const { status, data } = result.data;
    getNotifications();
  };

  const notificationRedirect = (data) => {
    if (data.type === "job") {
      localStorage.setItem("jobId", data?.typeID);
      redirect(`/jobInfo/${data?.typeID}`);
    } else if (data.type === "group") {
      redirect(`/group-detail/${data?.typeID}`);
    }
    setAnchorElNotification(false);
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  // -------------- get Company Details---------------------
  const [getCompanyDetails, setCompanyDetails] = useState();

  const redirectToNotification = () => {
    redirect("/notification");
    setAnchorElNotification(false);
  };
  const goToHome = () => {
    redirect("/landingpage");
  };
  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white", px: 5 }}>
      <Toolbar disableGutters>
        <Stack
          sx={{
            width: "100%",
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              cursor: "pointer",
            }}
            alt=" "
            src={fifyPluslogo}
            onClick={goToHome}
          />
          {isMobile ? (
            <EmployerDrawer />
          ) : (
            <Stack direction="row" spacing={3} alignItems="center">
              <Stack direction="row" alignItems="center">
                <LanguageIcon sx={{ color: "#1C614E", fontSize: "2rem" }} />
                <Typography sx={{ color: "#2B4947" }}>
                  {employerLanguage}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <NavLink to="/chats" className="navLink">
                  <Badge
                    badgeContent={companyProfilee?.ChatmessageCount? companyProfilee?.ChatmessageCount: "0"}
                    color="error"
                  >
                    <ChatOutlinedIcon
                      sx={{
                        color: "#1C614E",
                        margin: "0 5px",
                        fontSize: "2rem",
                      }}
                    />
                  </Badge>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                
                sx={{ cursor: "pointer"}}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon
                  onClick={handleClickNotification}
                    sx={{ color: "#1C614E", fontSize: "2rem"}}
                  />
                </Badge>
              </Stack>
            </Stack>
          )}

          <Menu
            anchorEl={anchorElNotification}
            open={Boolean(anchorElNotification)}
            onClose={handleCloseNotification}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {notifications.length === 0 ? (
              <Paper
                sx={{
                  padding: "5px 15px",
                  boxShadow: "none",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Adjusted here
                  mb: 1,
                  cursor: "pointer",
                  width:'200px',
                }}
                >
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ fontSize: "16px" }}>
                    {Labels.NotificationsEmpty}
                  </Typography>
                </Stack>
              </Paper>
            ) : (
              notifications.slice(0, 5).map((data, index) => (
                <>
                  <Paper
                    key={index}
                    sx={{
                      padding: "15px",
                      boxShadow: "none",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Adjusted here
                      mb: 1,
                      cursor: "pointer",
                      
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between" // Changed to space-between for better spacing
                      sx={{ width: "100%" }} // Ensures Stack takes full width of Paper
                    >
                      <Avatar sx={{ mr: 2 }} src={`${data.logo}`} />
                      <Typography
                        sx={{ fontSize: "16px", flexGrow: 1 }} // Added flexGrow to take available space
                        onClick={() => notificationRedirect(data)}
                      >
                        {data?.message}
                      </Typography>
                      <ClearIcon
                        onClick={() => {
                          clearnotificationfn(data.id);
                        }}
                      />
                    </Stack>
                  </Paper>
                  <Divider />
                </>
              ))
            )}
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
