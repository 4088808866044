import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import InputWithLabel from "../../Components/InputWithLabel";
import CustomButton from "../../Components/CustomButton";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import {
  errorNotify,
  succesNotify,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { useNavigate } from "react-router-dom";
import Topics from "../../CommonCode/Topics";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";

export default function CreateForm() {
  const [isApiCalled, setApiCalled] = useState(0);
  const redirect = useNavigate();
  const [image, setImage] = useState("");
  const [forumName, setForumName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const allowedTypes = ["image/jpeg", "image/png", "image/JPG"];
  const maxFileSize = 3145728;
  const [forumData, setForumData] = useState({});
  const [refreshState, setRefreshState] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);

  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );
  const Labels = useSelector((state) => state.allLabels.labels);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]; // Get the first accepted file
      if (allowedTypes.includes(file.type)) {
        // Check if file type is allowed
        setUploadedFile(file); // Set the uploaded file object
        setImage(URL.createObjectURL(file)); // Set the image URL
      } else {
        // Notify user of invalid file type
        errorNotify(Labels.invaildFile);
      }
    },
    [allowedTypes]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [btndisable, setbtndisable] = useState(false);

  const submitFormprofanity = (e) => {
    const data = {
      name: forumName,
      title: title,
      description: description,
    };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitForm(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!image) {
      errorNotify(Labels.uploadImage);
      return;
      // }
      // else if (!allowedTypes.includes(image[0].type)) {
      //   errorNotify(Labels.invaildFile);
    } else if (image[0].size > maxFileSize) {
      errorNotify(Labels.imageValidation);
      return;
    } else if (!forumName) {
      errorNotify(Labels.forumNameValidation);
      return;
    } else if (!title) {
      errorNotify(Labels.enterTitle);
      return;
    } else if (!description) {
      errorNotify(Labels.enterDescription);
      return;
    } else if (description.length > 1000) {
      errorNotify(Labels.descriptionValidation);
      return;
    } else {
      setbtndisable(true);
      const postData = new FormData();
      postData.append("logo", uploadedFile);
      postData.append("name", forumName);
      postData.append("title", title);
      postData.append("description", description);
      postData.append("language", localStorage.getItem("language"));
      try {
        const result = await axiosupload.post("/createForum", postData, tokens);

        const { status, message, data } = result.data;
        if (status) {
          succesNotify(message);
          setOpenPostDialog(true);
          setForumData(data);
          // redirect("/myForum");
          setbtndisable(false);
        } else {
          errorNotify(message);
          setbtndisable(false);
        }
      } catch (error) {
        //// console.log(error.message);
        setbtndisable(false);
      }
    }
  };

  const [postId, setPostId] = useState("");
  const [openPostDialog, setOpenPostDialog] = useState(false);

  // -------------------- Add post --------------------

  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState("");

  const AddPostprofanity = (e) => {
    const data = {
      message: content,
    };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      addPost(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const addPost = async (e) => {
    setbtndisable(true);
    e.preventDefault();
    try {
      if (content || attachment) {
        const data = new FormData();
        data.append("attachment", attachment);
        data.append("message", content);
        data.append("forumid", forumData.id);
        data.append("type", "forum");
        data.append("language", localStorage.getItem("language"));

        const result = await axiosupload.post("/addPost", data, tokens);
        const { status, message } = result.data;

        if (status) {
          succesNotify(message);
          setApiCalled(Math.random());
          setContent("");
          setbtndisable(false);
          redirect("/myForum");
        } else {
          errorNotify(message);
          setbtndisable(false);
        }
      } else {
        errorNotify(Labels.enterMessage);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const [attachmentPreview, setAttachmentPreview] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setAttachment(selectedFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      setAttachmentPreview(e.target.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const removeAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
  };

  // -----------------updatePost-------------------
  const [updatePostMessage, setUpdatePostMessage] = useState("");
  const updatePost = (message, id) => {
    setUpdatePostMessage(message);
    setPostId(id);
  };

  const UpdatePostprofanity = (e) => {
    const data = {
      message: content,
    };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updatePostMessageAPI(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const updatePostMessageAPI = async () => {
    try {
      const postData = {
        fmh_id: postId,
        type: "forum",
        message: content,
        language: localStorage.getItem("language"),
      };
      const result = await axiosAPI.post("/editPost", postData);
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        setApiCalled(Math.random());
        setContent("");
        setUpdatePostMessage("");
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  return (
    <Paper elevation={0} sx={{ my: 1 }}>
      <Typography variant="h6" sx={{ color: "#2B4947", p: 2 }}>
        {Labels?.CreateForum}
      </Typography>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1">{Labels?.Logo} </Typography>

        <div>
          <div {...getRootProps()} className="dropzone" style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>{Labels.dragndrop}</p>
          </div>
          {image && (
            <div>
              <h4>{Labels.UploadedImage}:</h4>
              <img src={image} alt="Uploaded" width="200px" />
            </div>
          )}
        </div>

        <InputWithLabel
          InputLabel={Labels?.name}
          label={Labels?.name}
          style={{ width: "100%" }}
          value={forumName}
          onChange={(event) =>
            setForumName(profanityfn(ProfanityList, event.target.value))
          }
        />
        <div style={{ margin: "10px 5px" }}>
          <label style={{ color: "#413E3E", width: 230 }}>
            {Labels?.topic}
          </label>
          <Topics
            value={title}
            label={Labels?.Topics}
            setValue={setTitle}
            style={{
              width: "100%",
              fontSize: "14px",
            }}
          />
        </div>
        <InputWithLabel
          InputLabel={Labels?.description}
          label={Labels?.description}
          multiline
          rows={4}
          style={{ width: "100%" }}
          value={description}
          onChange={(event) =>
            setDescription(profanityfn(ProfanityList, event.target.value))
          }
        />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomButton
            label={Labels?.CreateForum}
            disabled={btndisable}
            style={{ padding: "5px 25px" }}
            onClick={(e) => submitFormprofanity(e)}
          />
        </div>
      </Box>
      <Dialog open={openPostDialog}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ color: "#2B4947" }}>
              {Labels?.addPost}
            </Typography>
            <HighlightOffIcon
              sx={{
                cursor: "pointer",
                color: "#1C614E",
              }}
              onClick={() => {
                setOpenPostDialog(false);
                redirect("/myForum");
              }}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              background: "#fff",
              position: "sticky",
              bottom: 0,
              px: 2,
            }}
          >
            {attachmentPreview ? (
              <>
                <img
                  src={attachmentPreview}
                  alt=""
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                  }}
                />
                <CloseOutlinedIcon onClick={removeAttachment} />
              </>
            ) : null}
            <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
              <Avatar
                src={`${data?.profilePicPath}`}
                sx={{
                  width: 35,
                  height: 35,
                }}
              />
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <OutlinedInput
                  placeholder={Labels?.Typehere}
                  onChange={(e) =>
                    setContent(profanityfn(ProfanityList, e.target.value))
                  }
                  value={content || updatePostMessage}
                  endAdornment={
                    <InputAdornment position="start">
                      {/* Use label to trigger the file input */}
                      <label htmlFor="attachment-input">
                        <IconButton component="span">
                          <CameraAltIcon />
                        </IconButton>
                      </label>
                      <input
                        type="file"
                        id="attachment-input"
                        hidden
                        onChange={handleFileChange}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {updatePostMessage ? (
                <Button
                  sx={{
                    background: "#1C614E",
                    "&:hover": {
                      background: "#1C614E",
                    },
                    "&:active": {
                      backgroundColor: "#1C614E",
                    },
                    "&:disabled": {
                      color: "white",
                      backgroundColor: "#1C614E",
                    },
                  }}
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={UpdatePostprofanity}
                >
                  {Labels?.Update}
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#1C614E",
                    "&:hover": {
                      background: "#1C614E",
                    },
                    "&:active": {
                      backgroundColor: "#1C614E",
                    },
                    "&:disabled": {
                      color: "white",
                      backgroundColor: "#1C614E",
                    },
                  }}
                  variant="contained"
                  endIcon={<SendIcon />}
                  disabled={btndisable}
                  onClick={AddPostprofanity}
                >
                  {Labels?.Send}
                </Button>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </Paper>
  );
}
