import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { axiosAPI } from "../Axios/Axios";
import { IconButton} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import { Clear } from '@mui/icons-material';



const SkillsEdit = ({ style, value, setValue,setButtonDisable = () => {}}) => {
  const [skill, setSkill] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);

  useEffect(() => {
    const getSkills = async () => {
      const result = await axiosAPI.get("/getSkills");
      const { success, data } = await result.data;
      if (success === true) {
        setSkillsOptions(data);
      } else {
        setSkillsOptions([]);
      }
    };
    getSkills();
  }, []);
  const [svalues,setsvalues] = useState([])

  const handleChange = (event, newValue) => {
    setButtonDisable(false)
    const selectedValues = Array.isArray(newValue) ? newValue : [];
    setsvalues(selectedValues)

    setValue(selectedValues);
  };
  
  const filteredOptions = skillsOptions.filter(
    (option) =>
      !value.some((selected) => selected.id === option.id) &&
      value.every((selected) => selected.id !== value.id)
  );
  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <FormControl sx={{ width: "100%" }}>
     
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={filteredOptions}
        
        multiple
        sx={{ ...style }}
        getOptionLabel={(option) => option.name}
        clearIcon={<IconButton
          sx={{
            // border: "2px solid red",
            position: "absolute",
            left: "50px",
            visibility: "visible",
          }}
        >
          <Clear />
        </IconButton>}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.skills}  sx={{width:svalues.length || value.length?"90%":"100%"}}      />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default SkillsEdit;

