import React, { useState, useEffect } from "react";
import {
  Stack,
  Table,
  Chip,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TextField,
  Skeleton,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import CreateCompany from "./createCompany";
import CustomButton from "../../Components/CustomButton";
import DeleteCompany from "./deleteComapny.";
import { warningNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import InputWithLabel from "../../Components/InputWithLabel";

function CompanyList() {
  const Labels = useSelector((state) => state.allLabels.labels);
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [createCompany, setcreateCompany] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [listCompanies, setListCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Search state
  const [deleteCompany, setDeleteCompany] = useState(false);
  const [companyid, setCompanyId] = useState("");
  const [loading, setloading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const deleteCompanyById = (id) => {
    setDeleteCompany(true);
    setCompanyId(id);
  };

  const getAllCompanies = async () => {
    try {
      const res = await axiosAPI.post("/companiesList");
      const { data, status } = res.data;
      if (status) {
        setListCompanies(data);
        setFilteredCompanies(data); // Set filtered companies initially
        setloading(false);
      } else {
        setloading(false);
        setListCompanies([]);
        setFilteredCompanies([]);
      }
    } catch (error) {
      // console.log(error.message);
    }
  };

  // Function to handle search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    const filteredData = listCompanies.filter((company) => {
      // Check if the company data matches the search query
      const mainCompanyMatch =
        company?.name?.toLowerCase()?.includes(value) ||
        company?.website?.toLowerCase()?.includes(value) ||
        company?.emailid?.toLowerCase()?.includes(value) ||
        company?.contactNumber?.toLowerCase()?.includes(value) ||
        company?.city?.toLowerCase()?.includes(value) ||
        company?.state?.toLowerCase()?.includes(value) ||
        company?.county?.toLowerCase()?.includes(value) ||
        company?.businessCategory?.toLowerCase()?.includes(value);

      // Check if any branch matches the search query
      const branchMatch = company?.companyBranches?.some(
        (branch) =>
          branch?.stateName?.toLowerCase()?.includes(value) ||
          branch?.city?.toLowerCase()?.includes(value) ||
          branch?.address?.toLowerCase()?.includes(value) ||
          branch?.countryName?.toLowerCase()?.includes(value)
      );

      return mainCompanyMatch || branchMatch;
    });

    setFilteredCompanies(filteredData);
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h5" sx={{ color: "#1C614E" }}>
        {Labels?.Companies}
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CreateCompany
          open={createCompany}
          handleClose={() => setcreateCompany(false)}
          getAllCompanies={getAllCompanies}
        />
        {/* Search Field */}
        <InputWithLabel
          InputLabel={""}
          label={Labels.SearchCompanies}
          value={searchQuery}
          onChange={(e) => handleSearch(e)}
          style={{ width: "300px", position: "relative", top: "10px" }}
        />
        <CustomButton
          onClick={() => setcreateCompany(true)}
          label={Labels?.CreateCompany}
        />
      </Stack>

      <TableContainer sx={{ my: 2 }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                borderTop: "10px solid #EAEFF1",
                borderBottom: "10px solid #EAEFF1",
                background: "rgba(3, 132, 97, 0.3)",
              }}
            >
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.sno}</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {Labels?.CompanyInfo}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.Website}</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.Email}</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {Labels.ContactNumber}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels.Address}</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.Branches}</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {Labels?.BusinessIndustry}{" "}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {Labels?.PostedJobs}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>{Labels?.actions} </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <>
                {Array(10)
                  .fill()
                  .map(() => {
                    return (
                      <TableRow
                        sx={{
                          borderTop: "10px solid #EAEFF1",
                          borderBottom: "10px solid #EAEFF1",
                          background: "#fff",
                        }}
                      >
                        {Array(10)
                          .fill()
                          .map(() => (
                            <TableCell>
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                height={23}
                                style={{ margin: "2px auto" }}
                              />
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              filteredCompanies
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((Companies, index) => (
                  <TableRow
                    key={Companies.consultancy_id}
                    sx={{
                      borderTop: "10px solid #EAEFF1",
                      borderBottom: "10px solid #EAEFF1",
                      background: "#fff",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{Companies?.name}</TableCell>
                    <TableCell>{Companies?.website}</TableCell>
                    <TableCell>{Companies?.emailid}</TableCell>
                    <TableCell>{Companies?.contactNumber}</TableCell>
                    <TableCell>
                      {Companies?.address}
                      <br /> {Companies?.city}, {Companies?.state},{" "}
                      {Companies?.county}
                    </TableCell>
                    <TableCell>
                      {Companies.companyBranches.length > 0 ? (
                        <ul style={{ position: "relative", right: "25px" }}>
                          {Companies.companyBranches.map((branch) => (
                            <li key={branch.branchid}>
                              {branch.address} <br /> {branch.city},{" "}
                              {branch.stateName}, {branch.countryName}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul style={{ position: "relative", right: "25px" }}>
                          {" "}
                          {Labels.NoBranches}{" "}
                        </ul>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={Companies?.businessCategory}
                        size="small"
                        sx={{
                          color: "#BA8C04",
                          background: "#BA8C040F",
                          borderRadius: 1,
                          minWidth: 120,
                          border: "1px solid rgba(0, 0, 0, 0.10)",
                        }}
                      />
                    </TableCell>

                    <TableCell>{Companies?.jobCount}</TableCell>
                    <TableCell>
                      {Companies?.jobCount === 0 ? (
                        <DeleteIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "#d32f2f",
                          }}
                          onClick={() =>
                            deleteCompanyById(Companies.consultancy_id)
                          }
                        />
                      ) : (
                        <DeleteForeverIcon
                          onClick={() =>
                            warningNotify(Labels.Cannotdeletethiscompany)
                          }
                          sx={{
                            width: 28,
                            height: 28,
                            color: "#d32f2f",
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredCompanies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />

      <DeleteCompany
        open={deleteCompany}
        handleClose={() => setDeleteCompany(false)}
        companyid={companyid}
        getAllCompanies={getAllCompanies}
      />
    </ThemeProvider>
  );
}

export default CompanyList;
